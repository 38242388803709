import { MailIcon, PhoneIcon } from "@/assets/svgs";
import DownloadPolicy from "@/components/insurance/DownloadPolicy";
import { renderLoadingCard } from "@/components/insurance/LoadingCard";
import { Policy } from "@/interface/policies";
import usePolicyByIdStore from "@/states/policies-by-id";
import getInitial from "@/utils/get-initial";
import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";

const Title = tw.p`text-xs md:text-base text-[#6B7280] font-medium !leading-[20px]`;
const Text = tw.p`text-sm md:text-lg text-[#202020] font-semibold capitalize`;

const InsurancePaymentCompleted = () => {
  const queryParam = new URLSearchParams(window.location.search);
  const policyId = queryParam.get("policy_id");
  const { policyByIdLoading, policyById, fetchPolicyById } =
    usePolicyByIdStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (policyId) fetchPolicyById(policyId);
  }, [fetchPolicyById, policyId]);

  if (policyByIdLoading) {
    return renderLoadingCard();
  }

  return (
    <main className="flex flex-col gap-4 w-full max-w-full md:max-w-[75%] xl:max-w-[40%] bg-white my-0 mx-auto p-4 md:p-6 rounded-lg">
      <img
        src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/Frame%20(1).png?updatedAt=1704669394093"
        alt="Happy Customer"
        className="md:w-[215px] h-auto md:h-[270px] my-0 mx-auto"
      />

      <h1 className="text-base md:text-2xl font-semibold text-[#31355B] text-center">
        Your insurance has been paid
      </h1>

      <section className="border border-solid border-[#E5E7EB] rounded-lg divide-y divide-[#E5E7EB] p-2 md:p-3">
        <div className="flex flex-col gap-3 pb-3">
          <p className="text-xs md:text-sm text-[#30345E] font-medium font-outfit bg-[#EEEFFF] rounded-[4px] py-1 px-2 w-fit">
            Insurance details
          </p>
          <div className="flex flex-col gap-2">
            <p className="text-lg md:text-[22px] text-[#202020] font-semibold">
              Toyota Camry 2009
            </p>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              <div>
                <Title>Insurance Company</Title>
                <Text>{policyById?.providerName ?? "---"}</Text>
              </div>
              <div className="">
                <Title>Policy No</Title>
                <Text>{policyById?.policyNumber ?? "---"}</Text>
              </div>
              <div>
                <Title>Issue Date</Title>
                <Text>
                  {policyById?.startDate
                    ? moment(policyById?.startDate).format("YYYY-MM-DD")
                    : "---"}
                </Text>
              </div>
              <div className="">
                <Title>Expiry Date</Title>
                <Text>
                  {policyById?.endDate
                    ? moment(policyById?.endDate).format("YYYY-MM-DD")
                    : "---"}
                </Text>
              </div>
              <div className="">
                <Title>License Plate</Title>
                <Text>{policyById?.vehicle?.licensePlateNumber ?? "---"}</Text>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-start gap-2 pt-3">
          <div className="text-sm md:text-xl font-semibold font-outfit uppercase text-[#30345E] w-10 md:w-12 h-10 md:h-12 rounded-full bg-[#CCDDFF] flex items-center justify-center">
            {getInitial(
              `${policyById?.customer?.firstName} ${policyById?.customer?.lastName}`
            )}
          </div>
          <div>
            <p className="text-base md:text-lg text-[#202020] font-semibold leading-relaxed">
              {policyById?.customer?.firstName ?? "---"}{" "}
              {policyById?.customer?.lastName ?? "---"}
            </p>
            <p className="text-xs md:text-base text-[#6B7280] font-normal flex items-center gap-1">
              <MailIcon /> {policyById?.customer?.emailAddress ?? "---"}
            </p>
            <p className="text-xs md:text-base text-[#6B7280] font-normal flex items-center gap-1">
              <PhoneIcon /> {policyById?.customer?.phoneNumber ?? "---"}
            </p>
          </div>
        </div>
      </section>

      <div className="w-full max-w-[75%] md:max-w-[50%] my-0 mx-auto mt-4">
        <DownloadPolicy item={policyById as Policy} />
      </div>
      <button
        onClick={() => navigate(`/insurance/${policyId}`)}
        className="text-sm md:text-base font-semibold text-[#30345E]"
      >
        View Details
      </button>
    </main>
  );
};

export default InsurancePaymentCompleted;
