interface SourceData {
  name: string;
  sourceId?: string;
  email?: string;
}

interface ReferrerData {
  name?: string;
  referrerId?: string;
  email?: string;
}

interface Address {
  city?: string;
  state?: string;
  postalCode?: string;
  addressLine1?: string;
  addressLine2?: string;
  valuationLocation?: string;
}

interface OtherDetails {
  bvn?: string;
  kraPIN?: string;
  address?: Address;
  hasMajorModifications?: boolean;
}

export interface Customer {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  whatsappNumber: string;
  gender?: string;
  dateOfBirth?: string;
  idNumber?: string;
  idType?: string;
  country: string;
  externalRef?: string;
  otherDetails?: OtherDetails;
  createdAt: string;
  updatedAt: string;
}

export interface Vehicle {
  id: string;
  name: string;
  make: string;
  model: string;
  year: number;
  exteriorColor: string;
  bodyType: string;
  licensePlateNumber: string;
  chasisNumber?: string;
  engineNumber?: string;
  vinNumber?: string;
  usageType: string;
  estimatedCarPrice: string;
  valuationCarPrice: string;
  country: string;
  externalRef?: string;
  otherDetails?: OtherDetails;
  createdAt: string;
  updatedAt: string;
}

interface Product {
  id: string;
  code: string;
  name: string;
  coverageType: string;
  description: string;
  paymentOptions: string[];
  createdAt: string;
  updatedAt: string;
}

export interface Quote {
  id: string;
  quoteDate: string;
  amountDue: string;
  subsequentMonthlyAmount: string;
  commissionAmount: string;
  currency: string;
  paymentPlan: string;
  instalmentCount: number;
  coverDuration: number;
  status: string;
  country: string;
  source: string;
  sourceData: SourceData;
  referrer?: string;
  referrerData?: ReferrerData;
  createdAt: string;
  updatedAt: string;
  customer: Customer;
  vehicle: Vehicle;
  product: Product;
}

interface Pagination {
  total: number;
  currentPage: number;
  pageSize: number;
  pageCount: number;
}

export interface IQuotationData {
  data: Quote[];
  pagination: Pagination;
}

export enum QUOTE_STATUS {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_REQUESTED = "PAYMENT_REQUESTED",
  PAYMENT_COMPLETED = "PAYMENT_COMPLETED",
  PAYMENT_PROCESSING = "PAYMENT_PROCESSING",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  POLICY_ISSUED = "POLICY_ISSUED",
}

export interface IDirectDebits {
  accountNumber: string;
  bankCode: string;
  createdAt: Date;
  email: string;
  status: "pending" | "active" | "created" | "failed";
  userId: string;
}

export enum DIRECT_DEBIT_STATUS {
  ACTIVE = "active",
  PENDING = "pending",
  CREATED = "created",
  FAILED = "failed",
}
