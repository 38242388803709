import { useEffect, useState, Fragment } from "react";
import { MailIcon, WhatsappIcon } from "@/assets/svgs";
import useQuoteByIdStore from "@/states/quotes";
import formatInt from "@/utils/format-int";
import { useNavigate } from "react-router-dom";
import { Loader } from "../auth/styles/loader";
import { postInitiatePayment } from "@/hooks/nova-api";
import { Alert, Snackbar } from "@mui/material";
import GeneratedLinkCard from "@/components/upfront-payment/GeneratedLinkCard";
import { IPaymentRequest, PAYMENT_PLAN } from "@/interface/policies";
import { QUOTE_STATUS } from "@/interface/quotations";
import ConfirmQuotePayment from "@/components/insurance/ConfirmPayment";

const InsurancePaymentConfirmed = () => {
  const navigate = useNavigate();
  const queryParam = new URLSearchParams(window.location.search);
  const quoteId = queryParam.get("quote_id") as string;
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [linkGenerated, setLinkGenerated] = useState(false);
  const [storeData, setStoreData] = useState<IPaymentRequest>();

  const { quoteLoading, quotesById, fetchQuotesById } = useQuoteByIdStore();

  useEffect(() => {
    if (quoteId) fetchQuotesById(quoteId);
  }, [fetchQuotesById, quoteId]);

  const handleInitializePayment = async () => {
    setLoading(true);
    const data = {
      quotationId: quoteId,
    };
    try {
      const response = await postInitiatePayment(data);
      const parsedResponse = JSON.parse(response?.data);
      setStoreData(parsedResponse);
      setLinkGenerated(true);
      setTimeout(() => {
        setLinkGenerated(false);
      }, 4000);
      fetchQuotesById(quoteId);
    } catch (error: any) {
      const parsedError = JSON.parse(error?.response?.data);
      setAlert(parsedError?.message);
    } finally {
      setLoading(false);
    }
  };

  if (quoteLoading) {
    return (
      <div className="w-full min-h-[600px] flex items-center justify-center bg-white border border-solid border-[#E5E7EB] rounded-2xl">
        <Loader variant="secondary" />
      </div>
    );
  }

  return (
    <main>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}
      >
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>

      <div className="flex flex-col gap-8 w-full max-w-full md:max-w-[70%] xl:max-w-[40%] my-0 mx-auto">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-[#30345E] text-xl font-semibold"
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/Icon-1.svg"
            alt="chevron-back"
            style={{ marginTop: "3px", marginRight: "10px" }}
          />
          {""}
          Insurance Payment
        </button>

        {linkGenerated && (
          <div className="text-sm text-white font-semibold bg-[#22C55E] h-10 w-full rounded-md flex items-center justify-center">
            Link generated
          </div>
        )}

        {(storeData || quotesById?.paymentRequest?.paymentLink) && (
          <GeneratedLinkCard
            redirectUrl={
              storeData?.paymentLink ??
              (quotesById?.paymentRequest?.paymentLink as string)
            }
          />
        )}

        <section className="divide-y bg-white px-3 md:px-0 pt-6 pb-10 rounded-lg">
          <div className="space-y-3 pb-5">
            <img
              src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/cash-fill.png?updatedAt=1710196935945"
              alt="Payment Card"
              width="30px"
              height="30px"
              className="my-0 mx-auto"
            />
            <div>
              <p className="text-[#6B7280] font-semibold text-center leading-tight text-sm md:text-base">
                Amount due today
              </p>
              <p className="text-[#30345e] font-outfitbold text-base md:text-2xl text-center">
                {formatInt(Number(quotesById?.amountDue), true)}
              </p>
            </div>
            {quotesById?.paymentPlan === PAYMENT_PLAN.MONTHLY && (
              <Fragment>
                <div>
                  <p className="text-[#6B7280] font-semibold text-center leading-tight text-sm md:text-base">
                    Subsequent Monthly
                  </p>
                  <p className="text-[#30345e] font-outfitbold text-base md:text-2xl text-center">
                    {formatInt(
                      Number(quotesById?.subsequentMonthlyAmount),
                      true
                    )}
                  </p>
                </div>
                <div>
                  <p className="text-[#6B7280] font-semibold text-center leading-tight text-sm md:text-base">
                    Number of Instalments
                  </p>
                  <p className="text-[#30345e] font-outfitbold text-base md:text-2xl text-center">
                    {quotesById?.instalmentCount}
                  </p>
                </div>
              </Fragment>
            )}
          </div>

          <div className="flex flex-col gap-6 xl:gap-8 pt-5">
            <div className="flex flex-col gap-3">
              <p className="text-xl text-[#30345E] font-semibold text-center">
                Generate payment link
              </p>
              <p className="text-sm md:text-base text-[#6B7280] font-medium text-center md:max-w-sm my-0 mx-auto">
                Insurance quote and payment link will be sent to your Whatsapp
                and Email Address below
              </p>
              <p className="text-sm md:text-base text-[#30345E] font-semibold flex items-center justify-center gap-1 text-center">
                <MailIcon /> {quotesById?.customer?.emailAddress}
              </p>
              <p className="text-sm md:text-base text-[#30345E] font-semibold flex items-center justify-center text-center gap-1">
                <WhatsappIcon /> {quotesById?.customer?.whatsappNumber}
              </p>
            </div>

            <div className="flex flex-col md:flex-row gap-4 w-full md:max-w-[80%] my-0 mx-auto">
              <ConfirmQuotePayment
                quoteId={quoteId}
                setAlert={setAlert}
                status={quotesById?.status as QUOTE_STATUS}
              />

              {quotesById?.status !== QUOTE_STATUS.POLICY_ISSUED && (
                <>
                  {quotesById?.paymentPlan === PAYMENT_PLAN.ONE_OFF && (
                    <button
                      onClick={handleInitializePayment}
                      disabled={quotesById.status !== QUOTE_STATUS.PENDING}
                      className="text-[#30345E] bg-[#FFB619] text-sm md:text-base font-semibold w-full max-w-full md:max-w-[60%] my-0 mx-auto h-12 rounded-full disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
                    >
                      {loading ? (
                        <Loader variant="secondary" />
                      ) : (
                        <>
                          {quotesById.status === QUOTE_STATUS.PENDING
                            ? "Generate"
                            : "Regenerate"}
                        </>
                      )}
                    </button>
                  )}

                  {quotesById?.paymentPlan === PAYMENT_PLAN.MONTHLY && (
                    <button
                      onClick={() =>
                        navigate(`/insurance/direct-debit?quote_id=${quoteId}`)
                      }
                      className="text-[#30345E] bg-[#FFB619] text-sm md:text-base font-semibold w-full max-w-full md:max-w-[60%] my-0 mx-auto h-12 rounded-full disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
                    >
                      Setup Direct debit
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default InsurancePaymentConfirmed;
