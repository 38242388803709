import React, { useState } from "react";
import InsuranceCard from "./InsuranceCard";
import { IPolicyData } from "@/interface/policies";
import { LoadingInsuranceCard } from "@/components/LoadingCarCard";
import GridEmptyCard from "@/components/GridEmptyCard";
import { IQuotationData } from "@/interface/quotations";
import { Pagination, PaginationProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import { Alert, Snackbar } from "@mui/material";

type TInsuranceGrid = {
  tabValue: string;
  data: IPolicyData | IQuotationData;
  loading: boolean;
  parentTabValue: string;
  query: any;
};

const InsuranceGrid: React.FC<TInsuranceGrid> = ({
  tabValue,
  data,
  loading,
  parentTabValue,
  query,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const [alert, setAlert] = useState("");

  const handlePageChange: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrent(page);
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        page: page,
        pageSize: pageSize,
      })}${location.hash || ""}`
    );
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}
      >
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>

      {data?.data?.length > 0 ? (
        <React.Fragment>
          {loading ? (
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
              {Array.from({ length: 12 }, (_, index) => (
                <div key={index}>
                  <LoadingInsuranceCard />
                </div>
              ))}
            </div>
          ) : (
            <section className="flex flex-col gap-6">
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 py-4 md:py-5 md:px-4 md:bg-white">
                {data?.data?.map((item) => (
                  <InsuranceCard
                    key={item.id}
                    tabValue={tabValue}
                    item={item}
                    parentTabValue={parentTabValue}
                    setAlert={setAlert}
                  />
                ))}
              </div>
              <div className="flex justify-center w-full">
                <Pagination
                  current={current}
                  onChange={handlePageChange}
                  defaultPageSize={12}
                  total={data.pagination.total}
                  showSizeChanger={false}
                />
              </div>
            </section>
          )}
        </React.Fragment>
      ) : (
        <GridEmptyCard text="No insurance available" />
      )}
    </div>
  );
};

export default InsuranceGrid;
