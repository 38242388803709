import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "antd";
import {
  Alert,
  Autocomplete,
  Button,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
} from "@mui/material";
import {
  CRadioFormLabel,
  CRadioFormLabelSx,
  FormBackStyledBtn,
  FormDropdownStyle,
  FormStyledBtn,
  InputFieldStyle,
  InputLabelStyle,
  SelectTextFieldStyle,
} from "@/layouts/application-layouts/styles";
import { IOption, IStatesOption } from "@/types";
import {
  quoteDetailFormSchema,
  quoteFormDetailDefaultValues,
  QuoteFormDetailValues,
} from "@/validations/insurance-quote.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import storage from "@/lib/storage";
import { useQuery } from "@tanstack/react-query";
import { fetchAdminCars, fetchCities, fetchStates } from "@/hooks/use-api";
import useQuoteByIdStore from "@/states/quotes";
import { Loader } from "../auth/styles/loader";
import { validateAdditionalQuote } from "@/utils/validate-quote-form";
import moment from "moment";
import useQuoteStore from "@/states/create-quote";
import { cleanNestedObject } from "@/utils/process-form-data";
import { useNavigate } from "react-router-dom";
import CustomerContactCard from "@/components/upfront-payment/CustomerContactCard";
import { updateQuotation } from "@/hooks/nova-api";
import AddressAutocomplete from "@/components/insurance/AddressAutocomplete";
import { Genders, hasModifications, IdTypes } from "@/data/insurance-data";
import { AxiosError } from "axios";

const QuoteFormDetails = () => {
  const country = storage.getCountry();
  const navigate = useNavigate();
  const queryParam = new URLSearchParams(window.location.search);
  const quoteId = queryParam.get("quote_id") as string;
  const { quoteLoading, quotesById, fetchQuotesById } = useQuoteByIdStore();
  const { alert, setAlert } = useQuoteStore();
  const [loading, setLoading] = useState(false);
  const user = storage.getUser();
  const isFranchise = storage.utilities.isFranchise();
  const carId = quotesById?.customer.externalRef;
  const [carsById, setCarsById] = useState({} as { vin: string });

  useEffect(() => {
    if (quoteId) fetchQuotesById(quoteId);
  }, [fetchQuotesById, quoteId]);

  const methods = useForm({
    mode: "all",
    defaultValues: quoteFormDetailDefaultValues,
    resolver: zodResolver(quoteDetailFormSchema),
  });

  const stateIdLookup = methods.getValues("stateId");

  const errors = { ...methods.formState.errors } as Record<string, any>;
  const formValues = methods.watch();

  const isValid = validateAdditionalQuote(formValues);
  const disabled = !isValid;

  const { data: states } = useQuery({
    queryKey: ["states_list", country],
    queryFn: () => {
      const params = new URLSearchParams({
        country_code: country,
        page_size: "200",
      });
      return fetchStates(params);
    },
    enabled: !!country,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: Infinity,
    refetchOnMount: true,
  });

  const { data: cities } = useQuery({
    queryKey: ["cities_list", stateIdLookup],
    queryFn: () => {
      const params = new URLSearchParams({
        state_id: stateIdLookup,
        page_size: "500",
      });
      return fetchCities(params);
    },
    enabled: !!stateIdLookup,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: Infinity,
    refetchOnMount: true,
  });

  const preparePayload = (values: QuoteFormDetailValues) => {
    const payload = {
      customer: {
        gender: values.gender,
        dateOfBirth: values.dateOfBirth,
        idNumber: values.idNumber,
        idType: values.idType,
        otherDetails: {
          kraPIN: values.kraPin,
          address: {
            addressLine1: values.address,
            city: values.cities,
            state: values.states,
            valuationLocation: values.valuationLocation,
          },
        },
        id: quotesById?.customer.id,
      },
      vehicle: {
        engineNumber: values.engineNumber,
        vinNumber: values.vin,
        otherDetails: {
          hasMajorModifications: values.hasModification === "yes",
        },
        id: quotesById?.vehicle.id,
      },
      isDraft: false,
      creatorId: isFranchise ? user?.company?.id : user?.id,
    };
    return payload;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = preparePayload(formValues);
    const cleanedData = cleanNestedObject(data);
    try {
      await updateQuotation(quoteId, cleanedData);
      navigate(`/insurance/payment?quote_id=${quoteId}`);
    } catch (error: any) {
      setAlert(JSON.parse(error.response?.data).message);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchCarById = useCallback(async () => {
    try {
      const response = await fetchAdminCars(carId!);
      const parsedResponse = JSON.parse(response?.data);
      if (parsedResponse) {
        setCarsById(parsedResponse);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        setAlert(JSON.parse(err.response?.data).message);
      }
    }
  }, [carId, setAlert]);

  useEffect(() => {
    if (carId) handleFetchCarById();
  }, [carId, handleFetchCarById]);

  const mappedCarData = useCallback(
    (data: { vin: string }): QuoteFormDetailValues => {
      return {
        vin: data.vin ?? "",
        dateOfBirth: "",
        idType: "",
        idNumber: "",
        kraPin: "",
        gender: "",
        engineNumber: "",
        hasModification: "",
        address: "",
        states: "",
        cities: "",
        valuationLocation: "",
        stateId: "",
      };
    },
    []
  );

  useEffect(() => {
    if (carsById) {
      const mappedData = mappedCarData(carsById as { vin: string });
      methods.reset(mappedData);
    }
  }, [carsById, mappedCarData, methods]);

  return (
    <main>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}
      >
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>

      <div className="w-full max-w-full md:max-w-[75%] xl:max-w-[45%] flex flex-col gap-7 my-0 mx-auto">
        <CustomerContactCard
          firstName={quotesById?.customer?.firstName}
          lastName={quotesById?.customer?.lastName}
          emailAddress={quotesById?.customer?.emailAddress}
          phoneNumber={quotesById?.customer?.phoneNumber}
          loading={quoteLoading}
        />

        <form className="flex flex-col gap-7">
          <div className="rounded-2xl bg-white">
            <h2 className="text-xl text-[#30345E] font-semibold p-4 border-b border-solid border-[#E5E7EB]">
              Additional Personal Details
            </h2>
            <div className="p-4 flex flex-col gap-4">
              <div className="space-y-2">
                <FormLabel sx={InputLabelStyle}>Date of Birth</FormLabel>
                <Controller
                  name="dateOfBirth"
                  control={methods.control}
                  render={({ field: { onChange } }) => (
                    <DatePicker
                      onChange={(date) =>
                        onChange(
                          date
                            ? moment(date.toISOString()).format("YYYY-MM-DD")
                            : null
                        )
                      }
                      className="w-full h-[46px] bg-[#F8FAFC]"
                    />
                  )}
                />
                {errors?.dateOfBirth?.message && (
                  <span className="text-red-600 text-sm">
                    {errors?.dateOfBirth?.message}
                  </span>
                )}
              </div>

              <div className="space-y-2">
                <FormLabel sx={InputLabelStyle}>ID Type</FormLabel>
                <Controller
                  name="idType"
                  control={methods.control}
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      fullWidth
                      id="idType"
                      disablePortal
                      options={IdTypes}
                      onChange={(_, newValue) => {
                        onChange(newValue?.value);
                      }}
                      getOptionLabel={(option: IOption) => option?.label || ""}
                      isOptionEqualToValue={(option: IOption, value: IOption) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          placeholder={"Select ID type"}
                          sx={SelectTextFieldStyle}
                        />
                      )}
                      sx={FormDropdownStyle}
                    />
                  )}
                />
                {errors?.idType?.message && (
                  <span className="text-red-600 text-sm">
                    {errors?.idType?.message}
                  </span>
                )}
              </div>

              <div className="space-y-2">
                <FormLabel sx={InputLabelStyle}>ID number</FormLabel>
                <Controller
                  name="idNumber"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      placeholder="Enter ID number"
                      onChange={field.onChange}
                      sx={InputFieldStyle}
                    />
                  )}
                />
                {errors?.idNumber?.message && (
                  <span className="text-red-600 text-sm">
                    {errors?.idNumber?.message}
                  </span>
                )}
              </div>

              {country === "KE" && (
                <div className="space-y-2">
                  <FormLabel sx={InputLabelStyle}>KRA Pin</FormLabel>
                  <Controller
                    name="kraPin"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="text"
                        placeholder="Enter KRA pin"
                        onChange={field.onChange}
                        sx={InputFieldStyle}
                      />
                    )}
                  />
                  {errors?.kraPin?.message && (
                    <span className="text-red-600 text-sm">
                      {errors?.kraPin?.message}
                    </span>
                  )}
                </div>
              )}

              <div>
                <FormLabel sx={InputLabelStyle}>Gender</FormLabel>
                <Controller
                  name="gender"
                  control={methods.control}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      {(Genders ?? []).map((opt) => (
                        <FormControlLabel
                          key={opt.value}
                          value={opt.value}
                          control={<Radio sx={CRadioFormLabel} />}
                          label={opt.label}
                          sx={CRadioFormLabelSx}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
                {errors?.gender?.message && (
                  <span className="text-red-600 text-sm">
                    {errors?.gender?.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="rounded-2xl bg-white">
            <h2 className="text-xl text-[#30345E] font-semibold p-4 border-b border-solid border-[#E5E7EB]">
              Address Details
            </h2>
            <div className="p-4 flex flex-col gap-4">
              <div className="space-y-2">
                <FormLabel sx={InputLabelStyle}>State/Region</FormLabel>
                <Controller
                  name="states"
                  control={methods.control}
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      fullWidth
                      id="states"
                      disablePortal
                      options={states ?? []}
                      onChange={(_, newValue) => {
                        onChange(newValue?.value);
                        methods.setValue("stateId", newValue?.id as string);
                      }}
                      getOptionLabel={(option: IStatesOption) =>
                        option?.label || ""
                      }
                      isOptionEqualToValue={(
                        option: IStatesOption,
                        value: IStatesOption
                      ) => option.value === value.value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          placeholder={"Select state or region"}
                          sx={SelectTextFieldStyle}
                        />
                      )}
                      sx={FormDropdownStyle}
                    />
                  )}
                />
                {errors?.states?.message && (
                  <span className="text-red-600 text-sm">
                    {errors?.states?.message}
                  </span>
                )}
              </div>
              <div className="space-y-2">
                <FormLabel sx={InputLabelStyle}>City</FormLabel>
                <Controller
                  name="cities"
                  control={methods.control}
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      fullWidth
                      id="cities"
                      disablePortal
                      options={cities ?? []}
                      onChange={(_, newValue) => {
                        onChange(newValue?.value);
                      }}
                      getOptionLabel={(option: IOption) => option?.label || ""}
                      isOptionEqualToValue={(option: IOption, value: IOption) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          placeholder={"Select city"}
                          sx={SelectTextFieldStyle}
                        />
                      )}
                      sx={FormDropdownStyle}
                    />
                  )}
                />
                {errors?.cities?.message && (
                  <span className="text-red-600 text-sm">
                    {errors?.cities?.message}
                  </span>
                )}
              </div>
              <div className="space-y-2">
                <FormLabel sx={InputLabelStyle}>Address</FormLabel>
                <AddressAutocomplete methods={methods} />
                {errors?.address?.message && (
                  <span className="text-red-600 text-sm">
                    {errors?.address?.message}
                  </span>
                )}
              </div>

              {country === "KE" && (
                <div className="space-y-2">
                  <FormLabel sx={InputLabelStyle}>
                    Valuation Location (Kenya only)
                  </FormLabel>
                  <Controller
                    name="valuationLocation"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="text"
                        placeholder="Enter location"
                        onChange={field.onChange}
                        sx={InputFieldStyle}
                      />
                    )}
                  />
                  {errors?.valuationLocation?.message && (
                    <span className="text-red-600 text-sm">
                      {errors?.valuationLocation?.message}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="rounded-2xl bg-white">
            <h2 className="text-xl text-[#30345E] font-semibold p-4 border-b border-solid border-[#E5E7EB]">
              Additional Car Details
            </h2>
            <div className="p-4 flex flex-col gap-4">
              <div>
                <FormLabel sx={InputLabelStyle}>
                  Has this vehicle undergone any major modifications
                </FormLabel>
                <Controller
                  name="hasModification"
                  control={methods.control}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      {(hasModifications ?? []).map((opt) => (
                        <FormControlLabel
                          key={opt.value}
                          value={opt.value}
                          control={<Radio sx={CRadioFormLabel} />}
                          label={opt.label}
                          sx={CRadioFormLabelSx}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
                {errors?.hasModification?.message && (
                  <span className="text-red-600 text-sm">
                    {errors?.hasModification?.message}
                  </span>
                )}
              </div>
              <div className="space-y-2">
                <FormLabel sx={InputLabelStyle}>VIN</FormLabel>
                <Controller
                  name="vin"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      placeholder="Enter VIN"
                      onChange={field.onChange}
                      inputProps={{
                        maxLength: 17,
                        pattern: /^[A-Za-z0-9]{15,}$/,
                      }}
                      sx={InputFieldStyle}
                    />
                  )}
                />
                {errors?.vin?.message && (
                  <span className="text-red-600 text-sm">
                    {errors?.vin?.message}
                  </span>
                )}
              </div>
              {country === "KE" && (
                <div className="space-y-2">
                  <FormLabel sx={InputLabelStyle}>Engine Number</FormLabel>
                  <Controller
                    name="engineNumber"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="text"
                        placeholder="Enter engine number"
                        onChange={field.onChange}
                        sx={InputFieldStyle}
                      />
                    )}
                  />
                  {errors?.engineNumber?.message && (
                    <span className="text-red-600 text-sm">
                      {errors?.engineNumber?.message}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="flex gap-4">
            <Button
              className="w-full max-w-[100%] my-0 mx-auto disabled:cursor-not-allowed"
              variant="contained"
              sx={FormBackStyledBtn}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button
              className="w-full max-w-[100%] my-0 mx-auto disabled:cursor-not-allowed"
              variant="contained"
              sx={FormStyledBtn}
              onClick={handleSubmit}
              disabled={disabled}
            >
              {loading ? <Loader variant="secondary" /> : "Make Payment"}
            </Button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default QuoteFormDetails;
