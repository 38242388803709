import { MailIcon, PhoneIcon } from "@/assets/svgs";
import ConfirmQuotePayment from "@/components/insurance/ConfirmPayment";
import DownloadPolicy from "@/components/insurance/DownloadPolicy";
import ShareNotifs from "@/components/insurance/SendNotification";
import { PAYMENT_PLAN, Policy } from "@/interface/policies";
import {
  DIRECT_DEBIT_STATUS,
  Quote,
  QUOTE_STATUS,
} from "@/interface/quotations";
import useDirectDebitStore from "@/states/direct-debit-status";
import formatInt from "@/utils/format-int";
import getInitial from "@/utils/get-initial";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";

type TInsuranceCard = {
  tabValue: string;
  item: Policy | Quote;
  parentTabValue: string;
  setAlert: (value: string) => void;
};

const Title = tw.p`text-sm text-[#6B7280] font-normal leading-tight`;
const Text = tw.p`text-base text-[#202020] font-semibold capitalize`;
const Section = tw.div``;
const Card = tw.div`border border-solid border-[#E5E7EB] bg-white rounded-lg px-3 py-3.5 divide-y divide-[#E5E7EB] md:cursor-pointer`;

const InsuranceCard: React.FC<TInsuranceCard> = ({
  tabValue,
  item,
  parentTabValue,
  setAlert,
}) => {
  const navigate = useNavigate();
  const { directDebitStatus, fetchDirectDebitStatus } = useDirectDebitStore();

  const handleRequestPayment = (id: string) => {
    if (id) fetchDirectDebitStatus(id);

    if (
      ((item as Quote).paymentPlan === PAYMENT_PLAN.MONTHLY &&
        (item as Quote).status === QUOTE_STATUS.PAYMENT_REQUESTED) ||
      ((item as Quote).paymentPlan === PAYMENT_PLAN.MONTHLY &&
        (item as Quote).status === QUOTE_STATUS.PAYMENT_PENDING) ||
      ((item as Quote).paymentPlan === PAYMENT_PLAN.MONTHLY &&
        (item as Quote).status === QUOTE_STATUS.PAYMENT_FAILED) ||
      ([DIRECT_DEBIT_STATUS.ACTIVE, DIRECT_DEBIT_STATUS.CREATED].includes(
        directDebitStatus?.status as DIRECT_DEBIT_STATUS
      ) &&
        (item as Quote).status === QUOTE_STATUS.PENDING)
    ) {
      navigate(`/insurance/direct-debit?quote_id=${(item as Quote)?.id}`);
    } else {
      navigate(`/insurance/payment?quote_id=${(item as Quote)?.id}`);
    }
  };

  const renderRequestButton = () => {
    return (
      <button
        onClick={() => handleRequestPayment(item?.customer?.id)}
        className="text-sm md:text-base text-[#30345E] font-semibold font-outfit bg-[#FFB619] w-full max-w-full my-0 mx-auto h-12 rounded-full"
      >
        Request Payment
      </button>
    );
  };

  const renderQuotesButton = (value: string) => {
    switch (value) {
      case QUOTE_STATUS.DRAFT:
        return (
          <button
            onClick={() =>
              navigate(`/insurance/quote-completed/${(item as Quote)?.id}`)
            }
            className="text-sm md:text-base text-[#30345E] font-semibold font-outfit bg-[#FFB619] w-full h-12 rounded-full"
          >
            Complete Quotation
          </button>
        );
      case QUOTE_STATUS.PENDING:
      case QUOTE_STATUS.PAYMENT_FAILED:
        return renderRequestButton();
      case QUOTE_STATUS.PAYMENT_PENDING:
      case QUOTE_STATUS.PAYMENT_COMPLETED:
      case QUOTE_STATUS.PAYMENT_REQUESTED:
      case QUOTE_STATUS.PAYMENT_PROCESSING:
        return (
          <div className="flex items-center gap-3">
            <ConfirmQuotePayment
              quoteId={(item as Quote)?.id}
              setAlert={setAlert}
              status={(item as Quote)?.status}
            />
            {renderRequestButton()}
          </div>
        );
      default:
        return <></>;
    }
  };

  const renderPolicyActions = (value: string) => {
    if (value === "ACTIVE") {
      return (
        <div className="flex flex-row gap-2">
          <ShareNotifs item={item as Policy} type="whatsapp" />
          <ShareNotifs item={item as Policy} type="mail" />
          <DownloadPolicy item={item as Policy} />
        </div>
      );
    }
  };

  const renderQuoteStatus = () => {
    let color = "";
    switch (item.status) {
      case QUOTE_STATUS.PAYMENT_FAILED:
        color = "text-[#30345E] bg-[#FECACA]";
        break;
      case QUOTE_STATUS.PENDING:
        color = "bg-[#FFED8E] text-[#30345E]";
        break;
      default:
        color = "text-[#1677ff] bg-[#e6f4ff]";
        break;
    }
    return (
      <p
        className={`text-sm font-semibold font-outfit ${color} rounded-[4px] py-1 px-2 flex items-center`}
      >
        {item?.status?.replaceAll("_", " ")}
      </p>
    );
  };

  const renderPolicyStatus = () => {
    let color = "";
    if (item.status === "PENDING") {
      color = "bg-[#FFED8E]";
    } else if (item.status === "ACTIVE") {
      color = "bg-[#86EFAC]";
    } else if (item.status === "EXPIRED") {
      color = "bg-[#FECACA]";
    }
    return (
      <p
        className={`text-sm text-[#30345E] font-semibold font-outfit ${color} rounded-[4px] py-1 px-2 flex items-center`}
      >
        {item?.status}
      </p>
    );
  };

  const renderInitial = () => {
    if (tabValue === "policies") {
      return getInitial(
        `${(item as Policy)?.customer?.firstName} ${
          (item as Policy)?.customer?.lastName
        }`
      );
    } else {
      return getInitial(
        `${(item as Quote)?.customer?.firstName} ${
          (item as Quote)?.customer?.lastName
        }`
      );
    }
  };

  const renderName = () => {
    if (tabValue === "policies") {
      return `${(item as Policy)?.customer?.firstName} ${
        (item as Policy)?.customer?.lastName
      }`;
    } else {
      return `${(item as Quote)?.customer?.firstName} ${
        (item as Quote)?.customer?.lastName
      }`;
    }
  };

  const getPolicyValue = (policy?: Policy, quote?: Quote) => {
    return {
      policyNumber: policy?.policyNumber ?? "Not available",
      coverageType:
        policy?.coverageType?.replaceAll("_", " ") ??
        quote?.product?.coverageType?.replaceAll("_", " ") ??
        "---",
      providerName: policy?.providerName ?? "---",
      coverageAmount: formatInt(
        Number(
          policy?.coverageAmount ?? quote?.vehicle?.valuationCarPrice ?? 0
        ),
        true
      ),
      coverDuration:
        policy?.quotation?.coverDuration ?? quote?.coverDuration
          ? `${policy?.quotation?.coverDuration ?? quote?.coverDuration} months`
          : "",
      amountDue: formatInt(
        Number(policy?.quotation?.amountDue ?? quote?.amountDue ?? 0),
        true
      ),
      carPrice:
        formatInt(Number(policy?.vehicle?.valuationCarPrice), true) ?? "---",
      monthlyPayment: formatInt(
        Number(
          policy?.quotation?.subsequentMonthlyAmount ??
            quote?.subsequentMonthlyAmount
        ),
        true
      ),
      instalments:
        (quote?.paymentPlan === PAYMENT_PLAN.MONTHLY
          ? quote?.instalmentCount
          : "---") ||
        (policy?.quotation?.paymentPlan === PAYMENT_PLAN.MONTHLY
          ? policy?.quotation?.instalmentCount
          : "---") ||
        "---",
      quoteDate: policy?.quotation?.quoteDate ?? "---",
      startDate: policy?.startDate
        ? moment(policy.startDate).format("YYYY-MM-DD")
        : "---",
      endDate: policy?.endDate
        ? moment(policy.endDate).format("YYYY-MM-DD")
        : "---",
      licensePlate:
        quote?.vehicle.licensePlateNumber ??
        policy?.vehicle.licensePlateNumber ??
        "---",
    };
  };

  const policyValues = getPolicyValue(item as Policy, item as Quote);

  const InsuranceDetails = [
    {
      label: "Policy No",
      value: policyValues.policyNumber,
      visible: true,
      id: 1,
    },
    {
      label: "Policy type",
      value: policyValues.coverageType,
      visible: true,
      id: 2,
    },
    {
      label: "Company",
      value: policyValues.providerName,
      visible: parentTabValue === "policies",
      id: 3,
    },
    {
      label: "Policy value",
      value: policyValues.coverageAmount,
      visible: true,
      id: 4,
    },
    {
      label: "Policy duration",
      value: policyValues.coverDuration,
      visible: true,
      id: 5,
    },
    {
      label: "Amount due today",
      value: policyValues.amountDue,
      visible: parentTabValue === "quotations",
      id: 6,
    },
    {
      label: "Car price",
      value: policyValues.carPrice,
      visible: parentTabValue === "policies",
      id: 7,
    },
    {
      label: "License Plate",
      value: policyValues.licensePlate,
      visible: true,
      id: 8,
    },
    {
      label: "Monthly payment",
      value: policyValues.monthlyPayment,
      visible: parentTabValue === "quotations",
      id: 9,
    },
    {
      label: "No of instalments",
      value: policyValues.instalments,
      visible: parentTabValue === "quotations",
      id: 10,
    },
    {
      label: "Date Sold",
      value: policyValues.quoteDate,
      visible: parentTabValue === "policies",
      id: 11,
    },
    {
      label: "Issue Date",
      value: policyValues.startDate,
      visible: parentTabValue === "policies",
      id: 12,
    },
    {
      label: "Expiry Date",
      value: policyValues.endDate,
      visible: parentTabValue === "policies",
      id: 13,
    },
  ];

  return (
    <Card>
      <Section
        onClick={() =>
          parentTabValue === "policies" &&
          navigate(`/insurance/${(item as Policy)?.id}`)
        }
        className="pb-3.5 md:pb-3 flex flex-col gap-3.5"
      >
        <div className="flex items-center justify-start">
          {parentTabValue === "policies" && renderPolicyStatus()}
          {parentTabValue === "quotations" && renderQuoteStatus()}
        </div>
        <div className="flex flex-col gap-1.5">
          <h1 className="text-base xl:text-[22px] text-[#202020] font-semibold">
            {(item as Policy)?.vehicle?.name ??
              (item as Quote)?.vehicle?.name ??
              "---"}
          </h1>

          <div className="grid grid-cols-2 lg:grid-cols-3 gap-y-1.5 md:gap-y-2 md:gap-x-8">
            {InsuranceDetails.map(
              (item) =>
                item.visible && (
                  <div key={item.id}>
                    <Title>{item.label}</Title>
                    <Text>{item.value}</Text>
                  </div>
                )
            )}
          </div>
        </div>
      </Section>

      <div className="pt-4 md:pt-3.5 flex flex-col gap-5">
        <Section
          onClick={() =>
            parentTabValue === "policies" &&
            navigate(`/insurance/${(item as Policy)?.id}`)
          }
        >
          <div className="flex items-start gap-2">
            <div className="text-sm md:text-lg font-semibold font-outfit uppercase text-[#30345E] w-10 h-10 rounded-full bg-[#CCDDFF] flex items-center justify-center">
              {renderInitial()}
            </div>
            <div>
              <p className="text-base md:text-lg text-[#202020] font-semibold leading-relaxed capitalize mb-1">
                {renderName()}
              </p>
              <p className="text-sm text-[#6B7280] mb-1 font-normal flex items-center gap-1">
                <MailIcon />{" "}
                {parentTabValue === "policies"
                  ? (item as Policy)?.customer?.emailAddress ?? "---"
                  : (item as Quote)?.customer?.emailAddress ?? "---"}
              </p>
              <p className="text-sm text-[#6B7280] font-normal flex items-center gap-1">
                <PhoneIcon />+
                {parentTabValue === "policies"
                  ? (item as Policy)?.customer?.phoneNumber ?? "---"
                  : (item as Quote)?.customer?.phoneNumber ?? "---"}
              </p>
            </div>
          </div>
        </Section>
        {parentTabValue === "quotations" && renderQuotesButton(item?.status)}
        {parentTabValue === "policies" && renderPolicyActions(item?.status)}
      </div>
    </Card>
  );
};

export default InsuranceCard;
