export const usageTypeOption = [
  {
    label: "Private",
    value: "private",
  },
  {
    label: "Commercial (Not available)",
    value: "commercial",
  },
  {
    label: "PSV (Not available)",
    value: "psv",
  },
];

export const IdTypes = [
  {
    label: "Passport",
    value: "passport",
  },
  {
    label: "National ID",
    value: "national_id",
  },
  {
    label: "Drivers License",
    value: "driver_license",
  },
  {
    label: "Voter ID",
    value: "voter_id",
  },
];

export const Genders = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

export const hasModifications = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
