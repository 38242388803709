import storage from "../lib/storage";

const getSymbol = () => {
  let symbol;
  const country = storage.getCountry();

  switch (country) {
    case "NG":
      symbol = "₦ ";
      break;
    case "GH":
      symbol = "GH₵ ";
      break;
    case "KE":
      symbol = "KSh ";
      break;
    case "CI":
      symbol = "CFA ";
      break;
    case "UG":
      symbol = "USh ";
      break;
    case "SN":
      symbol = "XOF ";
      break;
    case "EG":
      symbol = "EGP ";
      break;
    default:
      symbol = "";
      break;
  }

  return symbol;
};

const formatInt = (value: number, withSymbol?: boolean): string => {
  if (value !== undefined && value !== 0) {
    const stringValue = value.toString();
    const amountChunk = Number(stringValue.split(".")[0]);

    return `${withSymbol ? getSymbol() : ""}${amountChunk.toLocaleString(
      "en-US"
    )}`;
  }

  return `---`;
};

export default formatInt;
