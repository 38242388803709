import React, { useState, Fragment } from "react";
import { ShareIcon, WhatsappLogo } from "@/assets/svgs";
import { Policy } from "@/interface/policies";
import { AxiosError } from "axios";
import { Alert, Snackbar } from "@mui/material";
import { postShareViaEmail, postShareViaWhatsapp } from "@/hooks/nova-api";
import { Loader } from "@/pages/auth/styles/loader";
import { message } from "antd";

interface IShareMail {
  item: Policy;
  type: "mail" | "whatsapp";
}

const ShareNotifs: React.FC<IShareMail> = ({ item, type }) => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");

  const handleShareNotification = async () => {
    setLoading(true);
    const data = {
      id: item?.id,
    };
    try {
      const apiCall =
        type === "mail" ? postShareViaEmail : postShareViaWhatsapp;
      const response = await apiCall(item?.id, data);
      const parsedResponse = JSON.parse(response?.data);
      message.success(parsedResponse?.message);
    } catch (error) {
      if (error instanceof AxiosError) {
        const parsedError = JSON.parse(error?.response?.data);
        setAlert(parsedError?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleButtonText = (type: string) => {
    if (type === "mail") {
      return (
        <>
          <span className="hidden lg:block">
            <ShareIcon />
          </span>{" "}
          Send as Email
        </>
      );
    } else {
      return <WhatsappLogo />;
    }
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}
      >
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>

      <button
        onClick={handleShareNotification}
        className={`${
          type === "mail"
            ? "flex items-center justify-center gap-2 text-sm md:text-base text-[#30345E] font-semibold font-outfit bg-white border border-solid border-[#30345E] w-full max-w-full whitespace-nowrap px-4 h-10 md:h-12 rounded-full"
            : ""
        }`}
      >
        {loading ? <Loader variant="secondary" /> : handleButtonText(type)}
      </button>
    </Fragment>
  );
};

export default ShareNotifs;
