import React, { useState } from "react";
import { CloseIcon } from "@/assets/svgs";
import { useLocation, useNavigate } from "react-router-dom";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";

interface ISortLeadsProps {
  query: any;
  setCloseFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

const DateFilters = [
  {
    label: "Oldest to Newest",
    value: "asc",
  },
  {
    label: "Newest to Oldest",
    value: "desc",
  },
];

const PriceFilters = [
  {
    label: "Low to High",
    value: "asc",
  },
  {
    label: "High to low",
    value: "desc",
  },
];

const SortLeadsFiltering: React.FC<ISortLeadsProps> = ({
  query,
  setCloseFilter,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [filterDate, setFilterDate] = useState("");
  const [filterPrice, setFilterPrice] = useState("");

  const handleDateChange = (value: string) => {
    setFilterDate(value);
  };

  const handlePriceChange = (value: string) => {
    setFilterPrice(value);
  };

  const handleSubmitFilter = () => {
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        ...(filterDate && { order: filterDate }),
        ...(filterPrice && { sort: "price", order: filterPrice }),
      })}`
    );
  };

  const handleResetFilter = () => {
    setFilterDate("");
    setFilterPrice("");
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        order: "",
        sort: "",
      })}`
    );
  };

  return (
    <section className="">
      <div className="flex items-center justify-between mb-4">
        <p className="text-xl text-black font-semibold">Sort By</p>
        <button onClick={() => setCloseFilter(false)}>
          <CloseIcon />
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <p className="text-base text-black font-semibold mb-2">Price</p>
          <div className="flex items-center gap-2.5 flex-wrap">
            {PriceFilters.map((p) => (
              <button
                key={p.value}
                onClick={() => handlePriceChange(p.value)}
                className={`${
                  p.value === filterPrice
                    ? "bg-[#EEEFFF] border-[#EEEFFF]"
                    : "bg-white border-[#30345e]"
                } text-[#30345E] w-fit h-10 border border-solid p-4 rounded-lg flex items-center`}
              >
                {p.label}
              </button>
            ))}
          </div>
        </div>
        <div>
          <p className="text-base text-black font-semibold mb-2">Date</p>
          <div className="flex items-center gap-2.5 flex-wrap">
            {DateFilters.map((d) => (
              <button
                key={d.value}
                onClick={() => handleDateChange(d.value)}
                className={`${
                  d.value === filterDate
                    ? "bg-[#EEEFFF] border-[#EEEFFF]"
                    : "bg-white border-[#30345e]"
                } text-[#30345E] w-fit h-10 border border-solid p-4 rounded-lg flex items-center`}
              >
                {d.label}
              </button>
            ))}
          </div>
        </div>

        <div className="flex items-center gap-3 mt-8">
          <button
            onClick={handleResetFilter}
            className="w-full h-12 text-base bg-[#fff] text-[#30345e] font-semibold border border-solid border-[#30345e] rounded-full"
          >
            Reset
          </button>
          <button
            onClick={handleSubmitFilter}
            className="w-full h-12 text-base bg-[#FFB619] text-[#30345e] font-semibold border border-solid border-[#FFB619] rounded-full"
          >
            Apply
          </button>
        </div>
      </div>
    </section>
  );
};

export default SortLeadsFiltering;
