import { useCallback, useEffect, useMemo, useState } from "react";
import NewLoansBtn from "@/layouts/application-layouts/NewLoansBtn";
import {
  InsuranceTabContainer,
  ParentTabContainer,
  SearchField,
} from "@/layouts/application-layouts/styles";
import { Input, Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ITabFilterMap } from "@/types";
import InsuranceGrid from "@/layouts/insurance-layouts/InsuranceGrid";
import InventoryFilterModal from "@/layouts/application-layouts/InventoryFilterModal";
import { AxiosError } from "axios";
import {
  IPaymentSummary,
  IPolicyData,
  TParentTabs,
} from "@/interface/policies";
import storage from "@/lib/storage";
import { IQuotationData } from "@/interface/quotations";
import queryString from "query-string";
import {
  fetchInsuranceStats,
  fetchPolicies,
  fetchQuotations,
} from "@/hooks/nova-api";
import Cards from "@/components/cards";
import { getCountryCurrency } from "@/utils/getCountryCurrency";
import formatInt from "@/utils/format-int";
import { useDebouncedCallback } from "use-debounce";
import { Alert, Snackbar } from "@mui/material";

const InsurancePolicies = () => {
  const navigate = useNavigate();
  const country = storage.getCountry();
  const { hash } = useLocation();
  const { search } = useLocation();
  const user = storage.getUser();
  const isFranchise = storage.utilities.isFranchise();

  const [parentTabValue, setParentTabValue] = useState<string>("quotations");
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [policies, setPolicies] = useState<IPolicyData>();
  const [quotations, setQuotations] = useState<IQuotationData>();
  const [quotationsTab, setQuotationsTab] = useState("draft");
  const [policiesTab, setPoliciesTab] = useState("active");
  const [policyLoading, setPolicyLoading] = useState(false);
  const [quotationsLoading, setQuotationsLoading] = useState(false);
  const [insuranceStats, setInsuranceStats] = useState({} as IPaymentSummary);
  const [statsLoading, setStatsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [alert, setAlert] = useState("");
  const [policyCount, setPolicyCount] = useState("");
  const [quoteCount, setQuoteCount] = useState("");

  const query = useMemo(() => queryString.parse(search), [search]);

  let quoteFilter = "";
  switch (quotationsTab) {
    case "draft":
      quoteFilter = "DRAFT";
      break;
    case "pending":
      quoteFilter =
        "PENDING,PAYMENT_PENDING,PAYMENT_REQUESTED,PAYMENT_PROCESSING,PAYMENT_FAILED,PAYMENT_COMPLETED";
      break;
    case "listed":
      quoteFilter = "LISTED";
      break;
    default:
      break;
  }

  const handleFetchPolicies = useCallback(async () => {
    setPolicyLoading(true);
    const params = new URLSearchParams({
      country: country,
      status: policiesTab?.toUpperCase(),
      ...(searchValue &&
        parentTabValue === "policies" && { search: searchValue }),
      creatorId: isFranchise ? user?.company?.id : user?.id,
      pageSize: "12",
      ...query,
    });
    try {
      const response = await fetchPolicies(params);
      const parsedResponse = JSON.parse(response?.data);
      setPolicies(parsedResponse);
      setPolicyCount(parsedResponse?.pagination?.total);
    } catch (error) {
      if (error instanceof AxiosError) {
        setAlert(JSON.parse(error?.response?.data)?.message);
      }
    } finally {
      setPolicyLoading(false);
    }
  }, [
    country,
    policiesTab,
    searchValue,
    parentTabValue,
    isFranchise,
    user?.company?.id,
    user?.id,
    query,
  ]);

  useEffect(() => {
    if (parentTabValue === "policies") handleFetchPolicies();
  }, [handleFetchPolicies, parentTabValue]);

  const handleFetchQuotations = useCallback(async () => {
    setQuotationsLoading(true);
    const params = new URLSearchParams({
      country: country,
      status: quoteFilter,
      ...(searchValue &&
        parentTabValue === "quotations" && { search: searchValue }),
      creatorId: isFranchise ? user?.company?.id : user?.id,
      pageSize: "12",
      ...query,
    });
    try {
      const response = await fetchQuotations(params);
      const parsedResponse = JSON.parse(response?.data);
      setQuotations(parsedResponse);
      setQuoteCount(parsedResponse?.pagination?.total);
    } catch (error) {
      if (error instanceof AxiosError) {
        setAlert(JSON.parse(error?.response?.data)?.message);
      }
    } finally {
      setQuotationsLoading(false);
    }
  }, [
    country,
    isFranchise,
    parentTabValue,
    query,
    quoteFilter,
    searchValue,
    user?.company?.id,
    user?.id,
  ]);

  useEffect(() => {
    if (parentTabValue === "quotations") handleFetchQuotations();
  }, [handleFetchQuotations, parentTabValue]);

  const handleFetchInsuranceStats = useCallback(async () => {
    setStatsLoading(true);
    const params = new URLSearchParams({
      country: country,
      creatorId: isFranchise ? user?.company?.id : user?.id,
    });
    try {
      const response = await fetchInsuranceStats(params);
      const parsedResponse = JSON.parse(response?.data);
      setInsuranceStats(parsedResponse);
    } catch (error) {
      if (error instanceof AxiosError) {
        setAlert(JSON.parse(error?.response?.data)?.message);
      }
    } finally {
      setStatsLoading(false);
    }
  }, [country, isFranchise, user?.company?.id, user?.id]);

  useEffect(() => {
    handleFetchInsuranceStats();
  }, [handleFetchInsuranceStats]);

  const handleFetchedData = (value: string) => {
    switch (value) {
      case "policies":
        return policies;
      case "quotations":
        return quotations;
      default:
        return {} as IPolicyData | IQuotationData;
    }
  };

  const fetchedData = handleFetchedData(parentTabValue);

  useEffect(() => {
    const tabFilterMap: ITabFilterMap = {
      "#quotations": "quotations",
      "#policies": "policies",
    };
    if (tabFilterMap[hash]) {
      setParentTabValue(tabFilterMap[hash]);
    }
  }, [hash]);

  const handleParentTabChange = (key: string) => {
    navigate({ hash: `${key}` });
    setParentTabValue(key as TParentTabs);
  };

  const renderPolicyGrid = () => {
    return (
      <InsuranceGrid
        parentTabValue={parentTabValue}
        tabValue={policiesTab}
        data={fetchedData as IPolicyData}
        loading={policyLoading}
        query={query}
      />
    );
  };

  const renderQuotationGrid = () => {
    return (
      <InsuranceGrid
        parentTabValue={parentTabValue}
        tabValue={quotationsTab}
        data={fetchedData as IQuotationData}
        loading={quotationsLoading}
        query={query}
      />
    );
  };

  const renderQuotationTabs = () => {
    return (
      <InsuranceTabContainer style={{ paddingTop: "0rem" }}>
        <Tabs
          activeKey={quotationsTab}
          items={[
            {
              key: "draft",
              label: `Draft ${
                quotationsTab === "draft" &&
                !quotationsLoading &&
                searchValue &&
                quoteCount
                  ? `(${quoteCount})`
                  : ""
              }`,
              children: renderQuotationGrid(),
            },
            {
              key: "pending",
              label: `Pending Payment ${
                quotationsTab === "pending" &&
                !quotationsLoading &&
                searchValue &&
                quoteCount
                  ? `(${quoteCount})`
                  : ""
              }`,
              children: renderQuotationGrid(),
            },
          ]}
          onChange={(key) => {
            setQuotationsTab(key);
            setQuoteCount("");
          }}
        />
      </InsuranceTabContainer>
    );
  };

  const renderPoliciesTabs = () => {
    return (
      <InsuranceTabContainer style={{ paddingTop: "0rem" }}>
        <Tabs
          activeKey={policiesTab}
          items={[
            {
              key: "active",
              label: `Active ${
                policiesTab === "active" &&
                !policyLoading &&
                searchValue &&
                policyCount.length !== 0
                  ? `(${policyCount})`
                  : ""
              }`,
              children: renderPolicyGrid(),
            },
            {
              key: "expired",
              label: `Expired ${
                policiesTab === "expired" &&
                !policyLoading &&
                searchValue &&
                policyCount.length !== 0
                  ? `(${policyCount})`
                  : ""
              }`,
              children: renderPolicyGrid(),
            },
          ]}
          onChange={(key) => {
            setPoliciesTab(key);
            setPolicyCount("");
          }}
        />
      </InsuranceTabContainer>
    );
  };

  const formatStatValue = (value: number) => {
    if (statsLoading) {
      return "---";
    }
    return value !== 0 ? value : "---";
  };

  const placeholderText = (): string => {
    const placeholderMap: { [key: string]: Record<string, string> } = {
      quotations: {
        draft: "Search drafts",
        pending: "Search pending payments",
      },
      policies: {
        active: "Search active policies",
        expired: "Search expired policies",
      },
    };
    const tabValue =
      parentTabValue === "quotations" ? quotationsTab : policiesTab;
    return placeholderMap[parentTabValue]?.[tabValue] || "";
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    setSearchValue(value);
  }, 1000);

  const renderSearch = () => {
    return (
      <div className="p-2 bg-white border-b">
        <SearchField className="w-full md:w-auto">
          <div className="text-sm text-[#000000] font-semibold mb-1 hidden md:block">
            {parentTabValue === "quotations"
              ? "Search Quotations"
              : "Search Policies"}
          </div>
          <Input
            placeholder={placeholderText()}
            onChange={(e) => debouncedSearch(e.target.value)}
            prefix={
              <img
                src="https://media.autochek.africa/file/publicAssets/icon-start.svg"
                alt="search-icon"
              />
            }
            allowClear
            className="w-full md:w-[270px] xl:w-[320px]"
          />
        </SearchField>
      </div>
    );
  };

  return (
    <main className="flex flex-col gap-6">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}
      >
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>

      <h1 className="text-base xl:text-xl font-semibold text-[#30345E]">
        Insurance Policies
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-3 md:gap-4">
        <Cards
          cardHeader={"Quotations Pending Payment"}
          leftText={"TOTAL PENDING"}
          leftValue={
            formatStatValue(insuranceStats?.pendingPayment?.total) ?? "---"
          }
          rightText={`EXPECTED VALUE (${getCountryCurrency(country)})`}
          rightValue={formatInt(
            insuranceStats?.pendingPayment?.expectedValue,
            true
          )}
          color="#DC2626"
        />
        <Cards
          cardHeader={"Insurance Active"}
          leftText={"TOTAL ACTIVE"}
          leftValue={formatStatValue(insuranceStats?.active?.total) ?? "---"}
          rightText={`INSURANCE VALUE (${getCountryCurrency(country)})`}
          rightValue={formatInt(insuranceStats?.active?.insuranceValue, true)}
          color="#60A5FA"
        />
        <Cards
          cardHeader={"Insurance Expired"}
          leftText={"TOTAL EXPIRED"}
          leftValue={formatStatValue(insuranceStats?.expired?.total) ?? "---"}
          rightText={`EXPIRED VALUE (${getCountryCurrency(country)})`}
          rightValue={formatInt(insuranceStats?.expired?.expiredValue, true)}
          color="#22C55E"
        />
        <Cards
          cardHeader={"Total Premium Sold"}
          leftText={"TOTAL PREMIUM SOLD"}
          leftValue={
            formatStatValue(insuranceStats?.premiumSold?.total) ?? "---"
          }
          rightText={`SOLD VALUE (${getCountryCurrency(country)})`}
          rightValue={formatInt(insuranceStats?.premiumSold?.soldValue, true)}
          color="#FFB619"
        />
      </div>

      <div>
        <ParentTabContainer>
          <Tabs
            activeKey={parentTabValue as TParentTabs}
            items={[
              {
                key: "quotations",
                label: "Quotations",
                children: (
                  <>
                    {renderSearch()}
                    {renderQuotationTabs()}
                  </>
                ),
              },
              {
                key: "policies",
                label: "Policies",
                children: (
                  <>
                    {renderSearch()}
                    {renderPoliciesTabs()}
                  </>
                ),
              },
            ]}
            onChange={handleParentTabChange}
            type="card"
          />
        </ParentTabContainer>
      </div>

      <InventoryFilterModal
        isModalOpen={openFilter}
        handleOk={() => setOpenFilter(false)}
        handleCancel={() => setOpenFilter(false)}
        query={{}}
        loading={false}
        variant={"insurance"}
        setOpenFilter={setOpenFilter}
      />

      <NewLoansBtn />
    </main>
  );
};

export default InsurancePolicies;
