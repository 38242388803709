import { IDependency } from "@/layouts/insurance-layouts/quote-form/types";
import client from "@/lib/axios-client";
import storage from "@/lib/storage";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const params = {
  page_size: "1000",
};

const fetchCars = async (params: URLSearchParams) => {
  const response = await client.get("/v1/inventory/car", { params });
  const data = JSON.parse(response.data);
  return data.carList.map((car: any) => ({
    label: `${car.year} ${car.model.make.name} ${car.model.name} \u2013 (${car?.vin})`,
    value: car.id,
  }));
};

const fetchCarById = async (carId: string) => {
  const response = await client.get(`/v1/inventory/admin/car/${carId}`);
  const data = JSON.parse(response.data);
  return {
    make: data.model.make.name,
    model: data.model.name,
    year: data.year,
    exteriorColor: data.exteriorColor,
    bodyType: data.bodyType.name,
    usageType: "private",
    estimatedCarPrice: data.marketplacePrice,
    imageUrl: data.imageUrl,
    rating: data.gradeScore,
    carId: data.id,
  };
};

const fetchMakes = async (params: URLSearchParams) => {
  const response = await client.get("/v1/inventory/make", { params });
  const data = JSON.parse(response.data);
  return data.makeList.map((make: any) => ({
    id: make.id,
    value: make.name,
    label: make.name,
  }));
};

const fetchModels = async (makeId: string) => {
  const response = await client.get(`/v1/inventory/model?make_id=${makeId}`, {
    params,
  });
  const data = JSON.parse(response.data);
  return data.modelList.map((model: any) => ({
    id: model.id.toString(),
    value: model.name,
    label: model.name,
  }));
};

const fetchColors = async (t: any) => {
  const response = await client.get("/v1/inventory/marketplace/colors", {
    params,
  });
  const data = JSON.parse(response.data);
  const uniqueColors = Array.from(new Set(data.colors));
  return uniqueColors.map((color: any) => ({
    value: color,
    label: t(color),
  })) as any;
};

const fetchFranchise = async (params: URLSearchParams) => {
  const response = await client.get("/v1/franchise", { params });
  const data = JSON.parse(response.data);
  return data.result.map((dealer: any) => ({
    label: dealer.name,
    value: dealer.name,
    id: dealer.id,
    email: dealer.email,
  }));
};

const fetchFranchiseById = async (id: string) => {
  const response = await client.get(`/v1/franchise/${id}`);
  const data = JSON.parse(response.data);
  return {
    name: data.name,
    phoneNumber: data.phonenumber,
    email: data.email,
  };
};

const fetchBodyTypes = async (t: any) => {
  const response = await client.get("/v1/inventory/body_type", { params });
  const data = JSON.parse(response.data);
  return data.bodyTypeList.map((bodyType: any) => ({
    id: bodyType.id,
    value: bodyType.name,
    label: t(bodyType.name),
  }));
};

export const useApiFetchers = ({
  carLookup,
  carId,
  makeId,
  dealerLookup,
  dealerId,
}: IDependency) => {
  const country = storage.getCountry();
  const { t: t1 } = useTranslation("car-form");
  const { t } = useTranslation("colors");

  const { data: cars } = useQuery({
    queryKey: ["fetch_cars", country, carLookup],
    queryFn: () => {
      const params = new URLSearchParams({
        page_size: "10",
        country: country,
        ...(carLookup && { query: carLookup }),
      });
      return fetchCars(params);
    },
    refetchOnWindowFocus: false,
  });

  const {
    data: selectedCar,
    isFetching: isFetchingCars,
    isLoading: isLoadingCars,
  } = useQuery({
    queryKey: ["fetching_selected_cars", carId],
    queryFn: () => {
      return fetchCarById(carId);
    },
    enabled: !!carId,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: Infinity,
    refetchOnMount: true,
  });

  const { data: makes } = useQuery({
    queryKey: ["makes"],
    queryFn: () => {
      const params = new URLSearchParams({
        use_cache: "false",
        page_size: "1000",
      });
      return fetchMakes(params);
    },
    refetchOnWindowFocus: false,
  });

  const { data: models } = useQuery({
    queryKey: ["models", makeId],
    queryFn: () => fetchModels(makeId),
    enabled: !!makeId,
    refetchOnWindowFocus: false,
  });

  const { data: colors } = useQuery({
    queryKey: ["colors"],
    queryFn: () => fetchColors(t),
    refetchOnWindowFocus: false,
  });

  const { data: dealers, isFetching: isFetchingDealers } = useQuery({
    queryKey: ["dealers_list", country, dealerLookup],
    queryFn: () => {
      const params = new URLSearchParams({
        page_size: "50",
        country: country,
        ...(dealerLookup && { search: dealerLookup }),
      });
      return fetchFranchise(params);
    },
    refetchOnWindowFocus: false,
  });

  const { data: selectedDealer } = useQuery({
    queryKey: ["fetching_selected_dealer", dealerId],
    queryFn: () => {
      return fetchFranchiseById(dealerId);
    },
    enabled: !!dealerId,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: Infinity,
    refetchOnMount: true,
  });

  const { data: bodyTypes } = useQuery({
    queryKey: ["bodyTypes"],
    queryFn: () => fetchBodyTypes(t1),
    refetchOnWindowFocus: false,
  });

  return {
    cars,
    selectedCar,
    makes,
    models,
    colors,
    isSearchingCars: isFetchingCars || isLoadingCars,
    dealers,
    isSearchingDealers: isFetchingDealers,
    selectedDealer,
    bodyTypes,
  };
};
