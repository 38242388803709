import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

const options = {
  baseURL: process.env.REACT_APP_NOVA_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  transformResponse: [
    (data: AxiosResponse) => {
      return data;
    },
  ],
};

const novaClient = axios.create(options);

// Add a request interceptor
novaClient.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    // Do something before request is sent
    const apiKey = process.env.REACT_APP_NOVA_API_KEY;
    if (apiKey) config.headers!["x-api-key"] = apiKey;

    return config;
  }
  // function (error: AxiosError) {
  //   // Do something with request error
  //   return Promise.reject(error);
  // }
);

// Add a response interceptor
novaClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default novaClient;
