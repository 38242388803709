import { useCallback, useEffect, useState } from "react";
import {
  FormDropdownStyle,
  InputFieldStyle,
  InputLabelStyle,
  SelectTextFieldStyle,
} from "@/layouts/application-layouts/styles";
import storage from "@/lib/storage";
import { IOption } from "@/types";
import {
  Autocomplete,
  FormControl,
  FormLabel,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { IPersistedJson } from "@/interface/upfront";
import { Loader } from "@/pages/auth/styles/loader";
import useQuoteByIdStore from "@/states/quotes";
import CustomerContactCard from "@/components/upfront-payment/CustomerContactCard";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import {
  debitMandateDefaultValues,
  debitMandateSchema,
  DebitMandateValues,
} from "@/validations/insurance-quote.schema";
import { validateDebitMandate } from "@/utils/validate-quote-form";
import GeneratedLinkCard from "@/components/upfront-payment/GeneratedLinkCard";
import useSupportedBanksStore from "@/states/supported-banks";
import { initiateDirectDebit, postInitiatePayment } from "@/hooks/nova-api";
import ConfirmQuotePayment from "@/components/insurance/ConfirmPayment";
import {
  DIRECT_DEBIT_STATUS,
  IDirectDebits,
  QUOTE_STATUS,
} from "@/interface/quotations";
import useDirectDebitStore from "@/states/direct-debit-status";
import { message } from "antd";

const DebitMandateForm = () => {
  const country = storage.getCountry();
  const queryParam = new URLSearchParams(window.location.search);
  const quoteId = queryParam.get("quote_id") as string;

  const [loading, setLoading] = useState<boolean>(false);
  const [linkGenerated, setLinkGenerated] = useState(false);
  const [storeData, setStoreData] = useState<IPersistedJson>();
  const [alert, setAlert] = useState("");

  const { quoteLoading, quotesById, fetchQuotesById } = useQuoteByIdStore();
  const { directDebitStatus, fetchDirectDebitStatus } = useDirectDebitStore();
  const { supportedBanksLoading, supportedBanks, fetchSupportedBanks } =
    useSupportedBanksStore();

  const isDebitMandateRequested =
    !!quotesById?.directDebitAuthorization?.redirectUrl;

  useEffect(() => {
    if (quoteId) fetchQuotesById(quoteId);
  }, [fetchQuotesById, quoteId]);

  useEffect(() => {
    if (quotesById?.customer?.id) {
      fetchDirectDebitStatus(quotesById?.customer?.id);
    }
  }, [
    fetchDirectDebitStatus,
    quotesById?.customer?.id,
    storeData?.redirectUrl,
  ]);

  useEffect(() => {
    fetchSupportedBanks();
  }, [fetchSupportedBanks]);

  const methods = useForm({
    mode: "all",
    defaultValues: debitMandateDefaultValues,
    resolver: zodResolver(debitMandateSchema),
  });

  const isGenerated =
    (isDebitMandateRequested &&
      directDebitStatus?.status === DIRECT_DEBIT_STATUS.PENDING) ||
    !!storeData ||
    [QUOTE_STATUS.PAYMENT_REQUESTED, QUOTE_STATUS.PAYMENT_PENDING].includes(
      quotesById?.status as QUOTE_STATUS
    );

  const errors = { ...methods.formState.errors } as Record<string, any>;
  const formValues = methods.watch();
  const isValid = validateDebitMandate(formValues);
  const disabled = !isValid || isGenerated;

  const handleDebitMandate = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      customerId: quotesById?.customer?.id,
      bankCode: formValues.supportedBank,
      accountNumber: formValues.accountNumber,
      country: country,
    };

    try {
      const response = await initiateDirectDebit(data);
      const parsedResponse = JSON.parse(response?.data);
      setStoreData(parsedResponse);
      setLinkGenerated(true);
      setTimeout(() => {
        setLinkGenerated(false);
      }, 4000);
      fetchDirectDebitStatus(quotesById?.customer?.id as string);
    } catch (error: any) {
      const parsedError = JSON.parse(error?.response?.data);
      setAlert(parsedError.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInitializePayment = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      quotationId: quoteId,
    };
    try {
      await postInitiatePayment(data);
      message.success("Payment initiated successfully");
      fetchQuotesById(quoteId);
    } catch (error: any) {
      const parsedError = JSON.parse(error?.response?.data);
      setAlert(parsedError.message);
    } finally {
      setLoading(false);
    }
  };

  const mappedQuotesData = useCallback(
    (data: IDirectDebits): DebitMandateValues => {
      return {
        supportedBank: data?.bankCode ?? "",
        accountNumber: data?.accountNumber ?? "",
      };
    },
    []
  );

  useEffect(() => {
    if (directDebitStatus) {
      const mappedData = mappedQuotesData(directDebitStatus);
      methods.reset(mappedData);
    }
  }, [mappedQuotesData, methods, directDebitStatus]);

  const redirectSubmitText = () => {
    if (
      directDebitStatus?.status === DIRECT_DEBIT_STATUS.ACTIVE ||
      directDebitStatus?.status === DIRECT_DEBIT_STATUS.CREATED
    ) {
      return "Initiate Payment";
    } else if (
      [DIRECT_DEBIT_STATUS.ACTIVE, DIRECT_DEBIT_STATUS.CREATED]?.includes(
        directDebitStatus?.status as DIRECT_DEBIT_STATUS
      ) &&
      quotesById?.status === QUOTE_STATUS.PAYMENT_FAILED
    ) {
      return "Retry Payment";
    } else if (
      directDebitStatus?.status === DIRECT_DEBIT_STATUS.PENDING ||
      directDebitStatus?.status === DIRECT_DEBIT_STATUS.FAILED
    ) {
      return "Generate Link";
    }
  };

  const renderSubmitAction = (e: React.FormEvent) => {
    if (directDebitStatus?.status === DIRECT_DEBIT_STATUS.ACTIVE) {
      handleInitializePayment(e);
    } else {
      handleDebitMandate(e);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}
      >
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>

      <div className="flex flex-col gap-8 w-full max-w-full md:max-w-[70%] xl:max-w-[40%] my-0 mx-auto">
        <CustomerContactCard
          firstName={quotesById?.customer?.firstName}
          lastName={quotesById?.customer.lastName}
          emailAddress={quotesById?.customer.emailAddress}
          phoneNumber={quotesById?.customer.phoneNumber}
          loading={quoteLoading}
        />

        {linkGenerated && (
          <div className="text-sm text-white font-semibold bg-[#22C55E] h-10 w-full rounded-md flex items-center justify-center">
            Link generated
          </div>
        )}

        {[
          QUOTE_STATUS.PAYMENT_REQUESTED,
          QUOTE_STATUS.PAYMENT_FAILED,
          QUOTE_STATUS.PAYMENT_PENDING,
        ].includes(quotesById?.status as QUOTE_STATUS) ||
        (quotesById?.status === QUOTE_STATUS.PENDING &&
          directDebitStatus?.status === DIRECT_DEBIT_STATUS.ACTIVE) ? (
          <></>
        ) : (
          <>
            {(storeData || isDebitMandateRequested) && (
              <GeneratedLinkCard
                redirectUrl={
                  storeData?.redirectUrl ??
                  (quotesById?.directDebitAuthorization?.redirectUrl as string)
                }
              />
            )}
          </>
        )}

        <form className="flex flex-col gap-6 bg-white p-4 rounded-2xl">
          <FormControl fullWidth>
            <FormLabel sx={InputLabelStyle}>Bank</FormLabel>
            <Controller
              name="supportedBank"
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  fullWidth
                  id="supportedBank"
                  disablePortal
                  disabled={
                    isGenerated ||
                    quotesById?.status === QUOTE_STATUS.PAYMENT_FAILED ||
                    directDebitStatus?.status === DIRECT_DEBIT_STATUS.ACTIVE ||
                    directDebitStatus?.status === DIRECT_DEBIT_STATUS.CREATED
                  }
                  loading={supportedBanksLoading}
                  options={supportedBanks ?? []}
                  onChange={(_, newValue) => {
                    onChange(newValue?.id);
                  }}
                  value={
                    supportedBanks?.find(
                      (option: any) => option?.id === value
                    ) || null
                  }
                  getOptionLabel={(option: IOption) => option?.label || ""}
                  isOptionEqualToValue={(option: IOption, value: IOption) =>
                    option.value === value.value
                  }
                  sx={FormDropdownStyle}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select bank"
                      sx={SelectTextFieldStyle}
                    />
                  )}
                />
              )}
            />
            {errors?.supportedBank?.message && (
              <span className="text-red-600 text-sm">
                {errors?.supportedBank?.message}
              </span>
            )}
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={InputLabelStyle}>Account Number</FormLabel>
            <Controller
              name="accountNumber"
              control={methods.control}
              render={({ field }) => (
                <TextField
                  id="accountNumber"
                  fullWidth
                  type="text"
                  placeholder="Enter account number"
                  sx={InputFieldStyle}
                  disabled={
                    isGenerated ||
                    quotesById?.status === QUOTE_STATUS.PAYMENT_FAILED ||
                    [
                      DIRECT_DEBIT_STATUS.ACTIVE,
                      DIRECT_DEBIT_STATUS.CREATED,
                    ].includes(directDebitStatus?.status as DIRECT_DEBIT_STATUS)
                  }
                  inputProps={{
                    maxLength: 15,
                  }}
                  onChange={(event) => {
                    const numericValue = event.target.value.replace(/\D/g, "");
                    field.onChange(numericValue);
                  }}
                  value={field.value}
                  onBeforeInput={(event: any) => {
                    if (!/^\d*$/.test(event.data)) {
                      event.preventDefault();
                    }
                  }}
                />
              )}
            />
            {errors?.accountNumber?.message && (
              <span className="text-red-600 text-sm">
                {errors?.accountNumber?.message}
              </span>
            )}
          </FormControl>

          <div className="flex flex-col md:flex-row gap-3 mt-4">
            <ConfirmQuotePayment
              quoteId={quoteId}
              setAlert={setAlert}
              status={quotesById?.status as QUOTE_STATUS}
            />
            <button
              onClick={(e) => renderSubmitAction(e)}
              disabled={disabled}
              className="text-sm md:text-base font-semibold text-[#30345E] bg-[#FFB619] rounded-[60px] w-fit max-w-full md:min-w-[50%] h-12 px-6 my-0 mx-auto disabled:text-[#00000040] disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
            >
              {loading ? (
                <Loader variant="secondary" />
              ) : (
                <>{redirectSubmitText() ?? "Generate Link"}</>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DebitMandateForm;
