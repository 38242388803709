import { IInsuranceFormProps } from "./types";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import {
  _RadioLabel,
  InputLabelStyle,
  RadioChecked,
  RadioLabel,
} from "@/layouts/application-layouts/styles";
import { Controller } from "react-hook-form";
import { Loader } from "@/pages/auth/styles/loader";
import { usageTypeOption } from "@/data/insurance-data";

const Loading = () => {
  return (
    <div className="flex items-center py-4">
      <Loader variant="secondary" />
    </div>
  );
};

const InsuranceSection: React.FC<IInsuranceFormProps> = ({
  methods,
  insuranceOptions,
  insuranceLoading,
  paymentOptions,
  paymentOptLoading,
}) => {
  const values = methods.getValues();
  const errors = { ...methods.formState.errors } as Record<string, any>;

  return (
    <section className="pt-2 pb-4 flex flex-col gap-6">
      <div>
        <FormLabel sx={InputLabelStyle}>Insurance type</FormLabel>
        <Controller
          name="insuranceType"
          control={methods.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              {insuranceLoading ? (
                <Loading />
              ) : (
                (insuranceOptions || []).map((opt: any) => (
                  <FormControlLabel
                    key={opt.value}
                    value={opt.value}
                    control={<Radio sx={RadioChecked} />}
                    label={opt.label}
                    sx={RadioLabel}
                  />
                ))
              )}
            </RadioGroup>
          )}
        />
        {errors?.insuranceType?.message && (
          <span className="text-red-600 text-sm">
            {errors?.insuranceType?.message}
          </span>
        )}
      </div>

      <div className="grid grid-cols-2 gap-5">
        <div>
          <FormLabel sx={InputLabelStyle}>Usage type</FormLabel>
          <Controller
            name="usageType"
            control={methods.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                {(usageTypeOption || []).map((opt: any) => (
                  <FormControlLabel
                    key={opt.value}
                    value={opt.value}
                    control={<Radio sx={RadioChecked} />}
                    label={opt.label}
                    disabled={opt.value === "commercial" || opt.value === "psv"}
                    sx={_RadioLabel}
                  />
                ))}
              </RadioGroup>
            )}
          />
          {errors?.usageType?.message && (
            <span className="text-red-600 text-sm">
              {errors?.usageType?.message}
            </span>
          )}
        </div>

        {values.insuranceType && (
          <div>
            <FormLabel sx={InputLabelStyle}>Payment option</FormLabel>
            <Controller
              name="paymentOption"
              control={methods.control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  {paymentOptLoading ? (
                    <Loading />
                  ) : (
                    (paymentOptions || []).map((opt: any) => (
                      <FormControlLabel
                        key={opt.value}
                        value={opt.value}
                        control={<Radio sx={RadioChecked} />}
                        label={opt.label}
                        sx={_RadioLabel}
                      />
                    ))
                  )}
                </RadioGroup>
              )}
            />
            {errors?.paymentOption?.message && (
              <span className="text-red-600 text-sm">
                {errors?.paymentOption?.message}
              </span>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default InsuranceSection;
