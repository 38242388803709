import create from "zustand";

type TLeadModalProps = {
  isBookLeadOpen: boolean;
  openBookLeadModal: () => void;
  closeBookLeadModal: () => void;
  isSalesOpen: boolean;
  openSalesModal: () => void;
  closeSalesModal: () => void;
  isClosedLeadOpen: boolean;
  openClosedLeadModal: () => void;
  closeClosedLeadModal: () => void;
};

const useLeadModalStore = create<TLeadModalProps>()((set) => ({
  isBookLeadOpen: false,
  openBookLeadModal: () => set({ isBookLeadOpen: true }),
  closeBookLeadModal: () => set({ isBookLeadOpen: false }),
  isSalesOpen: false,
  openSalesModal: () => set({ isSalesOpen: true }),
  closeSalesModal: () => set({ isSalesOpen: false }),
  isClosedLeadOpen: false,
  openClosedLeadModal: () => set({ isClosedLeadOpen: true }),
  closeClosedLeadModal: () => set({ isClosedLeadOpen: false }),
}));

export default useLeadModalStore;
