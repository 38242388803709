import React, { useState } from "react";
import { Loader } from "@/pages/auth/styles/loader";
import { postVerifyPayment } from "@/hooks/nova-api";
import useQuoteByIdStore from "@/states/quotes";
import { QUOTE_STATUS } from "@/interface/quotations";
import { useNavigate } from "react-router-dom";

interface IConfirmPaymentProps {
  quoteId: string;
  setAlert: (message: string) => void;
  status: string | QUOTE_STATUS;
}

const ConfirmQuotePayment: React.FC<IConfirmPaymentProps> = ({
  quoteId,
  setAlert,
  status,
}) => {
  const navigate = useNavigate();
  const [verifyLoading, setVerifyLoading] = useState(false);
  const { fetchQuotesById } = useQuoteByIdStore();

  const handleVerifyPayment = async (e: React.FormEvent) => {
    e.preventDefault();
    setVerifyLoading(true);
    const data = {
      quotationId: quoteId,
    };
    try {
      const response = await postVerifyPayment(data);
      const parsedResponse = JSON.parse(response?.data);
      fetchQuotesById(quoteId);
      navigate(
        `/insurance/payment-completed?policy_id=${parsedResponse?.policyId}`
      );
    } catch (error: any) {
      const parsedError = JSON.parse(error?.response?.data);
      setAlert(parsedError.message);
    } finally {
      setVerifyLoading(false);
    }
  };

  return (
    <>
      {[
        QUOTE_STATUS.PAYMENT_REQUESTED,
        QUOTE_STATUS.PAYMENT_PROCESSING,
        QUOTE_STATUS.PAYMENT_COMPLETED,
        QUOTE_STATUS.PAYMENT_PENDING,
      ].includes(status as QUOTE_STATUS) && (
        <button
          onClick={handleVerifyPayment}
          className="text-sm md:text-base font-semibold bg-white border border-solid border-[#30345E] w-full max-w-full md:max-w-[60%] my-0 mx-auto h-12 rounded-full"
        >
          {verifyLoading ? <Loader variant="secondary" /> : "Confirm Payment"}
        </button>
      )}
    </>
  );
};

export default ConfirmQuotePayment;
