import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import storage from "@/lib/storage";

const nextSteps = [
  {
    title: "Generate quote",
    text: "You can instantly generate a quote for a car, present it to your customer and if ok with it, you can send it to the customer",
  },
  {
    title: "Add customer details",
    text: "Once the customer has received the quote, they can proceed to pay for the insurance policy",
  },
  {
    title: "Track customer payment and renewals",
    text: "You can easily track their payments and also remind them when  the insurance is due to a renewal",
  },
  {
    title: "Earn commission through insurance sales",
    text: "You can easily earn commissions through insurance sales",
  },
];

const SellInsurance = () => {
  const navigate = useNavigate();
  const user = storage.getUser();
  const isFranchise = storage.utilities.isFranchise();

  return (
    <main className="flex flex-col gap-10">
      <div className="hidden md:block">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-1 font-semibold text-[#30345E]"
        >
          <LeftOutlined /> Back
        </button>
      </div>

      <div className="flex flex-col gap-5 text-button-variants-primary-scene mx-auto w-full max-w-full md:max-w-[75%] xl:max-w-[45%] bg-white p-3 md:p-6">
        <div className="flex items-center justify-between">
          <h2 className="!font-outfitBold font-semibold text-lg md:text-xl text-inputs-regular-input">
            Sell insurance
          </h2>
          <button className="block md:hidden" onClick={() => navigate(-1)}>
            <img
              src="https://media.autochek.africa/file/publicAssets/Icon-2.svg"
              alt="Close Page"
              className="w-3 h-3"
            />
          </button>
        </div>

        <img
          src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/smiling-teen-posing-with-small-red-car%201.webp"
          alt="Girl holding a girl"
          className="w-full h-auto"
        />

        <div className="">
          <h2 className="!font-outfitBold font-semibold text-lg xl:text-xl mb-1 xl:mb-2">
            Dear{" "}
            {isFranchise ? (
              user?.company?.name ?? "Partner"
            ) : (
              <>
                {user?.firstname
                  ? `${user?.firstname} ${user?.lastname}`
                  : "Partner"}
              </>
            )}
            ,
          </h2>
          <p className="text-base font-medium leading-tight">
            You can now sell insurance policies to your customers. Here is what
            to expect;
          </p>
        </div>

        <div className="divide-y divide-[#E5E7EB]">
          {nextSteps.map((el) => (
            <div className="flex gap-2 py-3" key={nextSteps.indexOf(el)}>
              <img
                src="/images/stepper.svg"
                className="w-6 h-6"
                alt="Stepper"
              />
              <div>
                <h2 className="!font-outfitBold font-semibold text-base mb-1 md:mb-1.5">
                  {el.title}
                </h2>
                <p className="font-thin text-sm">{el.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mx-auto my-0 w-full max-w-full md:max-w-[75%] xl:max-w-[45%] bg-white py-8 xl:py-6 px-4 flex justify-center">
        <button
          onClick={() => navigate(`/insurance/quote-form`)}
          className="w-full max-w-full md:max-w-[75%] xl:max-w-[80%] bg-[#FFB619] h-12 font-semibold text-sm md:text-base text-[#30345E] flex justify-center items-center rounded-full"
        >
          Proceed
        </button>

        <div className="flex gap-3 md:gap-4 w-full" style={{ display: "none" }}>
          <button className="w-full max-w-[50%] bg-[#FFB619] h-12 font-semibold text-sm text-[#30345E] flex justify-center items-center rounded-full">
            Generate New
          </button>
          <button className="w-full max-w-[50%] bg-[#fffff] h-12 font-semibold text-sm text-[#30345E] border border-solid border-[#30345E] flex justify-center items-center rounded-full">
            Show Existing Policies
          </button>
        </div>
      </div>
    </main>
  );
};

export default SellInsurance;
