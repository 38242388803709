import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TableContainer,
  EquityPaymentContainer,
} from "@/layouts/application-layouts/styles";
import tw from "tailwind-styled-components";
import {
  Form,
  Input,
  Upload,
  message,
  InputNumber,
  Tag,
  Table,
  Select,
} from "antd";
import storage from "@/lib/storage";
import { useTranslation } from "react-i18next";
import { EquityInputNumber } from "@/pages/auth/styles/sign-in";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import formatInt from "@/utils/format-int";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import { Loader } from "../auth/styles/loader";
import EquityApprovalModal from "@/components/upfront-payment/EquityApprovalModal";
import DeactivateDebitModal from "@/components/upfront-payment/DeactivateDebitModal";
import useUserAccountStore from "@/states/user-account";
import {
  fetchEquityCollections,
  fetchEquityConfig,
  fetchEquityPayments,
  retryPayment,
} from "@/hooks/use-api";
import { IEquityCollectionPayment, IEquityResponse } from "@/interface/upfront";
import useLoansByIdStore from "@/states/loans-by-id";
import { AxiosError } from "axios";
import { itemRender } from "@/utils/listItemRender";
import dayjs from "dayjs";

interface IActivePage {
  currentPage: number;
  pageSize: number;
}

const equityCollectionColumns = [
  {
    title: "Amount Req.",
    dataIndex: "amountRequested",
    key: "amountRequested",
    width: 120,
  },
  {
    title: "Amount Paid",
    dataIndex: "amountPaid",
    key: "amountPaid",
    width: 120,
  },
  {
    title: "Reference",
    dataIndex: "reference",
    key: "reference",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    key: "createdBy",
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
  },
];

const equityPaymentsColumns = [
  {
    title: "Amount Paid",
    dataIndex: "amountPaid",
    key: "amountPaid",
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
  },
  {
    title: "Paid To",
    dataIndex: "paidTo",
    key: "paidTo",
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

const Text = tw.div`text-base text-[#6B7280] font-semibold`;

export default function UploadEquity() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const country = storage.getCountry();
  const queryParam = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const API_URL = process.env.REACT_APP_AXIOS_BASE_URL;
  const token = storage.getToken();
  const { t } = useTranslation("loan-form");
  const { t: t1 } = useTranslation("dashboard");
  const loan_id = queryParam.get("loanId") as string;
  const user_id = queryParam.get("userId") as string;
  const [loading, setLoading] = useState(false);
  const [finalOffer, setFinalOffer] = useState<any>([]);
  const [fetchEquity, setFetchEquity] = useState<IEquityResponse>();
  const [activePage, setActivePage] = useState<IActivePage>({
    currentPage: 1,
    pageSize: 10,
  });
  const isAssumedUser = storage.getAssumedUser();
  const [openProceedModal, setOpenProceedModal] = useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [equityCollection, setEquityCollection] = useState<
    IEquityCollectionPayment[]
  >([]);
  const [retryLoading, setRetryLoading] = useState(false);
  const [equityConfig, setEquityConfig] = useState<string[]>([]);

  const { userAccount, fetchUserAccounts } = useUserAccountStore();
  const { loansById, fetchLoansById } = useLoansByIdStore();

  useEffect(() => {
    if (user_id) fetchUserAccounts(user_id);
  }, [fetchUserAccounts, user_id]);

  const handleRetryPayment = async () => {
    setRetryLoading(true);
    const payload = {
      loan_id: loan_id,
    };
    try {
      const response = await retryPayment(payload);
      if (response) {
        message.success("Upfront payment added");
        queryClient.invalidateQueries(["equity_payment"]);
        queryClient.invalidateQueries(["equity_collection"]);
        queryClient.invalidateQueries(["loans_by_id"]);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        message.error(JSON.parse(error.response?.data).error);
      }
    } finally {
      setRetryLoading(false);
    }
  };

  const handleStatusColor = (status: string) => {
    switch (status) {
      case "processing":
      case "partialProcessing":
        return "processing";
      case "cancelled":
      case "success":
        return "success";
      case "failed":
        return "error";
      default:
        return "default";
    }
  };

  const handleStatusName = (status: string) => {
    switch (status) {
      case "cancelled":
        return "finalized";
      case "partialProcessing":
        return "Partial Processing";
      default:
        return status;
    }
  };

  const equityCollectionData = equityCollection.map((item) => ({
    key: item.id,
    amountRequested: formatInt(item.amountRequested, true),
    amountPaid: formatInt(item.amountPaid, true),
    reference: item.reference ?? "---",
    status: (
      <Tag color={handleStatusColor(item?.status)} className="capitalize">
        {handleStatusName(item?.status)}
      </Tag>
    ),
    createdBy: item.createdBy.replaceAll("_", " ") ?? "---",
    createdAt: item?.createdAt
      ? dayjs(item?.createdAt).format("MMM D, YYYY h:mma")
      : "---",
    action: item?.status === "partialProcessing" && (
      <button
        onClick={handleRetryPayment}
        className="text-sm text-white font-semibold bg-[#DC2626] rounded-[44px] w-fit h-8 px-6 border border-solid border-[#DC2626]"
      >
        {retryLoading ? <Loader variant="secondary" /> : "Finalize"}
      </button>
    ),
  }));

  const equityPaymentsData = fetchEquity?.equities?.map((item) => ({
    key: item.id,
    balance: formatInt(item.balance, true),
    amountPaid: formatInt(item.amountPaid, true),
    paidTo: item.paidTo ? (
      <Tag color="processing" className="capitalize w-fit px-2">
        {item?.paidTo?.replaceAll("_", " ")}
      </Tag>
    ) : (
      "---"
    ),
    createdAt: item?.createdAt
      ? dayjs(item?.createdAt).format("MMM D, YYYY h:mma")
      : "---",
    action: item?.fileUrl?.startsWith("https://") ? (
      <button
        disabled={!item?.fileUrl}
        className="text-[#30345e] underline font-medium"
        onClick={() => window.open(item?.fileUrl, "_blank", "noopener")}
      >
        Preview
      </button>
    ) : (
      <span>{item?.fileUrl}</span>
    ),
  }));

  useEffect(() => {
    if (loan_id) fetchLoansById(loan_id);
  }, [fetchLoansById, loan_id]);

  useEffect(() => {
    const finalOfferI = loansById?.offers?.filter(function (item) {
      return item?.offerType === "FINAL_OFFER";
    });
    setFinalOffer(finalOfferI?.[0]);
  }, [loansById?.offers]);

  const { isLoading: fetchEquityLoading } = useQuery({
    queryKey: ["equity_payment", loan_id],
    queryFn: () => {
      const params = new URLSearchParams({
        loan_id: loan_id,
      });
      return fetchEquityPayments(params);
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      setFetchEquity(parsedResponse);
    },
    enabled: !!loan_id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: equityCollectionLoading } = useQuery({
    queryKey: ["equity_collection", loan_id],
    queryFn: () => {
      return fetchEquityCollections(loan_id);
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      setEquityCollection(parsedResponse?.collections);
    },
    enabled: !!loan_id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: loadEquityConfig } = useQuery({
    queryKey: ["equity_config", loan_id],
    queryFn: () => {
      const params = new URLSearchParams({
        loan_id: loan_id,
      });
      return fetchEquityConfig(params);
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      setEquityConfig(parsedResponse?.equityPaidTo);
    },
    enabled: !!loan_id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const props = {
    name: "file",
    multiple: false,
    accept: ".png, .jpg, .pdf",
    action: `${API_URL}document/upload`,
    headers: {
      authorization: `bearer ${token}`,
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {},
  };

  const isEquityEligible = (): boolean => {
    const equities = fetchEquity?.equities ?? [];
    if (
      equities.length === 0 ||
      (equities?.length > 0 && equities[0]?.balance > 0)
    ) {
      return true;
    }
    return false;
  };

  const handleSubmitForm = async (values: any) => {
    setLoading(true);
    const payload = {
      loan_id: loan_id,
      amount: values.amount.replaceAll(",", ""),
      file_url: values.document_upload[0].response.file.url,
      password: values.password,
      paid_to: values.paidTo,
    };

    try {
      const response = await client.post(`/v2/origination/equity`, payload);
      if (response) {
        message.success("Upfront payment added");
        queryClient.invalidateQueries(["equity_payment"]);
        queryClient.invalidateQueries(["equity_collection"]);
        queryClient.invalidateQueries(["loans_by_id"]);
        setLoading(false);
        form.resetFields();
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const parsedError = JSON.parse(error?.response?.data);
        const errorMessage = parsedError?.message ?? parsedError?.error;
        message.error(errorMessage);
        setLoading(false);
      }
    }
  };

  const handleActiveStatus = (status: string) => {
    let color = "";
    switch (status) {
      case "active":
        color = "text-[#15803D] bg-[#86EFAC]";
        break;
      case "pending":
        color = "ext-[#202020] bg-[#FFED8E]";
        break;
      default:
        color = "text-[#202020] bg-[#FECACA]";
        break;
    }
    return color;
  };

  const buttonStatusState = () => {
    if (userAccount?.status === "active") {
      return <></>;
    } else {
      return (
        <button
          onClick={() =>
            navigate(
              `/applications/direct-debit?loan_id=${loan_id}&user_id=${user_id}`
            )
          }
          className="text-sm text-[#30345E] font-semibold bg-[#FFB619] rounded-[44px] w-fit h-8 px-5"
        >
          View bank details
        </button>
      );
    }
  };

  if (fetchEquityLoading) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  return (
    <main className="flex flex-col gap-5">
      <div className="relative mb-5">
        <div className="text-2xl font-bold text-center text-[#30345e]">
          Upfront Payment
        </div>
        <button
          onClick={() => navigate(-1)}
          className="absolute left-0 top-[4px] cursor-pointer"
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
            alt="left-arrow"
          />
        </button>
      </div>

      <div className="w-full md:w-[75%] xl:w-[68%] h-auto pb-0 bg-white my-0 mx-auto rounded-lg">
        <div className="flex flex-col gap-2 px-4 py-4 lg:px-6 lg:pt-6 lg:pb-4 border-b border-solid border-[#E5E7EB] mb-0">
          <div className="flex flex-col items-center">
            <img
              src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/cash-fill.png?updatedAt=1710196935945"
              alt="Approved Equity"
              width="30px"
              height="30px"
            />
            <div className="text-[#6B7280] font-semibold text-center leading-tight text-sm mb-1.5">
              Approved Upfront Payment <br />
              <span className="text-xs font-medium">(equity + charges)</span>
            </div>
            <div className="text-[#30345e] font-bold font-outfitbold text-2xl">
              {formatInt(
                finalOffer?.additionalPayments?.find(
                  (item: any) => item?.name === "Total upfront payment"
                )?.value,
                true
              )}
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <Text>Paid</Text>
              <div className="font-semibold font-outfitbold text-xl text-[#22C55E]">
                {formatInt(
                  (fetchEquity?.equities ?? [])?.reduce(
                    (acc: number, item: Record<string, any>) =>
                      acc + item.amountPaid,
                    0
                  ),
                  true
                )}
              </div>
            </div>
            <div className="flex justify-between">
              <Text>Balance</Text>
              <div className="font-semibold text-xl font-outfitbold text-[#DC2626]">
                {fetchEquity ? (
                  <>
                    {fetchEquity?.equities &&
                    fetchEquity?.equities[0]?.balance >= 0
                      ? formatInt(fetchEquity?.equities[0]?.balance, true)
                      : formatInt(
                          finalOffer?.additionalPayments?.find(
                            (item: any) =>
                              item?.name === "Total upfront payment"
                          )?.value,
                          true
                        )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {country === "NG" && (
            <div className="flex flex-col md:flex-row items-center md:divide-x rounded-lg md:rounded-xl bg-[#FAFAFA] p-3 md:p-4 w-full md:min-h-[78px] h-auto gap-2">
              <p className="text-base text-[#30345E] font-semibold pr-0 md:pr-4 whitespace-nowrap">
                Direct debit
              </p>
              <div className="flex items-center justify-between w-full pl-0 md:pl-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm text-[#30345E] font-outfit font-semibold">
                    Status
                  </p>
                  <p
                    className={`text-sm font-outfit font-semibold capitalize flex items-center w-fit h-7 px-4 rounded-[40px] ${handleActiveStatus(
                      userAccount?.status as string
                    )}`}
                  >
                    {userAccount?.status ?? "Inactive"}
                  </p>
                </div>

                {buttonStatusState()}
              </div>
            </div>
          )}
        </div>

        {isEquityEligible() && (
          <div className="px-4 lg:px-6 pt-6 pb-4">
            <EquityPaymentContainer>
              <Form
                name="document-upload-form"
                form={form}
                layout="vertical"
                requiredMark={false}
                onFinish={handleSubmitForm}
                autoComplete="off"
              >
                <Form.Item>
                  <Form.Item
                    name="document_upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: t("Please upload proof of payment"),
                      },
                    ]}
                  >
                    <Upload.Dragger {...props} name="file" maxCount={1}>
                      <div className="flex gap-4 items-center px-4">
                        <img
                          src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/Vector%20(Stroke).svg?updatedAt=1711051618638"
                          alt="Upload"
                        />
                        <div className="text-base font-semibold">
                          {t("Upload upfront proof")}
                        </div>
                      </div>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>

                <Form.Item label="Password (optional)" name="password">
                  <Input.Password placeholder="Enter password" />
                </Form.Item>

                <Form.Item
                  label="Amount on proof of payment"
                  name="amount"
                  rules={[{ required: true, message: "Please enter amount" }]}
                >
                  <EquityInputNumber>
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        `${value}`
                          .replace(/\D/g, "")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      keyboard={false}
                      placeholder={"Enter amount"}
                    />
                  </EquityInputNumber>
                </Form.Item>

                <Form.Item
                  name="paidTo"
                  label="Paid To"
                  rules={[
                    { required: true, message: "Please choose an option" },
                  ]}
                >
                  <Select
                    placeholder="Select an option"
                    loading={loadEquityConfig}
                    allowClear
                  >
                    {equityConfig?.map((item) => (
                      <Option key={item} value={item}>
                        {item?.replaceAll("_", " ")}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item>
                  <div className="button-container w-[50%] my-0 mx-auto">
                    <button
                      className="text-sm lg:text-base mt-6 w-full h-12 rounded-[60px] bg-[#ffb619] font-semibold text-[#30345e] px-4 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                      disabled={!!isAssumedUser}
                    >
                      {loading ? <Loader variant={"secondary"} /> : t("Submit")}
                    </button>
                  </div>
                </Form.Item>
              </Form>
            </EquityPaymentContainer>
          </div>
        )}
      </div>

      {country === "NG" && (
        <div className="w-full md:w-[75%] xl:w-[68%] h-auto bg-white my-0 mx-auto rounded-lg">
          <h3 className="px-2 lg:px-4 pt-6 font-outfit font-semibold pb-4 border-b border-solid border-[#E5E7EB] !mb-0">
            Upfront Payment Collection
          </h3>
          <Table
            dataSource={equityCollectionData ?? []}
            columns={equityCollectionColumns}
            loading={equityCollectionLoading}
            scroll={{ x: "max-content" }}
            pagination={{
              itemRender: itemRender(t1),
              simple: true,
              position: ["bottomCenter"],
            }}
          />
        </div>
      )}

      <div className="w-full md:w-[75%] xl:w-[68%] h-auto bg-white my-0 mx-auto rounded-lg">
        <h3 className="px-2 lg:px-4 pt-6 font-outfit font-semibold pb-4 border-b border-solid border-[#E5E7EB] !mb-0">
          Upfront Payment History
        </h3>
        <Table
          dataSource={equityPaymentsData ?? []}
          columns={equityPaymentsColumns}
          loading={fetchEquityLoading}
          scroll={{ x: "max-content" }}
          pagination={{
            current: activePage?.currentPage,
            pageSize: activePage?.pageSize,
            total: fetchEquity?.pagination?.total,
            onChange(page, pageSize) {
              setActivePage({
                ...activePage,
                currentPage: page,
              });
              paramsObjectToQueryString({
                current_page: page,
                page_size: pageSize,
              });
            },
            itemRender: itemRender(t1),
            simple: true,
            position: ["bottomCenter"],
          }}
        />
      </div>

      <EquityApprovalModal
        isModalOpen={openProceedModal}
        handleOk={() => setOpenProceedModal(false)}
        handleCancel={() => setOpenProceedModal(false)}
        loanId={loan_id}
        userId={user_id}
      />

      <DeactivateDebitModal
        isModalOpen={openDeactivateModal}
        handleOk={() => setOpenDeactivateModal(false)}
        handleCancel={() => setOpenDeactivateModal(false)}
        userId={user_id}
      />
    </main>
  );
}
