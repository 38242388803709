import { useState } from "react";
import { Button, Modal, message } from "antd";
import client from "@/lib/axios-client";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  cancelStyle,
  confirmStyle,
  paragraphStyle,
  titleStyle,
} from "./styles";
import { Loader } from "@/pages/auth/styles/loader";

interface IListModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  carId: any;
}

const ListCarModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  carId,
}: IListModal) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("modal");

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      id: carId,
    };

    try {
      const response = await client.post(
        "/v1/inventory/car/toggle_visibility",
        payload
      );
      if (response) {
        message.success(t("car-listed-successfully"));
        queryClient.invalidateQueries();
        setLoading(false);
        handleOk();
      }
    } catch (err: any) {
      const errorMessage = JSON.parse(err.response?.data);
      message.error(
        errorMessage?.message || errorMessage?.error || t("Error listing car")
      );
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title={<div style={titleStyle}>List Car</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={1007}
        footer={[
          <Button key={1} onClick={handleCancel} style={cancelStyle}>
            {t("cancel")}
          </Button>,
          <Button
            key={2}
            type="primary"
            onClick={handleSubmit}
            style={confirmStyle}
          >
            {loading ? <Loader variant={"secondary"} /> : t("confirm")}
          </Button>,
        ]}
        closeIcon={
          <img
            src="https://media.autochek.africa/file/publicAssets/x-close-u.svg"
            alt="close-icon"
            style={{ marginTop: 8 }}
          />
        }
      >
        <p style={paragraphStyle}>
          {t("you-are-about-to-show-this-car-on-the-marketplace")}.
        </p>
      </Modal>
    </div>
  );
};

export default ListCarModal;
