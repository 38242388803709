import React from "react";
import { Autocomplete, FormLabel, TextField } from "@mui/material";
import {
  FormDropdownStyle,
  InputFieldStyle,
  InputLabelStyle,
  SelectTextFieldStyle,
} from "@/layouts/application-layouts/styles";
import { Controller } from "react-hook-form";
import { IInsuranceFormProps } from "./types";
import { NumericFormat } from "react-number-format";
import { generateArrayOfYears } from "@/utils/yearsArray";
import { IOption, IOptions } from "@/interface/dealer";
import { getCountryLicensePlateLength } from "@/utils/getCountryTelCodeLength";
import storage from "@/lib/storage";

const NewCarSection: React.FC<IInsuranceFormProps> = ({ methods, t, data }) => {
  const country = storage.getCountry();
  const errors = { ...methods.formState.errors } as Record<string, any>;

  const yearsArray = generateArrayOfYears().map((year) => {
    return {
      label: year.toString(),
      value: year.toString(),
    };
  });

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-4">
      <div className="space-y-2 pt-2 pb-4">
        <FormLabel sx={InputLabelStyle}>Brand</FormLabel>
        <Controller
          name="make"
          control={methods.control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              fullWidth
              id="make"
              disablePortal
              options={data?.makes ?? []}
              onChange={(_, newValue) => {
                onChange(newValue?.value);
                methods.setValue("makeId", newValue?.id as string);
              }}
              getOptionLabel={(option: IOption) => option?.label || ""}
              isOptionEqualToValue={(option: IOption, value: IOption) =>
                option.value === value.value
              }
              value={
                data?.makes?.find(
                  (option: IOption) => option.value === value
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  placeholder={t("select-brand")}
                  sx={SelectTextFieldStyle}
                />
              )}
              sx={FormDropdownStyle}
            />
          )}
        />
        {errors?.make?.message && (
          <span className="text-red-600 text-sm">{errors?.make?.message}</span>
        )}
      </div>

      <div className="space-y-2 pt-2 pb-4">
        <FormLabel sx={InputLabelStyle}>Model</FormLabel>
        <Controller
          name="model"
          control={methods.control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              fullWidth
              id="model"
              disablePortal
              options={data?.models ?? []}
              getOptionLabel={(option: IOption) => option?.label || ""}
              isOptionEqualToValue={(option: IOption, value: IOption) =>
                option.value === value.value
              }
              onChange={(_, newValue) => {
                onChange(newValue?.value);
                methods.setValue("modelId", newValue?.id as string);
              }}
              value={
                data?.models?.find(
                  (option: IOption) => option.value === value
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  placeholder={t("select-model")}
                  sx={SelectTextFieldStyle}
                />
              )}
              sx={FormDropdownStyle}
            />
          )}
        />
        {errors?.model?.message && (
          <span className="text-red-600 text-sm">{errors?.model?.message}</span>
        )}
      </div>

      <div className="space-y-2 pt-2 pb-4">
        <FormLabel sx={InputLabelStyle}>Year</FormLabel>
        <Controller
          name="year"
          control={methods.control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              fullWidth
              id="year"
              disablePortal
              options={yearsArray ?? []}
              getOptionLabel={(option) => option?.label || ""}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_, newValue) => onChange(newValue?.value)}
              value={
                yearsArray.find((option) => option.value === value) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  placeholder={t("select-year")}
                  sx={SelectTextFieldStyle}
                />
              )}
              sx={FormDropdownStyle}
            />
          )}
        />
        {errors?.year?.message && (
          <span className="text-red-600 text-sm">{errors?.year?.message}</span>
        )}
      </div>

      <div className="space-y-2 pt-2 pb-4">
        <FormLabel sx={InputLabelStyle}>Vehicle type</FormLabel>
        <Controller
          name="vehicleType"
          control={methods.control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              fullWidth
              id="vehicleType"
              disablePortal
              options={data?.bodyTypes ?? []}
              getOptionLabel={(option) => option?.label || ""}
              isOptionEqualToValue={(option: IOption, value: IOption) =>
                option.value === value.value
              }
              onChange={(_, newValue) => onChange(newValue?.value)}
              value={
                data?.bodyTypes?.find(
                  (option: IOptions) => option.value === value
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  placeholder={t("Select vehicle type")}
                  sx={SelectTextFieldStyle}
                />
              )}
              sx={FormDropdownStyle}
            />
          )}
        />
        {errors?.vehicleType?.message && (
          <span className="text-red-600 text-sm">
            {errors?.vehicleType?.message}
          </span>
        )}
      </div>

      <div className="space-y-2 pt-2 pb-4">
        <FormLabel sx={InputLabelStyle}>Exterior color</FormLabel>
        <Controller
          name="exteriorColor"
          control={methods.control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              fullWidth
              id="exteriorColor"
              disablePortal
              options={data?.colors ?? []}
              getOptionLabel={(option) => option?.label || ""}
              isOptionEqualToValue={(option: IOption, value: IOption) =>
                option.value === value.value
              }
              onChange={(_, newValue) => onChange(newValue?.value)}
              value={
                data?.colors?.find(
                  (option: IOptions) => option.value === value
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  placeholder={t("Select exterior color")}
                  sx={SelectTextFieldStyle}
                />
              )}
              sx={FormDropdownStyle}
            />
          )}
        />
        {errors?.exteriorColor?.message && (
          <span className="text-red-600 text-sm">
            {errors?.exteriorColor?.message}
          </span>
        )}
      </div>

      <div className="space-y-2 pt-2 pb-4">
        <FormLabel sx={InputLabelStyle}>Car price</FormLabel>
        <Controller
          name="price"
          control={methods.control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumericFormat
              fullWidth
              placeholder={"Enter price"}
              name={"price"}
              error={!!error}
              aria-label={"car price"}
              thousandSeparator=","
              allowNegative={false}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              customInput={TextField}
              sx={InputFieldStyle}
            />
          )}
        />
        {errors?.price?.message && (
          <span className="text-red-600 text-sm">{errors?.price?.message}</span>
        )}
      </div>

      <div className="space-y-2 pt-2 pb-4">
        <FormLabel sx={InputLabelStyle}>License plate number</FormLabel>
        <Controller
          name="licensePlate"
          control={methods.control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              error={!!error}
              type={"text"}
              placeholder={t("Enter plate number")}
              onChange={(e) => {
                const uppercaseValue = e.target.value.toUpperCase();
                field.onChange(uppercaseValue);
              }}
              sx={InputFieldStyle}
              inputProps={{
                maxLength: getCountryLicensePlateLength(country),
              }}
            />
          )}
        />
        {errors?.licensePlate?.message && (
          <span className="text-red-600 text-sm">
            {errors?.licensePlate?.message}
          </span>
        )}
      </div>
    </div>
  );
};

export default NewCarSection;
