import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { checkErrors } from "@/utils/process-form-data";
import { useNavigate } from "react-router-dom";

interface IFormButton {
  loading: boolean;
  data: any;
  fields: any;
  index: number;
  setAlert: (error: string) => void;
  setCompleted: React.Dispatch<React.SetStateAction<any>>;
  setExpanded: (index: number) => void;
  loanId?: string;
  label: string;
  submit: any;
}

export default function SectionsActionButton({
  loading,
  data,
  fields,
  index,
  setAlert,
  setCompleted,
  setExpanded,
  loanId,
  label,
  submit,
}: Readonly<IFormButton>) {
  const navigate = useNavigate();

  const handleSectionNextAction = async (
    formData: any,
    fields: any,
    index: number,
    setAlert: (error: string) => void,
    setCompleted: React.Dispatch<React.SetStateAction<any>>,
    setExpanded: (index: number) => void,
    e: any
  ) => {
    const error = checkErrors(formData, fields);
    if (error) {
      setAlert(error);
      setCompleted((current: any) => ({
        ...current,
        [index]: false,
      }));
      return;
    }
    !loanId && setExpanded(index + 1);
    setCompleted((current: any) => ({
      ...current,
      [index]: true,
    }));

    submit(e);

    if (loanId) {
      navigate(`/dealer/applications/loans/${loanId}`);
      await submit(e);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      type="button"
      fullWidth
      color="custom"
      style={{ fontWeight: 600 }}
      loading={loading}
      onClick={(e) => {
        handleSectionNextAction(
          data,
          fields,
          index,
          setAlert,
          setCompleted,
          setExpanded,
          e
        );
      }}
    >
      {label}
    </LoadingButton>
  );
}
