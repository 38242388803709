import { z } from "zod";

interface IInventoryCars {
  make: string;
  model: string;
  year: number;
  exteriorColor: string;
  bodyType: string;
  usageType: string;
  estimatedCarPrice: number;
}

export interface IDealerData {
  name: string;
  phoneNumber: string;
  email: string;
}

export interface QuoteFormValues {
  selectedCarId: string;
  insuranceType: string;
  usageType: string;
  paymentOption: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  whatsappNumber: string;
  make: string;
  model: string;
  year: string;
  price: string;
  exteriorColor: string;
  licensePlate: string;
  vehicleType: string;
  searchCar: string;
  inventoryCars: IInventoryCars;
  makeId: string;
  modelId: string;
  carId: string;
  duration: string;
  carLicense: string;
  dealerName: string;
  dealerId: string;
  dealerEmail: string;
  searchDealer: string;
  dealersData: IDealerData;
  customerId: string;
  vehicleId: string;
}

export interface QuoteFormDetailValues {
  dateOfBirth: string;
  idType: string;
  idNumber: string;
  kraPin: string;
  gender: string;
  vin: string;
  engineNumber: string;
  hasModification: string;
  address: string;
  states: string;
  stateId: string;
  cities: string;
  valuationLocation: string;
}

export interface DebitMandateValues {
  supportedBank: string;
  accountNumber: string;
}

export const createQuoteDefaultValues: QuoteFormValues = {
  selectedCarId: "",
  insuranceType: "",
  usageType: "",
  paymentOption: "",
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
  whatsappNumber: "",
  make: "",
  model: "",
  year: "",
  price: "",
  exteriorColor: "",
  licensePlate: "",
  vehicleType: "",
  searchCar: "",
  inventoryCars: {
    make: "",
    model: "",
    year: 0,
    exteriorColor: "",
    bodyType: "",
    usageType: "",
    estimatedCarPrice: 0,
  },
  makeId: "",
  modelId: "",
  carId: "",
  duration: "",
  carLicense: "",
  dealerName: "",
  dealerId: "",
  dealerEmail: "",
  searchDealer: "",
  dealersData: {
    name: "",
    phoneNumber: "",
    email: "",
  },
  customerId: "",
  vehicleId: "",
};

export const quoteFormDetailDefaultValues: QuoteFormDetailValues = {
  dateOfBirth: "",
  idType: "",
  idNumber: "",
  kraPin: "",
  gender: "",
  vin: "",
  engineNumber: "",
  hasModification: "",
  address: "",
  states: "",
  cities: "",
  valuationLocation: "",
  stateId: "",
};

export const debitMandateDefaultValues: DebitMandateValues = {
  supportedBank: "",
  accountNumber: "",
};

export const quoteFormSchema = z.object({
  selectedCarId: z
    .string({
      required_error: "Search and select a car",
    })
    .min(1, "Search and enter a valid car"),

  emailAddress: z
    .string({
      required_error: "Enter the email address",
    })
    .min(1, "Enter a valid email address"),

  firstName: z
    .string({
      required_error: "Enter the first name",
    })
    .min(1, "Enter a valid first name"),

  insuranceType: z
    .string({
      required_error: "Enter the insurance type",
    })
    .min(1, "Enter a valid insurance type"),

  lastName: z
    .string({
      required_error: "Enter the last name",
    })
    .min(1, "Enter a valid last name"),

  paymentOption: z
    .string({
      required_error: "Enter the payment option",
    })
    .min(1, "Enter a valid payment option"),

  phoneNumber: z
    .string({
      required_error: "Enter the phone number",
    })
    .min(10, "Enter a valid phone number")
    .regex(/^[7-9]\d{9}$/, "Enter a valid phone number"),

  usageType: z
    .string({
      required_error: "Enter the usage type",
    })
    .min(1, "Enter a valid usage type"),

  duration: z
    .string({
      required_error: "Enter the duration",
    })
    .min(1, "Enter a valid duration"),

  whatsappNumber: z
    .string({
      required_error: "Enter the whatsapp number",
    })
    .min(10, "Enter a valid whatsapp number")
    .regex(/^[7-9]\d{9}$/, "Enter a valid whatsapp number"),

  carLicense: z
    .string({
      required_error: "Enter the license plate",
    })
    .min(1, "Enter a valid license plate"),
  dealerName: z.string().optional(),
});

export const quoteFormSchema1 = z.object({
  duration: z
    .string({
      required_error: "Enter the duration",
    })
    .min(1, "Enter a valid duration"),

  make: z
    .string({
      required_error: "Enter the make",
    })
    .min(1, "Enter a valid make"),

  makeId: z.number().optional(),

  model: z
    .string({
      required_error: "Enter the model",
    })
    .min(1, "Enter a valid model"),

  modelId: z.string().optional(),

  emailAddress: z
    .string({
      required_error: "Enter the email address",
    })
    .min(1, "Enter a valid email address"),

  exteriorColor: z
    .string({
      required_error: "Enter the exterior color",
    })
    .min(1, "Enter a valid exterior color"),

  firstName: z
    .string({
      required_error: "Enter the first name",
    })
    .min(1, "Enter a valid first name"),

  insuranceType: z
    .string({
      required_error: "Enter the insurance type",
    })
    .min(1, "Enter a valid insurance type"),

  lastName: z
    .string({
      required_error: "Enter the last name",
    })
    .min(1, "Enter a valid last name"),

  licensePlate: z
    .string({
      required_error: "Enter the license plate",
    })
    .min(1, "Enter a valid license plate"),

  paymentOption: z
    .string({
      required_error: "Enter the payment option",
    })
    .min(1, "Enter a valid payment option"),

  phoneNumber: z
    .string({
      required_error: "Enter the phone number",
    })
    .min(10, "Enter a valid phone number")
    .regex(/^[7-9]\d{9}$/, "Enter a valid phone number"),

  price: z
    .string({
      required_error: "Enter the price",
    })
    .min(1, "Enter a valid price"),

  usageType: z
    .string({
      required_error: "Enter the usage type",
    })
    .min(1, "Enter a valid usage type"),

  vehicleType: z
    .string({
      required_error: "Enter the vehicle type",
    })
    .min(1, "Enter a valid vehicle type"),

  whatsappNumber: z
    .string({
      required_error: "Enter the whatsapp number",
    })
    .min(10, "Enter a valid whatsapp number")
    .regex(/^[7-9]\d{9}$/, "Enter a valid whatsapp number"),

  year: z
    .string({
      required_error: "Enter the year",
    })
    .min(1, "Enter a valid year"),
});

export const createQuoteForm = z.object({
  selectedCarId: z
    .string({
      required_error: "Search and select a car",
    })
    .min(1, "Search and enter a valid car"),

  emailAddress: z
    .string({
      required_error: "Enter the email address",
    })
    .min(1, "Enter a valid email address"),

  firstName: z
    .string({
      required_error: "Enter the first name",
    })
    .min(1, "Enter a valid first name"),

  insuranceType: z
    .string({
      required_error: "Enter the insurance type",
    })
    .min(1, "Enter a valid insurance type"),

  lastName: z
    .string({
      required_error: "Enter the last name",
    })
    .min(1, "Enter a valid last name"),

  paymentOption: z
    .string({
      required_error: "Enter the payment option",
    })
    .min(1, "Enter a valid payment option"),

  phoneNumber: z
    .string({
      required_error: "Enter the phone number",
    })
    .min(10, "Enter a valid phone number")
    .regex(/^[7-9]\d{9}$/, "Enter a valid phone number"),

  usageType: z
    .string({
      required_error: "Enter the usage type",
    })
    .min(1, "Enter a valid usage type"),

  whatsappNumber: z
    .string({
      required_error: "Enter the whatsapp number",
    })
    .min(10, "Enter a valid whatsapp number")
    .regex(/^[7-9]\d{9}$/, "Enter a valid whatsapp number"),

  duration: z
    .string({
      required_error: "Enter the duration",
    })
    .min(1, "Enter a valid duration"),

  make: z
    .string({
      required_error: "Enter the make",
    })
    .min(1, "Enter a valid make"),

  makeId: z.number().optional(),

  model: z
    .string({
      required_error: "Enter the model",
    })
    .min(1, "Enter a valid model"),

  modelId: z.string().optional(),

  exteriorColor: z
    .string({
      required_error: "Enter the exterior color",
    })
    .min(1, "Enter a valid exterior color"),

  licensePlate: z
    .string({
      required_error: "Enter the license plate",
    })
    .min(1, "Enter a valid license plate")
    .regex(
      /^[A-Z]{3}\d{3}[A-Z]{2}$/,
      "Enter a valid license plate (e.g., ABC123DE)"
    ),

  year: z
    .string({
      required_error: "Enter the year",
    })
    .min(1, "Enter a valid year"),

  price: z
    .string({
      required_error: "Enter the price",
    })
    .min(1, "Enter a valid price"),

  carLicense: z
    .string({
      required_error: "Enter the license plate",
    })
    .min(1, "Enter a valid license plate")
    .regex(
      /^[A-Z]{3}\d{3}[A-Z]{2}$/,
      "Enter a valid license plate (e.g., ABC123DE)"
    ),
});

export const quoteDetailFormSchema = z.object({
  date: z
    .string({
      required_error: "Select a date",
    })
    .min(1, "Select a valid date"),

  idType: z
    .string({
      required_error: "Enter the ID type",
    })
    .min(1, "Enter a valid ID type"),

  idNumber: z
    .string({
      required_error: "Enter the ID number",
    })
    .min(1, "Enter a valid ID number"),

  kraPin: z
    .string({
      required_error: "Enter the KRA pin",
    })
    .min(1, "Enter a valid KRA pin"),

  gender: z
    .string({
      required_error: "Enter the gender",
    })
    .min(1, "Enter a valid gender"),

  vin: z
    .string({
      required_error: "Enter the VIN",
    })
    .min(1, "Enter a valid VIN"),

  engineNumber: z
    .string({
      required_error: "Enter the engine number",
    })
    .min(1, "Enter a valid engine number"),
});

export const additionalDetailSchemaKE = z.object({
  dateOfBirth: z
    .string({
      required_error: "Select a date",
    })
    .min(1, "Select a valid date"),

  idType: z
    .string({
      required_error: "Enter the ID type",
    })
    .min(1, "Enter a valid ID type"),

  idNumber: z
    .string({
      required_error: "Enter the ID number",
    })
    .min(1, "Enter a valid ID number"),

  kraPin: z
    .string({
      required_error: "Enter the KRA pin",
    })
    .min(1, "Enter a valid KRA pin"),

  gender: z
    .string({
      required_error: "Select the gender",
    })
    .min(1, "Select a valid gender"),

  vin: z
    .string({
      required_error: "Enter the VIN",
    })
    .min(1, "Enter a valid VIN")
    .regex(/^[A-Za-z0-9]{15,}$/, "Enter a valid VIN"),

  engineNumber: z
    .string({
      required_error: "Enter the engine number",
    })
    .min(1, "Enter a valid engine number"),

  address: z
    .string({
      required_error: "Enter the address",
    })
    .min(1, "Enter a valid address"),

  states: z
    .string({
      required_error: "Enter the state",
    })
    .min(1, "Enter a valid state"),

  cities: z
    .string({
      required_error: "Enter the city",
    })
    .min(1, "Enter a valid city"),

  valuationLocation: z
    .string({
      required_error: "Enter the valuation location",
    })
    .min(1, "Enter a valid valuation location"),

  hasModification: z
    .string({
      required_error: "Select the modification",
    })
    .min(1, "Enter a valid modification"),
});

export const additionalDetailSchema = z.object({
  dateOfBirth: z
    .string({
      required_error: "Select a date",
    })
    .min(1, "Select a valid date"),

  idType: z
    .string({
      required_error: "Enter the ID type",
    })
    .min(1, "Enter a valid ID type"),

  idNumber: z
    .string({
      required_error: "Enter the ID number",
    })
    .min(1, "Enter a valid ID number"),

  gender: z
    .string({
      required_error: "Select the gender",
    })
    .min(1, "Select a valid gender"),

  vin: z
    .string({
      required_error: "Enter the VIN",
    })
    .min(1, "Enter a valid VIN")
    .regex(/^[A-Za-z0-9]{15,}$/, "Enter a valid VIN"),

  address: z
    .string({
      required_error: "Enter the address",
    })
    .min(1, "Enter a valid address"),

  states: z
    .string({
      required_error: "Enter the state",
    })
    .min(1, "Enter a valid state"),

  cities: z
    .string({
      required_error: "Enter the city",
    })
    .min(1, "Enter a valid city"),

  hasModification: z
    .string({
      required_error: "Select the modification",
    })
    .min(1, "Enter a valid modification"),
});

export const debitMandateSchema = z.object({
  supportedBank: z
    .string({
      required_error: "Enter the supported bank",
    })
    .min(1, "Enter a valid bank"),

  accountNumber: z
    .string({
      required_error: "Enter the account number",
    })
    .min(1, "Enter a valid account number"),
});
