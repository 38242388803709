import React from "react";
import { useLocation } from "react-router-dom";

interface LoanButtonProps {
  onHandleSubmit?: () => void;
}

const LoanApplyButton: React.FC<LoanButtonProps> = ({
  onHandleSubmit = () => {},
}) => {
  const location = useLocation();

  return (
    <button
      onClick={onHandleSubmit}
      className="text-sm md:text-base text-[#30345E] font-semibold w-fit md:w-full px-5 xl:px-4 h-11 md:h-12 bg-[#FFB619] rounded-full"
    >
      <span className="hidden xl:block">
        {location.pathname === "/dealer/leads"
          ? "Start Loan"
          : "Start Loan Application"}
      </span>{" "}
      <span className="block xl:hidden whitespace-nowrap">Apply Loan</span>
    </button>
  );
};

export default LoanApplyButton;
