import DownloadPolicy from "@/components/insurance/DownloadPolicy";
import ShareNotifs from "@/components/insurance/SendNotification";
import { IPolicyData } from "@/interface/policies";
import formatInt from "@/utils/format-int";
import moment from "moment";
import tw from "tailwind-styled-components";

type TInsurancePolicy = {
  policies: IPolicyData;
};

const Title = tw.p`text-xs md:text-sm text-[#6B7280] font-medium`;
const Text = tw.p`text-sm md:text-base text-[#202020] font-semibold capitalize`;

const renderEmptyCard = () => (
  <div className="w-full my-0 mx-auto pt-10 pb-3">
    <div className="flex flex-col items-center w-full">
      <img
        src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
        alt="caution"
      />
      <p className="text-sm lg:text-base text-[#6b7280]">
        No insurance policies available.
      </p>
    </div>
  </div>
);

const InsurancePolicy: React.FC<TInsurancePolicy> = ({ policies }) => {
  const policyStatus = (status: string) => {
    if (status === "PENDING") {
      return "bg-[#FFED8E]";
    } else if (status === "ACTIVE") {
      return "bg-[#86EFAC]";
    } else if (status === "EXPIRED") {
      return "bg-[#FECACA]";
    }
  };

  return (
    <section className="mt-4 flex flex-col gap-2">
      {policies?.data?.length > 0 ? (
        <div className="flex flex-col gap-4 bg-white py-4 md:py-0">
          {policies?.data?.map((item) => (
            <div
              key={item?.id}
              className="flex flex-col gap-2 border border-solid border-[#E5E7EB] rounded-lg px-3 pt-3 pb-5"
            >
              <div className="flex items-center justify-between">
                <h2 className="text-base md:text-xl font-semibold text-[#202020] capitalize">
                  {item?.providerName ?? "---"}
                </h2>
                <p
                  className={`text-xs md:text-sm text-[#30345E] font-semibold h-6 px-2 ${policyStatus(
                    item?.status
                  )} w-fit flex items-center justify-center rounded-[4px]`}
                >
                  {item?.status}
                </p>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-3">
                <div>
                  <Title>Policy no</Title>
                  <Text>{item?.policyNumber ?? "---"}</Text>
                </div>
                <div>
                  <Title>Policy type</Title>
                  <Text>
                    {item?.coverageType?.replaceAll("_", " ") ?? "---"}
                  </Text>
                </div>
                <div>
                  <Title>Policy value</Title>
                  <Text>{formatInt(Number(item?.coverageAmount), true)}</Text>
                </div>
                <div>
                  <Title>Premium value</Title>
                  <Text>{formatInt(Number(item?.premiumAmount), true)}</Text>
                </div>
                <div>
                  <Title>Issue date</Title>
                  <Text>
                    {item?.startDate
                      ? moment(item?.startDate).format("YYYY-MM-DD")
                      : "---"}
                  </Text>
                </div>
                <div>
                  <Title>Expiry date</Title>
                  <Text>
                    {item?.endDate
                      ? moment(item?.endDate).format("YYYY-MM-DD")
                      : "---"}
                  </Text>
                </div>
                <div>
                  <Title>Car Name</Title>
                  <Text>{item?.vehicle?.name ?? "---"}</Text>
                </div>
                <div>
                  <Title>Car Price</Title>
                  <Text>
                    {formatInt(
                      Number(item?.vehicle?.valuationCarPrice),
                      true
                    ) ?? "---"}
                  </Text>
                </div>
                <div>
                  <Title>License Plate</Title>
                  <Text>{item?.vehicle?.licensePlateNumber ?? "---"}</Text>
                </div>
              </div>
              <div className="flex items-center gap-3 mt-4">
                <ShareNotifs item={item} type="whatsapp" />
                <ShareNotifs item={item} type="mail" />
                <DownloadPolicy item={item} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        renderEmptyCard()
      )}
    </section>
  );
};

export default InsurancePolicy;
