import { DownloadIcon } from "@/assets/svgs";
import { Policy } from "@/interface/policies";

interface DownloadPolicyProps {
  item: Policy;
}

const DownloadPolicy: React.FC<DownloadPolicyProps> = ({ item }) => {
  return (
    <button
      onClick={() => {
        const doc = item?.documents.find((item) =>
          item.providerDocumentUrl.includes("_Certificate")
        );
        window.open(doc?.providerDocumentUrl, "_blank", "noopener noreferrer");
      }}
      className="flex items-center justify-center gap-2 text-sm md:text-base text-[#30345E] font-semibold font-outfit bg-[#FFB619] w-full max-w-full h-10 md:h-12 rounded-full"
    >
      <span className="hidden lg:block">
        <DownloadIcon />
      </span>{" "}
      Download <span className="hidden lg:block">Policy</span>
    </button>
  );
};

export default DownloadPolicy;
