import novaClient from "@/lib/nova-client";

export const postInitiatePayment = (data: any) => {
  return novaClient.post("/payments/initiate", data);
};

export const postVerifyPayment = (data: any) => {
  return novaClient.post("/payments/verify", data);
};

export const createQuotation = (data: any) => {
  return novaClient.post(`/quotations`, data);
};

export const updateQuotation = (id: string, data: any) => {
  return novaClient.patch(`/quotations/${id}`, data);
};

export const initiateDirectDebit = (data: any) => {
  return novaClient.post(`/payments/direct-debit/authorize`, data);
};

export const fetchProducts = () => {
  return novaClient.get(`/products`);
};

export const fetchProductsById = (id: string) => {
  return novaClient.get(`/products/${id}`);
};

export const fetchPolicies = (params: URLSearchParams) => {
  return novaClient.get(`/policies`, { params });
};

export const fetchQuotations = (params: URLSearchParams) => {
  return novaClient.get(`/quotations`, { params });
};

export const fetchPoliciesById = (id: string) => {
  return novaClient.get(`/policies/${id}`);
};

export const fetchInsuranceStats = (params: URLSearchParams) => {
  return novaClient.get(`/stats/insurance`, { params });
};

export const fetchDirectDebitStatus = (params: URLSearchParams) => {
  return novaClient.get(`/payments/direct-debit/status`, { params });
};

export const postShareViaEmail = (id: string, data: any) => {
  return novaClient.post(`notifications/policies/${id}/email`, data);
};

export const postShareViaWhatsapp = (id: string, data: any) => {
  return novaClient.post(`notifications/policies/${id}/whatsapp`, data);
};
