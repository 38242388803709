import storage from "@/lib/storage";
import { useNavigate } from "react-router-dom";

interface IProfileProps {
  setOpenProfileModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileUpdateContent: React.FC<IProfileProps> = ({
  setOpenProfileModal,
}) => {
  const navigate = useNavigate();
  const isAccountManager = storage.utilities.isAccountManager();

  const handleToPath = () => {
    const path = isAccountManager ? `/manage/dealers` : `/settings`;
    navigate(path);
  };

  return (
    <div>
      <p className="text-sm md:text-base text-[#30345e] font-semibold mb-3 mt-3">
        Buyers can now contact {isAccountManager ? "your dealers" : "you"}{" "}
        directly!
      </p>
      <p className="text-sm md:text-base text-[#30345e] font-medium mb-3">
        To make the most of this new feature, ensure your profile is complete
        and up-to-date. Here&apos;s why it matters:
      </p>
      <ul className="flex flex-col gap-2 list-disc ml-4">
        <li className="text-sm md:text-base text-[#30345e]">
          <b>Boost Your Sales:</b> Buyers are more likely to reach out when they
          see complete and reliable information.
        </li>
        <li className="text-sm md:text-base text-[#30345e]">
          <b>Stand Out in Searches:</b> Sellers with updated profiles gain more
          visibility.
        </li>
        <li className="text-sm md:text-base text-[#30345e]">
          <b>Save Time:</b> Provide accurate contact details to avoid missed
          opportunities.
        </li>
      </ul>
      <div className="mt-8 flex flex-row justify-center gap-2 md:gap-3">
        {isAccountManager && (
          <button
            onClick={() => setOpenProfileModal?.(false)}
            className="text-sm md:text-base text-[#30345e] border border-solid border-[#30345e] font-semibold bg-white w-auto whitespace-nowrap h-12 px-6 rounded-full"
          >
            Update later
          </button>
        )}
        <button
          onClick={handleToPath}
          className="text-sm md:text-base text-[#30345e] font-semibold bg-[#FFB619] w-full max-w-full h-12 px-6 rounded-full"
        >
          {isAccountManager
            ? "Update your dealer's profile"
            : "Update your Profile"}
        </button>
      </div>
    </div>
  );
};

export default ProfileUpdateContent;
