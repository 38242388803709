import type { PaginationProps } from "antd";

export const itemRender = (t: (key: string) => string) => {
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <a href="#link">{t("previous")}</a>;
    }
    if (type === "next") {
      return <a href="#link">{t("next")}</a>;
    }
    return originalElement;
  };

  return itemRender;
};
