import React, { useState } from "react";
import { Button, Modal, Select, Input, InputNumber, Form, message } from "antd";
import styled from "styled-components";
import tw from "tailwind-styled-components";
import client from "@/lib/axios-client";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import formatCurrency from "@u/formatCurrency";

interface IListModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  carId: any;
}

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const confirmStyle = {
  background: "#FFB619",
  borderRadius: 60,
  color: "#30345E",
  fontWeight: 600,
  border: 0,
  height: 48,
  minWidth: 101,
};

const cancelStyle = {
  background: "#fff",
  borderRadius: 60,
  color: "#30345E",
  fontWeight: 600,
  border: "1px solid #30345E",
  height: 48,
  minWidth: 101,
  marginRight: 12,
};

const paragraphStyle = {
  fontSize: 15,
  fontWeight: 500,
  marginBottom: 24,
};

const titleStyle = {
  fontSize: 22,
  fontWeight: 600,
  color: "#30345E",
};

const FormStyle = styled.div`
  label {
    font-weight: 600;
  }
  .ant-select {
    height: 48px;
    width: 100%;
    .ant-select-selector {
      height: 48px;
      border-radius: 4px;
      background: #f8fafc;
      align-items: center;
      .ant-select-selection-search-input {
        padding-top: 14px;
      }
    }
  }
  .ant-input-number .ant-input-number-input {
    width: 100%;
    height: 48px;
    border-radius: 4px;
  }
  .ant-input {
    width: 100%;
    height: 48px;
    border-radius: 4px;
  }
`;

const DelistCarModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  carId,
}: IListModal) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [delistReason, setDelistReason] = useState("");
  const { t } = useTranslation("modal");

  const handleSelectChange = (value: string) => {
    setDelistReason(value);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const { delistReason, otherReason, priceSold } = values;
    const payload = {
      id: carId,
      delist_reason: otherReason || delistReason,
      price: priceSold,
    };
    if (payload.price === "" || payload.price === undefined)
      delete payload.price;

    try {
      const response = await client.post(
        "/v1/inventory/car/toggle_visibility",
        payload
      );
      if (response) {
        message.success(t("car-de-listed-successfully"));
        queryClient.invalidateQueries();
        setLoading(false);
        handleOk();
        form.resetFields();
      }
    } catch (err: any) {
      const errorMessage = JSON.parse(err.response?.data);
      message.error(
        errorMessage?.message ||
          errorMessage?.error ||
          t("error-de-listing-car")
      );
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title={<div style={titleStyle}>{t("delist-car")}</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closeIcon={
          <img
            src="https://media.autochek.africa/file/publicAssets/x-close-u.svg"
            alt="close-icon"
            style={{ marginTop: 8 }}
          />
        }
      >
        <p style={paragraphStyle}>
          {t("you-are-about-to-remove-this-car-from-the-marketplace")}.
        </p>
        <FormStyle>
          <Form
            name="basic"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={handleSubmit}
          >
            <Form.Item
              label={t("car-delist-reason")}
              name="delistReason"
              rules={[{ required: true, message: t("please-add-a-reason") }]}
            >
              <Select
                onChange={handleSelectChange}
                options={[
                  { value: "Sold", label: t("sold") },
                  {
                    value: "Sold outside Autochek",
                    label: t("sold-outside-autochek"),
                  },
                  { value: "Out of stock", label: t("out-of-stock") },
                  { value: "Not available", label: t("not-available") },
                  { value: "Others", label: t("others") },
                ]}
              />
            </Form.Item>
            {delistReason === "Sold" ||
            delistReason === "Sold outside Autochek" ? (
              <Form.Item
                label={t("price-sold")}
                name="priceSold"
                rules={[
                  { required: true, message: t("please-add-price-sold") },
                ]}
              >
                <InputNumber
                  formatter={(value: any) => {
                    return formatCurrency(value);
                  }}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            ) : (
              ""
            )}
            {delistReason === "Others" && (
              <Form.Item
                label={t("other-reason")}
                name="otherReason"
                rules={[
                  { required: true, message: t("please-add-other-reasons") },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            )}
            <Form.Item style={{ display: "flex", justifyContent: "end" }}>
              <Button onClick={handleCancel} style={cancelStyle}>
                {t("cancel")}
              </Button>
              <Button type="primary" htmlType="submit" style={confirmStyle}>
                {loading ? <Loader variant={"secondary"} /> : t("confirm")}
              </Button>
            </Form.Item>
          </Form>
        </FormStyle>
      </Modal>
    </div>
  );
};

export default DelistCarModal;
