import React from "react";

type SalesCompletedButtonProps = {
  onHandleSubmit?: () => void;
};

const SalesCompletedButton: React.FC<SalesCompletedButtonProps> = ({
  onHandleSubmit,
}) => {
  return (
    <button
      onClick={onHandleSubmit}
      className="text-sm md:text-base text-[#30345E] border border-solid border-[#30345E] font-semibold w-full px-4 h-11 md:h-12 bg-white rounded-full"
    >
      Sale Completed
    </button>
  );
};

export default SalesCompletedButton;
