import { Vehicle, Customer } from "./quotations";

export interface IPolicyData {
  data: Policy[];
  pagination: Pagination;
}

export interface Policy {
  id: string;
  startDate: string;
  endDate: string;
  status: string;
  providerName: string;
  policyNumber: string;
  externalPolicyId: string;
  coverageType: string;
  coverageAmount: string;
  coverageDetails: string;
  premiumAmount: string;
  currency: string;
  country: string;
  createdAt: string;
  updatedAt: string;
  quotation: Quotation;
  documents: Document[];
  vehicle: Vehicle;
  customer: Customer;
}

interface Quotation {
  id: string;
  quoteDate: string;
  amountDue: string;
  subsequentMonthlyAmount: string;
  commissionAmount: string;
  currency: string;
  paymentPlan: string;
  instalmentCount: number;
  coverDuration: number;
  status: string;
  country: string;
  source: string;
  sourceData: SourceData;
  referrer: string;
  referrerData: ReferrerData;
  createdAt: string;
  updatedAt: string;
}

interface SourceData {
  name: string;
  sourceId: string;
  email: string;
}

interface ReferrerData {
  name: string;
  referrerId: string;
  email: string;
}

interface Document {
  id: string;
  providerDocumentUrl: string;
  policyNotes: string;
  country: string;
  createdAt: string;
  updatedAt: string;
}

interface Pagination {
  total: number;
  currentPage: number;
  pageSize: number;
  pageCount: number;
}

export type TParentTabs = "policies" | "quotations";

export interface IPaymentRequest {
  provider: string;
  status: string;
  reference: string;
  remarks: string;
  amount: number;
  paymentLink: string;
  metadata: PaymentMetadata;
  request: PaymentRequestDetails;
}

interface PaymentMetadata {
  status: boolean;
  message: string;
  data: PaymentData;
}

interface PaymentData {
  id: number;
  integration: number;
  domain: string;
  amount: number;
  currency: string;
  has_invoice: boolean;
  description: string;
  line_items: any[];
  tax: any[];
  request_code: string;
  status: string;
  paid: boolean;
  metadata: PaymentDataMetadata;
  notifications: any[];
  offline_reference: string;
  customer: number;
  created_at: string;
}

interface PaymentDataMetadata {
  quotationId: string;
  paymentScheduleId: string;
  paymentScheduleDetailId: string;
}

interface PaymentRequestDetails {
  customerCode: string;
  amount: number;
  currency: string;
  description: string;
  metadata: PaymentDataMetadata;
}

export enum PAYMENT_PLAN {
  ONE_OFF = "ONE_OFF",
  MONTHLY = "MONTHLY",
}

interface PaymentStatus {
  total: number;
  expectedValue: number;
}

interface PaymentStatus {
  total: number;
  expectedValue: number;
}

interface ActivePaymentStatus {
  total: number;
  insuranceValue: number;
}

interface ExpiredPaymentStatus {
  total: number;
  expiredValue: number;
}

interface PremiumSoldStatus {
  total: number;
  soldValue: number;
}

export interface IPaymentSummary {
  pendingPayment: PaymentStatus;
  active: ActivePaymentStatus;
  expired: ExpiredPaymentStatus;
  premiumSold: PremiumSoldStatus;
}

export interface IPlacesValues {
  label: string;
  value: any;
}
