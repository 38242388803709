import { Link, useLocation, useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import storage from "../../../src/lib/storage";
import { Dropdown } from "antd";
import { NewLoansButton } from "./styles";
import { useTranslation } from "react-i18next";

const NewLoansBtn = () => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const location = useLocation();
  const country = storage.getCountry();
  const isFranchise = storage.utilities.isFranchise();
  const isDealerDsa = storage.utilities.isDealerDsa();
  const isAccountManager = storage.utilities.isAccountManager();
  const isAssumedUser = storage.getAssumedUser();
  const isDealerFinanceManager = storage.utilities.isDealerFinanceManager();
  const currentPath = location.pathname;

  const franchiseMenuItems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Link
          to="/applications/select-cars"
          style={{ color: "#30345e", fontWeight: "600" }}
        >
          {t("loan-application")}
        </Link>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <Link
          to="/applications/create-car-for-cash?form_type=new"
          style={{ color: "#30345e", fontWeight: "600" }}
        >
          {t("cash-loan")}
        </Link>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "3",
      label: (
        <Link
          to="/inventory/create-car"
          style={{ color: "#30345e", fontWeight: "600" }}
        >
          {t("car")}
        </Link>
      ),
    },
    {
      type: "divider",
    },
    ...(["NG", "KE"].includes(country)
      ? [
          {
            key: "4",
            label: (
              <Link
                to="/applications/select-cars?variant=loan-transfer"
                style={{ color: "#30345e", fontWeight: "600" }}
              >
                {t("Dealer Whitelabel")}
              </Link>
            ),
          },
        ]
      : []),
    {
      type: "divider",
    },
    {
      key: "5",
      label: (
        <Link
          to="/insurance/car"
          style={{ color: "#30345e", fontWeight: "600" }}
        >
          {t("Insurance")}
        </Link>
      ),
    },
  ];

  const mainManuItems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Link
          to="/applications/select-cars"
          style={{ color: "#30345e", fontWeight: "600" }}
        >
          {t("loan-application")}
        </Link>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <Link
          to="/applications/create-car-for-cash?form_type=new"
          style={{ color: "#30345e", fontWeight: "600" }}
        >
          {t("cash-loan")}
        </Link>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "3",
      label: (
        <Link
          to="/inventory/create-car"
          style={{ color: "#30345e", fontWeight: "600" }}
        >
          {t("car")}
        </Link>
      ),
    },
  ];

  const otherMenuItems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Link
          to="/applications/select-cars"
          style={{ color: "#30345e", fontWeight: "600" }}
        >
          {t("loan-application")}
        </Link>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <Link
          to="/inventory/create-car"
          style={{ color: "#30345e", fontWeight: "600" }}
        >
          {t("car")}
        </Link>
      ),
    },
  ];

  const dsaMenuItems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Link
          to="/applications/select-cars"
          style={{ color: "#30345e", fontWeight: "600" }}
        >
          {t("loan-application")}
        </Link>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <Link
          to="/applications/create-car-for-cash?form_type=new"
          style={{ color: "#30345e", fontWeight: "600" }}
        >
          {t("cash-loan")}
        </Link>
      ),
    },
  ];

  const getItems = () => {
    if (
      ["NG", "GH", "KE"]?.includes(country) &&
      (isFranchise || isAccountManager)
    ) {
      return franchiseMenuItems;
    } else if (["NG", "GH", "KE"]?.includes(country) && isDealerDsa) {
      return mainManuItems;
    } else if (
      !["NG", "GH", "KE"]?.includes(country) &&
      (isFranchise || isDealerDsa || isAccountManager)
    ) {
      return otherMenuItems;
    } else {
      return dsaMenuItems;
    }
  };

  const items = getItems();

  const renderButtonText = (path: string) => {
    const mappings: Record<string, { url: string; label: string }> = {
      "/insurance": { url: "/insurance/car", label: "Create Quote" },
      "/inventory": { url: "/inventory/create-car", label: "Create Car" },
    };
    return (
      Object.entries(mappings).find(([key]) => path.includes(key))?.[1] || null
    );
  };

  const renderDropdownButton = (isDisabled: boolean) => (
    <Dropdown
      menu={{ items }}
      placement="topRight"
      trigger={["click"]}
      overlayStyle={{ width: "180px", fontWeight: "600" }}
      disabled={isDisabled}
    >
      <button className="disabled:text-black/25 disabled:bg-[#efefef] disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed">
        <img
          src="https://media.autochek.africa/file/publicAssets/plus.svg"
          alt="plus-icon"
        />{" "}
        {t("create-new")}
      </button>
    </Dropdown>
  );

  const isDropdownDisabled = !!isAssumedUser || isDealerFinanceManager;

  const renderCreateButtons = (path: string) => {
    if (["/insurance", "/inventory", "/dealer/inventory"].includes(path)) {
      const buttonDetails = renderButtonText(path);
      return (
        <button onClick={() => navigate(`${buttonDetails?.url}`)}>
          {buttonDetails?.label}
        </button>
      );
    } else {
      return (
        <div className="button-content">
          {isFranchise || isDealerDsa || isAccountManager ? (
            renderDropdownButton(!!isAssumedUser)
          ) : (
            <>
              {["NG", "GH", "KE"]?.includes(country) ? (
                renderDropdownButton(isDropdownDisabled)
              ) : (
                <button onClick={() => navigate("/applications/select-cars")}>
                  <img
                    src="https://media.autochek.africa/file/publicAssets/plus.svg"
                    alt="plus-icon"
                  />{" "}
                  {t("new-loan")}
                </button>
              )}
            </>
          )}
        </div>
      );
    }
  };

  return <NewLoansButton>{renderCreateButtons(currentPath)}</NewLoansButton>;
};

export default NewLoansBtn;
