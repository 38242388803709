import storage from "@/lib/storage";
import {
  additionalDetailSchema,
  additionalDetailSchemaKE,
  debitMandateSchema,
  quoteFormSchema,
  quoteFormSchema1,
} from "@/validations/insurance-quote.schema";

const country = storage.getCountry();

export const validateQuoteForm = (
  values: Record<string, any>,
  step: number
) => {
  let schema;
  if (step === 1) {
    schema = quoteFormSchema;
  } else {
    schema = quoteFormSchema1;
  }

  try {
    schema.parse(values);
    return true;
  } catch (e) {
    return false;
  }
};

export const currentSchema = (step: number) => {
  return step === 1 ? quoteFormSchema : quoteFormSchema1;
};

export const validateAdditionalQuote = (values: Record<string, any>) => {
  let schema;
  if (country === "KE") {
    schema = additionalDetailSchemaKE;
  } else {
    schema = additionalDetailSchema;
  }

  try {
    schema.parse(values);
    return true;
  } catch (e) {
    return false;
  }
};

export const validateDebitMandate = (values: Record<string, any>) => {
  let schema = debitMandateSchema;
  try {
    schema.parse(values);
    return true;
  } catch (e) {
    return false;
  }
};
