import create from "zustand";
import { AxiosError } from "axios";
import { message } from "antd";
import { fetchPoliciesById } from "@/hooks/nova-api";
import { Policy } from "@/interface/policies";

interface PolicyByIdStore {
  policyByIdLoading: boolean;
  policyById?: Policy;
  fetchPolicyById: (policyId: string) => Promise<void>;
}

const usePolicyByIdStore = create<PolicyByIdStore>((set) => ({
  policyByIdLoading: false,
  policyById: undefined,
  fetchPolicyById: async (policyId: string) => {
    try {
      set({ policyByIdLoading: true });
      const response = await fetchPoliciesById(policyId);
      set({
        policyById: JSON.parse(response?.data),
        policyByIdLoading: false,
      });
    } catch (error) {
      set({ policyByIdLoading: false });
      if (error instanceof AxiosError) {
        message.error(
          JSON.parse(error?.response?.data)?.message || "Error fetching policy"
        );
      }
    }
  },
}));

export default usePolicyByIdStore;
