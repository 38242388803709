import React, { useMemo, useState } from "react";
import {
  CustomSegmentButton,
  InsuranceTabContainer,
  SearchField,
} from "@/layouts/application-layouts/styles";
import { Input, Segmented, Tabs } from "antd";
import type { TabsProps } from "antd";
import { useDebouncedCallback } from "use-debounce";
import DealerLeadGrid from "@/layouts/dealers-layouts/DealerLeadGrid";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchCarLeads } from "@/hooks/use-api";
import { ICarLeadsResp } from "@/interface/car-leads";
import storage from "@/lib/storage";
import SortLeadsFiltering from "@/components/dealerLeads/SortLeads";
import FilterCarLeads from "@/components/dealerLeads/FilterCarLeads";
import DrawerVariant from "@/components/blocks/modals/DrawerVariant";
import { useMediaQuery } from "@mui/material";

const DealerLeads = () => {
  const { search } = useLocation();
  const user = storage.getUser();
  const isMobile = useMediaQuery("(max-width:600px)");

  const [searchValue, setSearchValue] = useState("");
  const [leadsTab, setLeadsTab] = useState("new");
  const [bookedValue, setBookedValue] = useState<string | number>(
    "todayBookings"
  );
  const [carLeads, setCarLeads] = useState({} as ICarLeadsResp);
  const [displaySortFilter, setDisplaySortFilter] = useState<boolean>(false);
  const [displayFilterCarLeads, setDisplayFilterCarLeads] =
    useState<boolean>(false);
  const [openSortFilter, setOpenSortFilter] = useState(false);
  const [openFilterLead, setOpenFilterLead] = useState(false);

  const query = useMemo(() => queryString.parse(search), [search]);

  const handleSortFilter = () => {
    if (isMobile) {
      setOpenSortFilter(true);
    } else {
      setDisplaySortFilter((prev) => !prev);
      setDisplayFilterCarLeads(false);
    }
  };

  const handleFilterCarLeads = () => {
    if (isMobile) {
      setOpenFilterLead(true);
    } else {
      setDisplayFilterCarLeads((prev) => !prev);
      setDisplaySortFilter(false);
    }
  };

  const carLeadFilters = () => {
    let carLeadFilter = {};
    switch (leadsTab) {
      case "new":
        carLeadFilter = {
          status: "new",
        };
        break;
      case "closedLeads":
        carLeadFilter = { status: "closed_leads" };
        break;
      case "booked": {
        if (bookedValue === "todayBookings") {
          carLeadFilter = {
            status: "booked",
          };
        } else if (bookedValue === "pastBookings") {
          carLeadFilter = {
            status: "past_leads",
          };
        } else {
          carLeadFilter = {
            status: "upcoming",
          };
        }
        break;
      }
      case "sold":
        carLeadFilter = { status: "sold" };
        break;
      default:
        break;
    }
    return carLeadFilter;
  };

  const { isFetching: carLeadsLoading } = useQuery({
    queryKey: [
      "fetch_car_leads",
      query,
      leadsTab,
      bookedValue,
      user?.company?.id,
      searchValue,
    ],
    queryFn: () => {
      const params = new URLSearchParams({
        ...(carLeadFilters() ? carLeadFilters() : {}),
        page_size: "12",
        franchise_id: user?.company?.id,
        ...(searchValue ? { search: searchValue } : {}),
        ...query,
      });
      return fetchCarLeads(params);
    },
    onSuccess: (res: any) => {
      const parsedLeads = JSON.parse(res?.data);
      const updatedLeads = {
        ...parsedLeads,
        carLeads: parsedLeads?.carLeads?.map((lead: any) => ({
          ...lead,
          metadata: lead?.metadata ? JSON.parse(lead?.metadata) : null,
        })),
      };
      setCarLeads(updatedLeads);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const debouncedSearch = useDebouncedCallback((value) => {
    setSearchValue(value);
  }, 1000);

  const handleLeadsTabChange = (key: string) => {
    setLeadsTab(key);
  };

  const renderDealerLeadGrid = () => {
    return (
      <DealerLeadGrid
        query={query}
        tabValue={leadsTab}
        data={carLeads}
        loading={carLeadsLoading}
      />
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "new",
      label: "New",
      children: renderDealerLeadGrid(),
    },
    {
      key: "booked",
      label: "Booked",
      children: (
        <>
          <CustomSegmentButton className="pt-5">
            <Segmented
              value={bookedValue}
              onChange={setBookedValue}
              className="!w-full md:!w-[35%]"
              options={[
                {
                  label: <p className="p-1">Today&apos;s Bookings</p>,
                  value: "todayBookings",
                },
                {
                  label: <p className="p-1">Past Bookings</p>,
                  value: "pastBookings",
                },
                {
                  label: <p className="p-1">Upcoming</p>,
                  value: "upcomingBookings",
                },
              ]}
            />
          </CustomSegmentButton>
          {renderDealerLeadGrid()}
        </>
      ),
    },
    {
      key: "closedLeads",
      label: "Closed Leads",
      children: renderDealerLeadGrid(),
    },
    {
      key: "sold",
      label: "Sold",
      children: renderDealerLeadGrid(),
    },
  ];

  return (
    <main className="flex flex-col gap-6">
      <h1 className="text-xl text-[#30345E] font-semibold font-outfitbold">
        My Leads
      </h1>

      <section>
        <div className="p-2 bg-white border-b flex items-center md:justify-between gap-3 relative">
          <SearchField className="w-full md:w-auto">
            <Input
              placeholder="Search name, make, phone number"
              onChange={(e) => debouncedSearch(e.target.value)}
              prefix={
                <img
                  src="https://media.autochek.africa/file/publicAssets/icon-start.svg"
                  alt="search-icon"
                />
              }
              allowClear
              className="w-full md:w-[320px] xl:w-[500px]"
            />
          </SearchField>
          <div className="flex items-center gap-2 md:gap-4">
            <button onClick={handleFilterCarLeads}>
              <img
                src="https://media.autochek.africa/file/publicAssets/filter-lines.svg"
                alt="Filter Lines"
              />
            </button>
            <button onClick={handleSortFilter}>
              <img
                src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/sorting-05.svg?updatedAt=1740043576624"
                alt="Sort Filter"
              />
            </button>
          </div>
          {displaySortFilter && (
            <div className="absolute top-[80px] right-0 hidden md:block w-full max-w-[500px] h-auto bg-white p-4 py-6 rounded-lg shadow-none md:shadow-[0_0px_15px_rgba(0,0,0,0.1)] z-[999]">
              <SortLeadsFiltering
                query={query}
                setCloseFilter={setDisplaySortFilter}
              />
            </div>
          )}
          {displayFilterCarLeads && (
            <div className="absolute top-[80px] right-0 hidden md:block w-full max-w-[500px] h-auto bg-white p-4 py-6 rounded-lg shadow-none md:shadow-[0_0px_15px_rgba(0,0,0,0.1)] z-[999]">
              <FilterCarLeads
                query={query}
                setCloseFilter={setDisplayFilterCarLeads}
              />
            </div>
          )}
        </div>

        <InsuranceTabContainer>
          <Tabs
            activeKey={leadsTab}
            items={items}
            onChange={handleLeadsTabChange}
          />
        </InsuranceTabContainer>
      </section>

      <DrawerVariant
        open={openSortFilter}
        onClose={() => setOpenSortFilter(false)}
        placement="bottom"
        content={
          <SortLeadsFiltering
            query={query}
            setCloseFilter={setOpenSortFilter}
          />
        }
      />
      <DrawerVariant
        open={openFilterLead}
        onClose={() => setOpenFilterLead(false)}
        placement="bottom"
        content={
          <FilterCarLeads query={query} setCloseFilter={setOpenFilterLead} />
        }
      />
    </main>
  );
};

export default DealerLeads;
