import { MailIcon, PhoneVariantIcon } from "@/assets/svgs";
import tw from "tailwind-styled-components";
import { useNavigate } from "react-router-dom";
import LoanApplyButton from "@/components/dealerLeads/LoanApplyButton";
import BookLeadButton from "@/components/dealerLeads/BookLeadButton";
import useLeadModalStore from "@/states/leadsModal";
import useLeadDrawerStore from "@/states/leadsDrawer";
import { useMediaQuery } from "@mui/material";
import { ICarLeadProps } from "@/interface/car-leads";
import formatInt from "@/utils/format-int";
import moment from "moment";
import { ImageLoader } from "@/utils/ImageLoader";
import getInitial from "@/utils/get-initial";

interface IDealerCardProps {
  tabValue: string;
  item: ICarLeadProps;
  setLeadData: React.Dispatch<React.SetStateAction<ICarLeadProps>>;
}

const Text = tw.p`text-xs lg:text-sm text-[#30345E] bg-[#EEEFFF] font-semibold w-fit h-7 px-1 md:px-2 lg:px-3 rounded-full flex items-center justify-center`;

const DealerLeadCard: React.FC<IDealerCardProps> = ({
  tabValue,
  item,
  setLeadData,
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");

  const { openBookLeadModal } = useLeadModalStore();
  const { openBookLeadDrawer } = useLeadDrawerStore();

  const handleBookLead = (data: ICarLeadProps) => {
    setLeadData(data);
    if (isMobile) {
      openBookLeadDrawer();
    } else {
      openBookLeadModal();
    }
  };

  const renderCardActionButtons = (value: string, data: any) => {
    switch (value) {
      case "closedLeads":
        return (
          <div className="flex items-center gap-2">
            <LoanApplyButton
              onHandleSubmit={() =>
                navigate(
                  `/applications/loans?car_id=${data?.metadata?.carId}&form_type=new`
                )
              }
            />
            <BookLeadButton onHandleSubmit={() => handleBookLead(data)} />
          </div>
        );
      case "new":
      case "booked":
      case "sold":
        return (
          <button
            onClick={() => navigate(`/dealer/leads/${data.id}`)}
            className="text-sm md:text-base text-[#30345E] font-semibold w-full h-11 md:h-12 bg-[#FFB619] rounded-full"
          >
            View Details
          </button>
        );
    }
  };

  return (
    <main>
      <div className="border border-solid border-[#E5E7EB] bg-white rounded-lg px-3 py-3.5 divide-y divide-[#E5E7EB] md:cursor-pointer">
        <div className="flex items-start gap-2 pb-2">
          <p className="text-sm md:text-lg font-semibold font-outfit uppercase text-[#30345E] !w-10 !h-10 rounded-full bg-[#CCDDFF] flex items-center justify-center">
            {item?.metadata?.firstName &&
              getInitial(
                `${item?.metadata?.firstName} ${item?.metadata?.lastName}`
              )}
          </p>
          <div className="flex-1">
            <p className="text-base md:text-lg text-[#202020] font-semibold leading-relaxed capitalize">
              {item?.metadata?.firstName
                ? `${item?.metadata?.firstName} ${item?.metadata?.lastName}`
                : "---"}
            </p>
            <div className="flex flex-col md:flex-row md:items-center md:justify-between">
              <p className="text-sm text-[#6B7280] mb-1 font-normal flex items-center gap-1">
                <MailIcon /> {item?.metadata?.email ?? "---"}
              </p>
              <p className="text-sm text-[#2563EB] font-normal flex items-center gap-1">
                <PhoneVariantIcon color="#2563EB" />{" "}
                {item?.metadata?.primaryPhone ?? "---"}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3 pt-3 pb-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="w-[66px] h-[64px] bg-gray-300 overflow-hidden rounded-md">
                <img
                  src={ImageLoader({
                    src: `${
                      item?.metadata?.carImage ??
                      "https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg"
                    }`,
                    width: 400,
                    quality: 100,
                  })}
                  alt="Car Thumbnail"
                  className={`${
                    item?.metadata?.carImage ? "object-cover" : "object-contain"
                  } w-full h-[64px] object-center`}
                />
              </div>
              <div>
                <p className="text-base md:text-lg font-semibold text-[#30345E]">
                  {item?.metadata?.carMake
                    ? `${item?.metadata?.carYear} ${item?.metadata?.carMake} ${item?.metadata?.carModel}`
                    : "---"}
                </p>
                <p className="block md:hidden text-sm md:text-base text-[#6B7280]">
                  Price:{" "}
                  <span className="font-semibold text-base md:text-lg text-[#30345E]">
                    {formatInt(
                      Number(item?.metadata?.carMarketplacePrice),
                      true
                    )}
                  </span>
                </p>
              </div>
            </div>
            <p className="hidden md:block text-sm md:text-base text-[#6B7280] whitespace-nowrap">
              Price:{" "}
              <span className="font-semibold text-base md:text-lg text-[#30345E]">
                {formatInt(Number(item?.metadata?.carMarketplacePrice), true)}
              </span>
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2">
              <p className="text-sm font-semibold">Contact type:</p>
              {item?.source ? (
                <div className="flex items-center gap-1.5">
                  {item?.source?.split(",")?.map((el) => (
                    <Text key={el}>{el?.replaceAll("_", " ")}</Text>
                  ))}
                </div>
              ) : (
                "---"
              )}
            </div>
            <div className="flex items-center gap-2">
              <Text>
                Lead date:{" "}
                {item?.createdAt
                  ? moment(item?.createdAt).format("MMM Do YYYY")
                  : "---"}
              </Text>
            </div>
          </div>
        </div>

        <div className="pt-4">{renderCardActionButtons(tabValue, item)}</div>
      </div>
    </main>
  );
};

export default DealerLeadCard;
