import { Loader } from "@/pages/auth/styles/loader";

export const renderLoadingCard = () => {
  return (
    <div className="w-full my-0 mx-auto pt-10 pb-3 flex flex-col justify-center gap-5 text-button-variants-primary-scene max-w-full md:max-w-[75%] xl:max-w-[45%] md:bg-white p-0 md:p-6 min-h-[400px]">
      <div className="flex flex-col items-center w-full">
        <Loader variant="secondary" />
        <p className="text-sm lg:text-base text-[#6b7280]">Loading...</p>
      </div>
    </div>
  );
};
