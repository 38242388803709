import React from "react";
import { Drawer, DrawerProps } from "antd";

type TDrawerVariantProps = {
  title?: React.ReactNode | string;
  content: React.ReactNode;
  placement: DrawerProps["placement"];
  open: boolean;
  onClose: () => void;
};

const DrawerVariant: React.FC<TDrawerVariantProps> = ({
  title,
  content,
  placement,
  open,
  onClose,
}) => {
  return (
    <Drawer
      title={
        title ? (
          <span className="text-xl font-semibold ml-[-12px]">{title}</span>
        ) : (
          ""
        )
      }
      placement={placement}
      onClose={onClose}
      open={open}
      height="auto"
      className="custom-mobile-drawer"
      headerStyle={{
        borderBottom: "none",
        display: "flex",
        justifyContent: "start",
      }}
      closeIcon={false}
    >
      {content}
    </Drawer>
  );
};

export default DrawerVariant;
