import { useState, useEffect, useRef, Fragment } from "react";
import storage from "@/lib/storage";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDealerCars } from "@/hooks/use-loan";
import { LoanLimitStyled } from "@/layouts/application-layouts/styles";
import { Box, Container, Stack, ThemeProvider } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grid from "@mui/material/Grid";
import client from "@/lib/axios-client";
import { useQuery } from "@tanstack/react-query";
import InputGroup from "@/components/formElements/input-group";
import LimitValuesCard from "@/components/LimitValuesCard";
import { LIMIT_REQUEST_STATUS } from "@/utils/finance-status";
import AddNewCollateral from "@/components/AddNewCollaterals";
import {
  checkErrors,
  checkLoanFieldErrors,
  cleanFieldObject,
  extractAndSetLoanFields,
  fieldDefinitions,
  formFieldForDealership,
  getCleanedLoanFields,
  getFilteredCarResult,
  getInitialData,
  getUpdatedCarFields,
  getValidFormData,
  handleCollateralInputOnChange,
  handleInputOnChange,
  mapCarsInInventory,
  mapCarsNotInInventory,
  mapPrefilledCarInfo,
} from "@/utils/process-form-data";
import FormsPageLoader from "@/components/FormLoader";
import CollateralCarCardContainer from "@/components/CollateralCarCardContainer";
import SectionsActionButton from "@/components/FormSectionButton";
import PreferenceActionButton from "@/components/FormPreferenceButton";
import CollateralActionButton from "@/components/FormCollateralButton";
import TermsConditionActionButton from "@/components/FormTermsConditionButton";
import { createCustomTheme } from "@/utils/themeUtils";
import { FINANCE_TYPE } from "@/utils/finance-type";
import VehicleActionButton from "./FormVehicleButton";

interface IDealerFinancingForm {
  productData: any;
  productCarField: any;
  loanById: any;
  loanTermsById: any;
  loanPreferenceById: any;
  loanCarsById: any;
  loanVehicleById: any;
  pageLoading: any;
  getLoanId: string;
  new_loan: string;
}

export default function DealerFinancingForm({
  productData,
  productCarField,
  loanById,
  loanTermsById,
  loanPreferenceById,
  loanCarsById,
  loanVehicleById,
  pageLoading,
  getLoanId,
  new_loan,
}: Readonly<IDealerFinancingForm>) {
  const navigate = useNavigate();
  const { t } = useTranslation("loan-form");
  const { t: t1 } = useTranslation("inventory");
  const country = storage.getCountry();
  const user = storage.getUser();
  let selectOptions: any = {};
  const theme = createCustomTheme("#ffb619");

  //states
  const [expanded, setExpanded] = useState(0);
  const [completed, setCompleted]: any = useState({});
  const [formData, setFormData] = useState<any>({});
  const [alert, setAlert]: any = useState("");
  const submitionInProgress = useRef(false);
  const [loading, setLoading] = useState(false);
  const [loanId, setLoanId] = useState<string>("");
  const [loanFields, setLoanFields]: any = useState({});
  const [termsCondition, setTermsCondition]: any = useState({});
  const [loanCollateral, setLoanCollateral]: any = useState({});
  const [loanVehicleFields, setLoanVehicleFields]: any = useState({});
  const [allFranchiseCar, setAllFranchiseCar]: any = useState([]);
  const [franchiseCar, setFranchiseCar]: any = useState([]);
  const [selectedCarValue, setSelectedCarValue]: any = useState([]);
  const [formRender, setFormRender] = useState(false);
  const [loanLimitById, setLoanLimitById] = useState<any>([]);
  const [carFieldsArr, setCarFieldsArr] = useState<any>([]);
  const [prefilledCarFields, setPrefilledCarFields]: any = useState([]);
  const [prefilledCarsNotInventory, setPrefilledCarsNotInventory]: any =
    useState([]);
  const [showPrefilledCars, setShowPrefilledCars]: any = useState([]);
  const [searchParams] = useSearchParams();
  const dealerId = searchParams.get("dealer_id");

  useEffect(() => {
    setLoanId(getLoanId);
  }, [getLoanId, setLoanId]);

  useQuery({
    queryKey: ["loan_limit_by_id", country, user?.company?.id || dealerId],
    queryFn: () => {
      return client.get(`/v1/dealer/${user?.company?.id || dealerId}`);
    },
    onSuccess: (res: any) => {
      const parsedLoanDetails = JSON.parse(res?.data);
      const filteredParsed = parsedLoanDetails?.results?.find(
        (item: any) => item?.status === LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED
      );
      setLoanLimitById(filteredParsed?.customer?.profile);
    },
    enabled: !!user?.company?.id || !!dealerId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const termsProductKey = (productData: any, index: number) => {
    if (productData?.name === FINANCE_TYPE.DUTY_FINANCE) {
      return (
        productData?.config?.sections?.length +
        productData?.config?.preference?.length +
        productData?.config?.collateral?.length +
        productData?.config?.vehicle?.length +
        index
      );
    } else {
      return (
        productData?.config?.sections?.length +
        productData?.config?.preference?.length +
        productData?.config?.collateral?.length +
        index
      );
    }
  };

  useEffect(() => {
    const {
      initialFormData,
      initialTermsCondition,
      initialLoanFields,
      initialVehicleFields,
      formRender,
    } = getInitialData(
      loanById,
      loanTermsById,
      loanPreferenceById,
      new_loan,
      loanVehicleById
    );

    setFormData(initialFormData ?? {});
    setTermsCondition(initialTermsCondition ?? {});
    setLoanFields(initialLoanFields ?? {});
    setLoanVehicleFields(initialVehicleFields ?? {});
    setFormRender(formRender);
  }, [loanById, loanTermsById, loanPreferenceById, new_loan, loanVehicleById]);

  //Loan Sections
  const handleAccordionChange =
    (panel: any) => (event: any, newExpanded: any) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    const cars = getDealerCars(country, user?.company?.id || dealerId);
    cars.then((result) => {
      const parsedResult = JSON.parse(result?.data);
      const filteredCarResult = getFilteredCarResult(parsedResult);
      setFranchiseCar(filteredCarResult);
      setAllFranchiseCar(parsedResult);
    });
  }, [country, user?.company?.id, dealerId]);

  // aboutDealership fields
  useEffect(() => {
    if (!loanLimitById) return;

    const data = formFieldForDealership(loanLimitById, fieldDefinitions);

    setFormData((state: any) => ({
      ...state,
      ...data,
    }));
  }, [loanLimitById]);

  // initialLoanFields Data
  useEffect(() => {
    extractAndSetLoanFields(loanPreferenceById, productData, setLoanFields);
  }, [loanPreferenceById, productData]);

  // render prefilled car inventory data
  useEffect(() => {
    if (loanCarsById && loanCarsById?.length > 0) {
      const mappedCarsInInventory = mapCarsInInventory(loanCarsById);
      const mappedCarsNotInInventory = mapCarsNotInInventory(loanCarsById);
      const mappedPrefilledCarInfo = mapPrefilledCarInfo(loanCarsById);

      setPrefilledCarFields(mappedCarsInInventory);
      setPrefilledCarsNotInventory(mappedCarsNotInInventory);
      setShowPrefilledCars(mappedPrefilledCarInfo);
    } else {
      setPrefilledCarFields([]);
      setShowPrefilledCars([]);
      setPrefilledCarsNotInventory([]);
    }
  }, [loanCarsById]);

  const totalCarCollaterals = [
    ...(selectedCarValue?.map((item: any) => item?.dealerCar) || []),
    ...(prefilledCarFields?.map((item: any) => item?.dealerCar) || []),
  ];

  const totalCarFields = [...carFieldsArr, ...prefilledCarsNotInventory];

  // handle all payloads submitted to the form
  const handlePayload = (isDraft: boolean) => {
    const validFormData = getValidFormData(formData);
    const cleanedLoanFields = getCleanedLoanFields(loanFields);
    const updatedCarFields = getUpdatedCarFields(totalCarFields);
    const cleanedTermsCondition = cleanFieldObject(termsCondition);

    Object.keys(loanVehicleFields).forEach((item) => {
      if (loanVehicleFields[item]?.type === "amount") {
        loanVehicleFields[item].value = loanVehicleFields[
          item
        ]?.value?.replaceAll(",", "");
      }
    });

    const payload: any = {
      product_id: productData?.id,
      dealer_id: user?.company?.id || dealerId,
      country: country,
      profile: Object.values(validFormData),
      preference: cleanedLoanFields,
      collaterals: totalCarCollaterals,
      car_fields: updatedCarFields,
      terms_and_conditions: Object.values(cleanedTermsCondition),
      vehicle: Object.values(loanVehicleFields),
      is_draft: isDraft,
    };

    const updatePayload = {
      finance_request_id: loanId,
      profile: Object.values(validFormData),
      preference: cleanedLoanFields,
      collaterals: totalCarCollaterals,
      car_fields: updatedCarFields,
      terms_and_conditions: Object.values(cleanedTermsCondition),
      vehicle: Object.values(loanVehicleFields),
      is_draft: isDraft,
    };

    const _payload = cleanFieldObject(payload);
    const _updatePayload = cleanFieldObject(updatePayload);

    return loanId ? _updatePayload : _payload;
  };

  const handleValidateData = async (isDraft: boolean): Promise<boolean> => {
    const errors =
      checkErrors(formData, productData.config.sections) ??
      checkLoanFieldErrors(loanFields, productData.config.preference);
    if (errors && !isDraft) {
      setAlert(errors);
      return false;
    }
    return true;
  };

  // handle form submission
  const submit = async (e: any, isDraft = true): Promise<void> => {
    e.preventDefault();
    setAlert(null);
    const userId = user?.company?.id || dealerId;

    if (loading || submitionInProgress.current) {
      return;
    }

    if (isDraft && !userId) {
      return;
    }

    submitionInProgress.current = true;
    !isDraft && setLoading(true);
    if (!(await handleValidateData(isDraft))) return;
    const payload = handlePayload(isDraft);

    try {
      const response = loanId
        ? await client.put(`v1/dealer/update-dealer-finance`, payload)
        : await client.post(`v1/dealer/create-dealer-finance`, payload);
      if (response) {
        const parsedResponse = JSON.parse(response?.data);
        setLoanId(parsedResponse?.id);
        submitionInProgress.current = false;
        setLoading(false);
        if (!isDraft) {
          navigate(
            `/dealer/loan-limit/confirmation?path=dealer-financing&dealer_id=${dealerId}`
          );
        }
      }
    } catch (error: any) {
      submitionInProgress.current = false;
      let errorMessage = JSON.parse(error?.response?.data);
      setLoading(false);
      setAlert(
        errorMessage.message || typeof errorMessage?.error === "string"
          ? errorMessage?.message
          : "Something went wrong."
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {pageLoading ? (
        <FormsPageLoader />
      ) : (
        <div>
          <button
            className="flex items-center gap-2 text-lg text-[#30345e] font-semibold"
            onClick={() => navigate(-1)}
          >
            <img
              src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
              alt="left-arrow"
            />
            {capitalFirstLetter(t1("back"))}
          </button>

          <LoanLimitStyled>
            <LimitValuesCard />
            <div className="text-[25.6px] text-[#30345E] text-center font-bold">
              {`${productData?.name} Application`}
            </div>
            <Box
              style={{
                backgroundColor: "#F8FAFC",
                marginBottom: "-64px",
                paddingBottom: "64px",
              }}
            >
              <Container className="custom-container">
                {formRender && (
                  <form onSubmit={(e) => submit(e, false)}>
                    <Stack spacing={5}>
                      <Stack alignItems="center">
                        <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={!!alert}
                          autoHideDuration={5000}
                          onClose={() => setAlert("")}
                        >
                          <Alert severity="error" variant="filled">
                            {alert}
                          </Alert>
                        </Snackbar>
                      </Stack>

                      {productData?.config?.sections?.map(
                        (item: any, index: number) => (
                          <Accordion
                            key={item.name}
                            expanded={expanded === index}
                            onChange={handleAccordionChange(index)}
                          >
                            <AccordionSummary
                              expandIcon={
                                completed[index] && expanded !== index ? (
                                  <CheckCircleIcon sx={{ color: "#22C55E" }} />
                                ) : (
                                  <ExpandMoreIcon />
                                )
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography
                                variant="h6"
                                fontWeight="600"
                                textAlign="center"
                              >
                                {item.label}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <InputGroup
                                  label={item.label}
                                  fields={item.fields}
                                  formData={formData}
                                  onBlur={submit}
                                  handleInputChange={(e: any) =>
                                    handleInputOnChange(e, setFormData)
                                  }
                                  selectOptions={{
                                    ...selectOptions,
                                  }}
                                  addDirector={null}
                                  directorCount={null}
                                />
                                <Grid container spacing={2} marginTop="16px">
                                  <Grid item xs={12} sm={12} md={4}>
                                    <SectionsActionButton
                                      label={t("Next")}
                                      loading={loading}
                                      data={formData}
                                      fields={item.fields}
                                      index={index}
                                      setAlert={setAlert}
                                      setCompleted={setCompleted}
                                      setExpanded={setExpanded}
                                      submit={submit}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={4}>
                                    <SectionsActionButton
                                      label={t("Save and Exit")}
                                      loading={loading}
                                      data={formData}
                                      fields={item.fields}
                                      index={index}
                                      setAlert={setAlert}
                                      setCompleted={setCompleted}
                                      setExpanded={setExpanded}
                                      loanId={loanId}
                                      submit={submit}
                                    />
                                  </Grid>
                                </Grid>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        )
                      )}

                      {productData?.config?.preference?.map(
                        (item: any, index: number) => (
                          <Accordion
                            key={item.name}
                            expanded={
                              expanded ===
                              productData?.config?.sections?.length + index
                            }
                            onChange={handleAccordionChange(
                              productData?.config?.sections?.length + index
                            )}
                          >
                            <AccordionSummary
                              expandIcon={
                                completed[
                                  productData?.config?.sections?.length + index
                                ] &&
                                expanded !==
                                  productData?.config?.sections?.length +
                                    index ? (
                                  <CheckCircleIcon sx={{ color: "#22C55E" }} />
                                ) : (
                                  <ExpandMoreIcon />
                                )
                              }
                            >
                              <Typography
                                variant="h6"
                                fontWeight="600"
                                textAlign="center"
                              >
                                {t(item.label)}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <InputGroup
                                label={item.label}
                                fields={item.fields}
                                formData={loanFields}
                                onBlur={submit}
                                handleInputChange={(e: any) =>
                                  handleInputOnChange(e, setLoanFields)
                                }
                                selectOptions={{
                                  ...selectOptions,
                                }}
                                addDirector={null}
                                directorCount={null}
                              />
                              <Grid container spacing={2} marginTop="16px">
                                <Grid item xs={12} sm={12} md={4}>
                                  <PreferenceActionButton
                                    label={t("Next")}
                                    loading={loading}
                                    data={loanFields}
                                    fields={item.fields}
                                    productData={productData}
                                    index={index}
                                    setAlert={setAlert}
                                    setCompleted={setCompleted}
                                    setExpanded={setExpanded}
                                    submit={submit}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <PreferenceActionButton
                                    label={t("Save and Exit")}
                                    loading={loading}
                                    data={loanFields}
                                    fields={item.fields}
                                    productData={productData}
                                    index={index}
                                    setAlert={setAlert}
                                    setCompleted={setCompleted}
                                    setExpanded={setExpanded}
                                    loanId={loanId}
                                    submit={submit}
                                  />
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        )
                      )}

                      {productData?.config?.collateral?.map(
                        (item: any, index: number) => (
                          <Accordion
                            key={item.name}
                            expanded={
                              expanded ===
                              productData?.config?.sections?.length +
                                productData?.config?.preference?.length +
                                index
                            }
                            onChange={handleAccordionChange(
                              productData?.config?.sections?.length +
                                productData?.config?.preference?.length +
                                index
                            )}
                          >
                            <AccordionSummary
                              expandIcon={
                                completed[
                                  productData?.config?.sections?.length +
                                    productData?.config?.preference?.length +
                                    index
                                ] &&
                                expanded !==
                                  productData?.config?.sections?.length +
                                    productData?.config?.preference?.length +
                                    index ? (
                                  <CheckCircleIcon sx={{ color: "#22C55E" }} />
                                ) : (
                                  <ExpandMoreIcon />
                                )
                              }
                            >
                              <Typography
                                variant="h6"
                                fontWeight="600"
                                textAlign="center"
                              >
                                {t(item.label)}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="relative">
                              <div className="mb-4">
                                <InputGroup
                                  label={item.label}
                                  fields={item.fields}
                                  formData={loanCollateral}
                                  onBlur={submit}
                                  handleInputChange={(e: any) =>
                                    handleCollateralInputOnChange(
                                      e,
                                      setLoanCollateral,
                                      selectedCarValue,
                                      setSelectedCarValue
                                    )
                                  }
                                  selectOptions={{
                                    ...selectOptions,
                                    dealerCar: franchiseCar,
                                  }}
                                  addDirector={null}
                                  directorCount={null}
                                />
                              </div>

                              {/* Displays the Selected Car Card */}
                              <CollateralCarCardContainer
                                showPrefilledCars={showPrefilledCars}
                                setShowPrefilledCars={setShowPrefilledCars}
                                selectedCarValue={selectedCarValue}
                                setSelectedCarValue={setSelectedCarValue}
                                setPrefilledCarsNotInventory={
                                  setPrefilledCarsNotInventory
                                }
                                setPrefilledCarFields={setPrefilledCarFields}
                                allFranchiseCar={allFranchiseCar}
                                carFieldsArr={carFieldsArr}
                                setCarFieldsArr={setCarFieldsArr}
                              />

                              {/* Add New Collateral Form */}
                              <AddNewCollateral
                                submit={submit}
                                productCarField={productCarField}
                                setAlert={setAlert}
                                setCarFieldsArr={setCarFieldsArr}
                              />

                              <Grid container spacing={2} marginTop="16px">
                                <Grid item xs={12} sm={12} md={4}>
                                  <CollateralActionButton
                                    label={t("Next")}
                                    loading={loading}
                                    data={loanCollateral}
                                    fields={item.fields}
                                    productData={productData}
                                    index={index}
                                    setAlert={setAlert}
                                    setCompleted={setCompleted}
                                    setExpanded={setExpanded}
                                    carFields={carFieldsArr}
                                    prefilledCars={prefilledCarFields}
                                    prefilledFields={prefilledCarsNotInventory}
                                    submit={submit}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <CollateralActionButton
                                    label={t("Save and Exit")}
                                    loading={loading}
                                    data={loanCollateral}
                                    fields={item.fields}
                                    productData={productData}
                                    index={index}
                                    setAlert={setAlert}
                                    setCompleted={setCompleted}
                                    setExpanded={setExpanded}
                                    carFields={carFieldsArr}
                                    prefilledCars={prefilledCarFields}
                                    prefilledFields={prefilledCarsNotInventory}
                                    submit={submit}
                                    loanId={loanId}
                                  />
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        )
                      )}

                      {productData?.name === FINANCE_TYPE.DUTY_FINANCE && (
                        <Fragment>
                          {productData?.config?.vehicle?.map(
                            (item: any, index: number) => (
                              <Accordion
                                key={item.name}
                                expanded={
                                  expanded ===
                                  productData?.config?.sections?.length +
                                    productData?.config?.preference?.length +
                                    productData?.config?.collateral?.length +
                                    index
                                }
                                onChange={handleAccordionChange(
                                  productData?.config?.sections?.length +
                                    productData?.config?.preference?.length +
                                    productData?.config?.collateral?.length +
                                    index
                                )}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    completed[
                                      productData?.config?.sections?.length +
                                        productData?.config?.preference
                                          ?.length +
                                        productData?.config?.collateral
                                          ?.length +
                                        index
                                    ] &&
                                    expanded !==
                                      productData?.config?.sections?.length +
                                        productData?.config?.preference
                                          ?.length +
                                        productData?.config?.collateral
                                          ?.length +
                                        index ? (
                                      <CheckCircleIcon
                                        sx={{ color: "#22C55E" }}
                                      />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )
                                  }
                                >
                                  <Typography
                                    variant="h6"
                                    fontWeight="600"
                                    textAlign="center"
                                  >
                                    {t(item.label)}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <InputGroup
                                    label={item.label}
                                    fields={item.fields}
                                    formData={loanVehicleFields}
                                    onBlur={submit}
                                    handleInputChange={(e: any) =>
                                      handleInputOnChange(
                                        e,
                                        setLoanVehicleFields
                                      )
                                    }
                                    selectOptions={{
                                      ...selectOptions,
                                    }}
                                    addDirector={null}
                                    directorCount={null}
                                  />

                                  <Grid container spacing={2} marginTop="16px">
                                    <Grid item xs={12} sm={12} md={4}>
                                      <VehicleActionButton
                                        label={t("Next")}
                                        loading={loading}
                                        data={loanVehicleFields}
                                        fields={item.fields}
                                        productData={productData}
                                        index={index}
                                        setAlert={setAlert}
                                        setCompleted={setCompleted}
                                        setExpanded={setExpanded}
                                        submit={submit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <VehicleActionButton
                                        label={t("Save and Exit")}
                                        loading={loading}
                                        data={loanVehicleFields}
                                        fields={item.fields}
                                        productData={productData}
                                        index={index}
                                        setAlert={setAlert}
                                        setCompleted={setCompleted}
                                        setExpanded={setExpanded}
                                        loanId={loanId}
                                        submit={submit}
                                      />
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            )
                          )}
                        </Fragment>
                      )}

                      {productData?.config?.termsAndConditions?.map(
                        (item: any, index: number) => (
                          <Accordion
                            key={item.name}
                            expanded={
                              expanded === termsProductKey(productData, index)
                            }
                            onChange={handleAccordionChange(
                              termsProductKey(productData, index)
                            )}
                          >
                            <AccordionSummary
                              expandIcon={
                                completed[
                                  termsProductKey(productData, index)
                                ] &&
                                expanded !==
                                  termsProductKey(productData, index) ? (
                                  <CheckCircleIcon sx={{ color: "#22C55E" }} />
                                ) : (
                                  <ExpandMoreIcon />
                                )
                              }
                            >
                              <Typography
                                variant="h6"
                                fontWeight="600"
                                textAlign="center"
                              >
                                {t(item.label)}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <InputGroup
                                label={item.label}
                                fields={item.fields}
                                formData={termsCondition}
                                onBlur={submit}
                                handleInputChange={(e: any) =>
                                  handleInputOnChange(e, setTermsCondition)
                                }
                                selectOptions={{
                                  ...selectOptions,
                                }}
                                addDirector={null}
                                directorCount={null}
                              />
                              <Grid container spacing={2} marginTop="16px">
                                <Grid item xs={12} sm={12} md={4}>
                                  <TermsConditionActionButton
                                    label={t("Submit")}
                                    loading={loading}
                                    data={termsCondition}
                                    fields={item.fields}
                                    index={index}
                                    setAlert={setAlert}
                                    setCompleted={setCompleted}
                                    submit={submit}
                                  />
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        )
                      )}
                    </Stack>
                  </form>
                )}
              </Container>
            </Box>
          </LoanLimitStyled>
        </div>
      )}
    </ThemeProvider>
  );
}
