import { useState } from "react";
import { Button, Modal, message } from "antd";

import tw from "tailwind-styled-components";
import client from "@/lib/axios-client";
import { useQueryClient } from "@tanstack/react-query";
import storage from "@l/storage";
import { useTranslation } from "react-i18next";

interface IListModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  carData: any;
}

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const confirmStyle = {
  background: "#FFB619",
  borderRadius: 60,
  color: "#30345E",
  fontWeight: 600,
  border: 0,
  height: 48,
  minWidth: 101,
};

const cancelStyle = {
  background: "#fff",
  borderRadius: 60,
  color: "#30345E",
  fontWeight: 600,
  border: "1px solid #30345E",
  height: 48,
  minWidth: 101,
  marginRight: 12,
};

const paragraphStyle = {
  fontSize: 15,
  fontWeight: 500,
  marginBottom: 24,
};

const titleStyle = {
  fontSize: 22,
  fontWeight: 600,
  color: "#30345E",
};

const RequestInspectionModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  carData,
}: IListModal) => {
  const queryClient = useQueryClient();
  const user = storage.getUser();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("modal");

  const handleSubmit = async () => {
    setLoading(true);

    const payload = {
      vin: carData?.vin,
      model_id: carData?.model?.id,
      country: carData?.country,
      inspection_type: "dealer-regular",
      source: "franchise",
      franchise_id: user?.company?.id || carData?.carManagerId,
    };

    try {
      const response = await client.post(
        "/v1/inventory/inspection_request",
        payload
      );
      if (response) {
        message.success(t("inspection-request-successfully"));
        queryClient.invalidateQueries();
        setLoading(false);
        handleOk();
      }
    } catch (err: any) {
      const errorMessage = JSON.parse(err.response?.data);
      message.error(
        errorMessage?.message ||
          errorMessage?.error ||
          t("error-requesting-inspection")
      );
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title={<div style={titleStyle}>{t("request-inspection")}</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key={1} onClick={handleCancel} style={cancelStyle}>
            {t("cancel")}
          </Button>,
          <Button
            key={2}
            type="primary"
            onClick={handleSubmit}
            style={confirmStyle}
          >
            {loading ? <Loader variant={"secondary"} /> : t("confirm")}
          </Button>,
        ]}
        closeIcon={
          <img
            src="https://media.autochek.africa/file/publicAssets/x-close-u.svg"
            alt="close-icon"
            style={{ marginTop: 8 }}
          />
        }
      >
        <p style={paragraphStyle}>
          {t("you-are-about-to-request-an-inspection")}.
        </p>
      </Modal>
    </div>
  );
};

export default RequestInspectionModal;
