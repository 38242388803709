import React from "react";

interface IEmptyCard {
  text: string;
}

//t("you-do-not-have-a-car")

const GridEmptyCard: React.FC<IEmptyCard> = ({ text }) => {
  return (
    <div className="w-full max-w-full p-3 md:p-4">
      <div className="flex justify-center items-center min-h-[500px] xl:min-h-[700px] border border-solid rounded-lg">
        <div className="">
          <img
            src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
            alt="Note"
            className="my-0 mx-auto w-full h-auto"
          />
          <div className="text-base md:text-lg text-center text-[#6b7280] font-normal">
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridEmptyCard;
