import React, { useEffect, useState } from "react";
import {
  FormDropdownStyle,
  InputLabelStyle,
  SelectTextFieldStyle,
} from "@/layouts/application-layouts/styles";
import { Autocomplete, FormLabel, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { IInsuranceFormProps } from "./types";
import { MailIcon2, PhoneIcon2 } from "@/assets/svgs";
import { IDealerData } from "@/validations/insurance-quote.schema";
import getInitial from "@/utils/get-initial";

interface IOption {
  label: string;
  value: string;
  id: string;
  email: string;
}

const DealerSection: React.FC<IInsuranceFormProps> = ({ methods, data, t }) => {
  const errors = { ...methods.formState.errors } as Record<string, any>;
  const [dealerData, setDealerData] = useState<IDealerData>();

  useEffect(() => {
    setDealerData(data?.selectedDealer);
  }, [data?.selectedDealer]);

  return (
    <div>
      <div className="pt-2 pb-4 space-y-2">
        <FormLabel sx={InputLabelStyle}>Search dealer</FormLabel>
        <Controller
          name="dealerName"
          control={methods.control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              fullWidth
              id="dealerName"
              disablePortal
              loading={data?.isSearchingDealers}
              options={data?.dealers ?? []}
              value={
                data?.dealers?.find(
                  (option: IOption) => option?.value === value
                ) || null
              }
              getOptionLabel={(option: IOption) => option?.label || ""}
              isOptionEqualToValue={(option: IOption, value: IOption) =>
                option.value === value.value
              }
              onChange={(_, newValue) => {
                onChange(newValue?.value);
                methods.setValue("dealerId", newValue?.id as string);
                methods.setValue("dealerEmail", newValue?.email as string);
              }}
              sx={FormDropdownStyle}
              renderInput={(params) => (
                <TextField
                  onChange={(e) =>
                    methods.setValue("searchDealer", e.target.value)
                  }
                  {...params}
                  placeholder={t("Search and select dealer")}
                  sx={SelectTextFieldStyle}
                />
              )}
            />
          )}
        />
        {errors?.dealerName?.message && (
          <span className="text-red-600 text-sm">
            {errors?.dealerName?.message}
          </span>
        )}
      </div>

      {data?.selectedDealer && (
        <div className="flex flex-col gap-2 md:gap-3 w-full border border-solid p-3 rounded-md">
          <div className="flex items-center gap-2">
            <p className="w-10 h-10 bg-[#CCDDFF] rounded-full text-[#30345E] text-sm font-medium flex items-center justify-center">
              {dealerData?.name && getInitial(dealerData?.name)}
            </p>
            <p className="font-semibold text-[#30345E] text-lg">
              {dealerData?.name}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex justify-between items-center">
              <p className="flex items-center gap-1 text-xs text-black font-normal">
                <PhoneIcon2 /> Phone Number
              </p>
              <p className="text-sm text-[#2563EB] font-semibold">
                {dealerData?.phoneNumber}
              </p>
            </div>
            <div className="flex justify-between items-center">
              <p className="flex items-center gap-1 text-xs text-black font-normal">
                <MailIcon2 /> Email Address
              </p>
              <p className="text-sm text-[#2563EB] font-semibold">
                {dealerData?.email}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DealerSection;
