type TMapIframeProps = {
  address: string;
};

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY as string;

const PlacesGoogleMap: React.FC<TMapIframeProps> = ({ address }) => {
  return (
    <div className="w-full h-[400px]">
      <iframe
        title="Google Maps Api"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}
    &q=${encodeURIComponent(address)}`}
      ></iframe>
    </div>
  );
};

export default PlacesGoogleMap;
