import { MailIcon, PhoneIcon } from "@/assets/svgs";
import { Loader } from "@/pages/auth/styles/loader";
import getInitial from "@/utils/get-initial";
import React from "react";

interface Props {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  loading: boolean;
}

const CustomerContactCard: React.FC<Props> = ({
  firstName,
  lastName,
  emailAddress,
  phoneNumber,
  loading,
}) => {
  if (loading) {
    return (
      <div className="w-full min-h-[112px] flex items-center justify-center bg-white border border-solid border-[#E5E7EB] rounded-2xl">
        <Loader variant="secondary" />
      </div>
    );
  }

  return (
    <div className="pt-4 md:pt-3.5 flex flex-col gap-2.5 bg-white p-4 border border-solid border-[#E5E7EB] rounded-2xl">
      <div className="text-xs text-[#30345E] font-normal font-outfit bg-[#EEEFFF] rounded-[4px] py-1 px-2 w-fit">
        Customer&apos;s contact
      </div>
      <div className="flex items-end justify-between">
        <div className="flex items-center gap-2">
          <div className="text-sm font-medium font-outfit uppercase text-[#30345E] w-10 h-10 rounded-full bg-[#CCDDFF] flex items-center justify-center">
            {firstName && getInitial(`${firstName} ${lastName}`)}
          </div>
          <div>
            <p className="text-base text-[#202020] font-semibold leading-relaxed">
              {firstName ? `${firstName} ${lastName}` : "---"}
            </p>
            <p className="text-[11px] md:text-sm text-[#6B7280] font-normal flex items-center gap-1">
              <MailIcon /> {emailAddress}
            </p>
          </div>
        </div>
        <p className="text-[11px] md:text-sm text-[#6B7280] font-normal flex items-center gap-1">
          <PhoneIcon /> {phoneNumber}
        </p>
      </div>
    </div>
  );
};

export default CustomerContactCard;
