import React, { useCallback, useEffect, useMemo, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionContainer } from "@/layouts/application-layouts/styles";
import { Breadcrumb } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  createQuoteForm,
  createQuoteDefaultValues,
  QuoteFormValues,
} from "@/validations/insurance-quote.schema";
import CarSection from "@/layouts/insurance-layouts/quote-form/CarSection";
import InsuranceSection from "@/layouts/insurance-layouts/quote-form/InsuranceSection";
import CustomerSection from "@/layouts/insurance-layouts/quote-form/CustomerSection";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useApiFetchers } from "@/hooks/use-api-fetchers";
import {
  IDependency,
  IGeneratedQuote,
  IInsuranceOption,
  IPaymentOption,
  IProductOption,
} from "@/layouts/insurance-layouts/quote-form/types";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosError } from "axios";
import { Alert, Snackbar } from "@mui/material";
import useQuoteStore from "@/states/create-quote";
import DealerSection from "@/layouts/insurance-layouts/quote-form/DealerSection";
import useQuoteByIdStore from "@/states/quotes";
import storage from "@/lib/storage";
import { convertToTitleCase } from "@/utils/capitalizeFirstLetter";
import { fetchProducts, fetchProductsById } from "@/hooks/nova-api";

type TCompletedType = { [key: number]: boolean };

const QuoteForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("component");
  const queryParam = new URLSearchParams(window.location.search);
  const quoteId = queryParam.get("quote_id") ?? undefined;
  const isFranchise = storage.utilities.isFranchise();

  const [expanded, setExpanded] = useState<number | false>(0);
  const [completed, setCompleted]: any = useState<TCompletedType>({});
  const [insuranceOptions, setInsuranceOptions] = useState<IInsuranceOption[]>(
    []
  );
  const [paymentOptions, setPaymentOptions] = useState<IPaymentOption[]>([]);
  const [insuranceLoading, setInsuranceLoading] = useState(false);
  const [paymentOptLoading, setPaymentOptLoading] = useState(false);

  const { alert, setAlert } = useQuoteStore();
  const { quotesById, fetchQuotesById } = useQuoteByIdStore();

  useEffect(() => {
    if (quoteId) fetchQuotesById(quoteId);
  }, [fetchQuotesById, quoteId]);

  const handleAccordionChange =
    (panel: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const methods = useForm({
    mode: "all",
    defaultValues: createQuoteDefaultValues,
    resolver: zodResolver(createQuoteForm),
  });

  const watched = methods.watch([
    "searchCar",
    "selectedCarId",
    "makeId",
    "searchDealer",
    "dealerId",
  ]);

  const [debouncedCarLookup] = useDebounce(watched[0], 1000);
  const [debouncedDealerLookup] = useDebounce(watched[3], 1000);
  const insuranceTypevalue = methods.getValues().insuranceType;
  const usageTypevalue = methods.getValues().usageType;
  const carLicenseValue = methods.getValues().carLicense;

  const dependencies: IDependency = {
    carLookup: debouncedCarLookup,
    carId: watched[1],
    makeId: watched[2],
    dealerLookup: debouncedDealerLookup,
    dealerId: watched[4],
  };

  const fetchedData = useApiFetchers(dependencies);

  const props = {
    methods,
    data: fetchedData,
    t,
    insuranceOptions,
    insuranceLoading,
    paymentOptions,
    paymentOptLoading,
    quoteId,
  };

  const Quotes = [
    ...(!isFranchise
      ? [
          {
            id: "dealerDetails",
            label: "Dealer details",
            content: <DealerSection {...props} />,
          },
        ]
      : []),
    {
      id: "carDetails",
      label: "Car details",
      content: <CarSection {...props} />,
    },
    {
      id: "insuranceDetails",
      label: "Insurance details",
      content: <InsuranceSection {...props} />,
    },
    {
      id: "customerDetails",
      label: "Customer details",
      content: <CustomerSection {...props} />,
    },
  ];

  const handleFetchProducts = useCallback(async () => {
    setInsuranceLoading(true);
    try {
      const response = await fetchProducts();
      const parsedResponse = JSON.parse(response?.data);
      if (response) {
        const filteredProduct = parsedResponse?.map((item: IProductOption) => {
          return {
            label: (
              <div className="mt-2">
                <p className="text-sm font-semibold text-[#30345e] mb-0.5">
                  {item.name}
                </p>
                <p className="text-xs text-[#30345E] font-normal">
                  {item.description}
                </p>
              </div>
            ),
            value: item.id,
          };
        });
        setInsuranceOptions(filteredProduct);
        setInsuranceLoading(false);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        setAlert(JSON.parse(err.response?.data).message);
      }
    } finally {
      setInsuranceLoading(false);
    }
  }, [setAlert]);

  const handleFetchProductById = useCallback(async () => {
    setPaymentOptLoading(true);
    try {
      const response = await fetchProductsById(insuranceTypevalue);
      const parsedResponse = JSON.parse(response?.data);
      if (parsedResponse) {
        const filteredData = parsedResponse?.paymentOptions?.map(
          (item: string) => {
            return {
              label: item?.replaceAll("_", " "),
              value: item,
            };
          }
        );
        setPaymentOptions(filteredData);
        setPaymentOptLoading(false);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        setAlert(err?.response?.data?.message);
      }
    } finally {
      setPaymentOptLoading(false);
    }
  }, [insuranceTypevalue, setAlert]);

  useEffect(() => {
    handleFetchProducts();
  }, [handleFetchProducts]);

  useEffect(() => {
    if (insuranceTypevalue) {
      handleFetchProductById();
    }
  }, [handleFetchProductById, insuranceTypevalue]);

  useMemo(() => {
    if (fetchedData?.selectedCar) {
      const _selectedCar = {
        make: fetchedData?.selectedCar?.make,
        model: fetchedData.selectedCar.model,
        year: fetchedData.selectedCar.year,
        exteriorColor: fetchedData.selectedCar.exteriorColor,
        bodyType: fetchedData.selectedCar.bodyType,
        usageType: usageTypevalue,
        estimatedCarPrice: fetchedData.selectedCar.estimatedCarPrice,
        licensePlateNumber: carLicenseValue,
      };
      methods.setValue("inventoryCars", _selectedCar);
      methods.setValue("carId", fetchedData.selectedCar.carId);
    }
  }, [carLicenseValue, fetchedData.selectedCar, methods, usageTypevalue]);

  useMemo(() => {
    methods.setValue("usageType", "private");
    methods.setValue("duration", "12");
  }, [methods]);

  const mappedQuotesData = useCallback(
    (data: IGeneratedQuote): QuoteFormValues => {
      return {
        selectedCarId: data?.customer?.externalRef ?? "",
        insuranceType: data?.product?.id ?? "",
        usageType: data?.vehicle?.usageType ?? "",
        paymentOption: data?.paymentPlan ?? "",
        firstName: data?.customer?.firstName ?? "",
        lastName: data?.customer?.lastName ?? "",
        emailAddress: data?.customer?.emailAddress ?? "",
        phoneNumber: data?.customer?.phoneNumber?.replace(/^234/, "") ?? "",
        whatsappNumber:
          data?.customer?.whatsappNumber?.replace(/^234/, "") ?? "",
        make: data?.vehicle?.make ?? "",
        model: data?.vehicle?.model ?? "",
        year: data?.vehicle?.year?.toString() ?? "",
        price: data?.vehicle?.estimatedCarPrice?.toString() ?? "",
        exteriorColor: data?.vehicle?.exteriorColor ?? "",
        licensePlate: data?.vehicle?.licensePlateNumber ?? "",
        vehicleType: convertToTitleCase(data?.vehicle?.bodyType ?? ""),
        searchCar: "",
        inventoryCars: {
          make: data?.vehicle?.make ?? "",
          model: data?.vehicle?.model ?? "",
          year: data?.vehicle?.year ?? "",
          exteriorColor: data?.vehicle?.exteriorColor ?? "",
          bodyType: convertToTitleCase(data?.vehicle?.bodyType ?? ""),
          usageType: data?.vehicle?.usageType ?? "",
          estimatedCarPrice: Number(data?.vehicle?.estimatedCarPrice),
        },
        makeId:
          fetchedData?.makes?.find(
            (item: any) => item?.label === data?.vehicle?.make
          )?.id ?? "",
        modelId: "",
        carId: data?.customer?.externalRef ?? "",
        duration: data?.coverDuration?.toString() ?? "",
        carLicense: data?.vehicle?.licensePlateNumber ?? "",
        dealerName: data?.referrerData?.name ?? "",
        dealerId: data?.referrerData?.referrerId ?? "",
        dealerEmail: "",
        searchDealer: "",
        dealersData: {
          name: "",
          phoneNumber: "",
          email: "",
        },
        customerId: data?.customer?.id ?? "",
        vehicleId: data?.vehicle?.id ?? "",
      };
    },
    [fetchedData?.makes]
  );

  useEffect(() => {
    if (quoteId) {
      const mappedData = mappedQuotesData(quotesById as IGeneratedQuote);
      methods.reset(mappedData);
    }
  }, [mappedQuotesData, methods, quoteId, quotesById]);

  return (
    <main>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}
      >
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>

      <Breadcrumb
        separator={<RightOutlined style={{ paddingBottom: "5px" }} />}
        className="mb-5 md:mb-0"
      >
        <Breadcrumb.Item
          onClick={() => navigate(-1)}
          className="text-base text-[#30345E] font-semibold cursor-pointer"
        >
          Sell a service
        </Breadcrumb.Item>
        <Breadcrumb.Item className="text-base text-[#30345E] font-semibold hidden md:block">
          Insurance
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="w-full max-w-full md:max-w-[75%] xl:max-w-[50%] mx-auto my-0 flex flex-col gap-8">
        <h1 className="text-2xl text-[#30345E] font-semibold text-center">
          Quote details
        </h1>
        <AccordionContainer>
          {Quotes.map((item, index) => (
            <Accordion
              key={item.id}
              expanded={expanded === index}
              onChange={handleAccordionChange(index)}
            >
              <AccordionSummary
                expandIcon={
                  completed[index] && expanded !== index ? (
                    <CheckCircleIcon sx={{ color: "#22C55E" }} />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <p className="text-lg text-[#30345E] font-semibold">
                  {item.label}
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <form>
                  {item.content}

                  {index !== Quotes.length - 1 && (
                    <button
                      type="button"
                      onClick={() => {
                        setExpanded(index + 1);
                        setCompleted((current: any) => ({
                          ...current,
                          [index]: true,
                        }));
                      }}
                      className="text-base font-semibold bg-[#ffb619] text-[#30345e] w-full max-w-[40%] h-10 rounded-[30px] mt-5"
                    >
                      Next
                    </button>
                  )}
                </form>
              </AccordionDetails>
            </Accordion>
          ))}
        </AccordionContainer>
      </div>
    </main>
  );
};

export default QuoteForm;
