import { useEffect, useCallback, useRef, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import shallow from "zustand/shallow";
import {
  Container,
  Content,
  CustomSelect,
  ForgotPasswordLink,
  Form,
  Inputs,
  Logo,
} from "@p/auth/styles/sign-in";
import RegularInput from "@b/inputs/regular";
import Button from "@c/button";
import asideState from "@s/aside";
import { useSignIn } from "@h/use-authentication";
import storage from "@l/storage";
import authState from "../../states/auth";
import franchiseAdmins from "@cn/franchise-admin.json";
import accountManagers from "@cn/account-manager.json";
import dealerFinanceManagers from "@cn/dealer-finance-manager.json";
import adminRoles from "@cn/admin-roles.json";
import dsaAgents from "@cn/dsa.json";
import dealerDsa from "@cn/dealer-dsa.json";
import { message, Select } from "antd";
import client from "@/lib/axios-client";
import { useTranslation } from "react-i18next";
import { determineRole } from "@/utils/determine-role";
import useGTMTagging from "@/utils/gtmTagging";
import { useMediaQuery } from "@mui/material";
import { GlobalTagContext } from "@/utils/gtmGlobalContext";

const schema = yup
  .object({
    email: yup
      .string()
      .email("Kindly provide a valid email")
      .required("Email field cannot be left blank"),
    password: yup.string().required("There can't be a blank password field"),
  })
  .required();

const SignIn = () => {
  const { i18n } = useTranslation("navigation");
  const { t: t1 } = useTranslation("auth");
  const [lang, setLang] = useState("");
  const queryParam = new URLSearchParams(window.location.search);
  const locale: string = queryParam.get("lang") as string;
  const isMobile = useMediaQuery("(max-width:600px)");

  const { trackEvent } = useGTMTagging();
  const { setExtraPageDataLayer } = useContext(GlobalTagContext);

  const [setVisibility, setCollapsibility] = asideState(
    (state) => [state.setVisibility, state.setCollapsibility],
    shallow
  );

  useEffect(() => {
    setVisibility(false);
    setCollapsibility(false);
  }, [setCollapsibility, setVisibility]);

  useEffect(() => {
    document.title = "Dealer Plus | Sign In";
  }, []);

  const signInParseData = useRef<Keyable>({});
  const navigate = useNavigate();
  const location = useLocation() as IUseLocation;
  const redirectPath = location.state?.path || "/";
  const setLogin = authState((state: IAuthState) => state.setLogin);

  const handleLanguageChange = (value: string) => {
    const params = new URLSearchParams({ lang: value });
    navigate(
      { pathname: location.pathname, search: params.toString() },
      { replace: true }
    );
    i18n.changeLanguage(value);
    setLang(value);
  };

  useEffect(() => {
    i18n.changeLanguage(locale);
    setLang(i18n.language);
  }, [i18n, i18n.language, locale]);

  const {
    mutate: signInMutate,
    isLoading: isSignInLoading,
    isError: isSignInError,
    isSuccess: isSignInSuccess,
    error: signInError,
    data: signInData,
  } = useSignIn();

  const isAllowedRole = (roles: string[], allowedRoles: string[]): boolean => {
    return allowedRoles.some((role) => roles.includes(role));
  };

  const isAllowedAdminProxyRole = (roles: string[]): boolean => {
    const hasAdmin = roles.includes("ADMIN") || roles.includes("admin");
    const hasProxy = roles.includes("PROXY");

    if ((hasAdmin && !hasProxy) || (!hasAdmin && hasProxy)) {
      return false;
    }

    return true;
  };

  const isAllowedCountry = (
    countries: string[],
    allowedCountries: string[]
  ): boolean => {
    return allowedCountries.some((country) => countries.includes(country));
  };

  const fetchFranchiseData = async (roles: string[], userId: string) => {
    const params = new URLSearchParams({
      account_manager_id: roles.includes("ACCOUNT_MANAGER") ? userId : "",
      dsa_id: roles.includes("DEALER_DSA") ? userId : "",
    });

    try {
      const res = await client.get("/v1/franchise", { params });
      const parsedFranchiseData: FranchiseResponse = JSON.parse(res.data);
      storage.setFranchises(parsedFranchiseData.result);
    } catch (error) {
      console.error("Error fetching franchise data:", error);
    }
  };

  const trackLoginData = useCallback(
    (data: {
      email: string;
      role: string;
      userId: string;
      username: string;
      country: string;
    }) => {
      localStorage.setItem("sessionStart", new Date().toString());
      const trackLoginData = {
        user_id: data.userId,
        user_role: data.role,
        user_email: data.email,
        user_country: data.country,
        username: data.username,
        device_type: isMobile ? "mobile" : "desktop",
        env_device: isMobile ? "mobile" : "desktop",
        session_start: new Date().toString(),
      };
      trackEvent("login", trackLoginData);
    },
    [isMobile, trackEvent]
  );

  const processSignIn = useCallback(
    async (data: AxiosResponse) => {
      signInParseData.current = JSON.parse(data.data);

      const roles = signInParseData.current.authority.roles;
      const countries = signInParseData.current.user.country;
      const token = signInParseData.current.token;
      storage.setToken(token);

      const allowedRoles = [
        ...adminRoles,
        ...franchiseAdmins,
        ...dsaAgents,
        ...accountManagers,
        ...dealerFinanceManagers,
        ...dealerDsa,
      ];
      const allowedCountries = ["NG", "KE", "UG", "GH", "CI", "SN", "EG"];

      if (
        isAllowedRole(roles, allowedRoles) &&
        isAllowedAdminProxyRole(roles) &&
        isAllowedCountry(countries, allowedCountries)
      ) {
        const partnerParsedData = JSON.parse(data.data);
        const user = signInParseData.current.user;
        user.partner = partnerParsedData;

        storage.setUser(user);
        storage.setRoles(roles);

        const role = determineRole(roles) as string;

        if (role) storage.setRole(role);

        if (roles.includes("ACCOUNT_MANAGER") || roles.includes("DEALER_DSA")) {
          await fetchFranchiseData(roles, user.id);
        }

        const permissions = signInParseData.current.authority.permissions;
        storage.setPermissions(permissions);

        const country = signInParseData.current.user.country;
        storage.setCountry(country);
        trackLoginData({
          country,
          role,
          email:
            role === "FRANCHISE ADMIN" ? user?.company?.email : user?.email,
          userId: role === "FRANCHISE ADMIN" ? user?.company?.id : user?.id,
          username:
            role === "FRANCHISE ADMIN"
              ? user?.company?.name
              : `${user?.firstname} ${user?.lastname}`,
        });
        setExtraPageDataLayer({
          user_id: role === "FRANCHISE ADMIN" ? user?.company?.id : user?.id,
          user_role: role,
          user_email:
            role === "FRANCHISE ADMIN" ? user?.company?.email : user?.email,
          user_country: country,
          username:
            role === "FRANCHISE ADMIN"
              ? user?.company?.name
              : `${user?.firstname} ${user?.lastname}`,
          device_type: isMobile ? "mobile" : "desktop",
          env_device: isMobile ? "mobile" : "desktop",
          session_start: new Date().toString(),
        });
        navigate(redirectPath, { replace: true });
        setLogin(true);
      } else {
        storage.clearToken();
        if (!isAllowedRole(roles, allowedRoles)) {
          message.error("Unauthorized: Role not supported");
        } else if (!isAllowedCountry(countries, allowedCountries)) {
          message.error("Unauthorized: Country not supported");
        } else {
          message.error("Unauthorized: Access is denied");
        }
      }
    },
    [
      isMobile,
      navigate,
      redirectPath,
      setExtraPageDataLayer,
      setLogin,
      trackLoginData,
    ]
  );

  useEffect(() => {
    isSignInSuccess && processSignIn(signInData);
  }, [signInData, isSignInSuccess, processSignIn]);

  useEffect(() => {
    if (isSignInError) {
      storage.clearToken();
    }
  }, [isSignInError]);

  useEffect(() => {
    const user = storage.getUser();
    user && navigate(redirectPath, { replace: true });
  });

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<ISignInInput>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    isSignInError &&
      signInError instanceof AxiosError &&
      message.error(JSON.parse(signInError.response?.data).message);
    isSignInError &&
      signInError instanceof AxiosError &&
      resetField("password");
  }, [isSignInError, resetField, signInError]);

  const handleSignIn = (data: ISignInInput) => {
    signInMutate({ ...data });
  };

  return (
    <Container>
      <Content>
        <Logo>
          <img
            alt="Autochek's Logo"
            src="https://media.autochek.africa/file/publicAssets/logo-horizontal-1.svg"
          />
        </Logo>
        <Form onSubmit={handleSubmit(handleSignIn)} autoComplete="off">
          <Inputs>
            <RegularInput
              label={t1("email")}
              type="email"
              placeholder={t1("email-address")}
              {...register("email")}
              error={errors.email}
            />

            <RegularInput
              label={t1("password")}
              type="password"
              placeholder={t1(`password`)}
              {...register("password")}
              error={errors.password}
            />
          </Inputs>

          <div style={{ marginTop: "48px" }}>
            <Button
              isLoading={isSignInLoading}
              variant="secondary"
              width="w-full"
              style={{ borderRadius: "60px" }}
            >
              {t1("login")}
            </Button>
          </div>

          <ForgotPasswordLink onClick={() => navigate("/auth/forgot-password")}>
            {t1("forgot-password")}?
          </ForgotPasswordLink>
        </Form>
      </Content>
      <CustomSelect>
        <div className="language-img">
          <img
            src="https://media.autochek.africa/file/publicAssets/icons8-language.svg"
            alt="language-img"
          />
        </div>
        <Select
          onChange={handleLanguageChange}
          value={lang}
          options={[
            { value: "en", label: "English" },
            { value: "fr", label: "Français" },
            { value: "ar", label: "عربي" },
          ]}
        />
      </CustomSelect>
    </Container>
  );
};

export default SignIn;
