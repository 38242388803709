import React from "react";

type BookLeadBtnProps = {
  onHandleSubmit?: () => void;
};

const BookLeadButton: React.FC<BookLeadBtnProps> = ({
  onHandleSubmit = () => {},
}) => {
  return (
    <button
      onClick={onHandleSubmit}
      className="text-sm md:text-base text-[#30345E] border border-solid border-[#30345E] font-semibold w-fit md:w-full px-5 xl:px-4 h-11 md:h-12 bg-white rounded-full"
    >
      <span className="hidden md:block">Book Lead</span>{" "}
      <span className="block md:hidden">Book</span>
    </button>
  );
};

export default BookLeadButton;
