import create from "zustand";
import storage from "@/lib/storage";
import { fetchDirectDebitStatus } from "@/hooks/nova-api";
import { IDirectDebits } from "@/interface/quotations";

interface DirectDebitStore {
  directDebitLoading: boolean;
  directDebitStatus: IDirectDebits | undefined;
  fetchDirectDebitStatus: (id: string) => Promise<void>;
}

const useDirectDebitStore = create<DirectDebitStore>((set) => ({
  directDebitLoading: false,
  directDebitStatus: undefined,
  fetchDirectDebitStatus: async (customerId: string) => {
    const country = storage.getCountry();
    const params = new URLSearchParams({
      country,
      customerId,
    });
    try {
      set({ directDebitLoading: true });
      const response = await fetchDirectDebitStatus(params);
      const parsedResponse = JSON.parse(response?.data);
      set({
        directDebitStatus: parsedResponse,
        directDebitLoading: false,
      });
    } catch (err: any) {
      set({ directDebitLoading: false });
    }
  },
}));

export default useDirectDebitStore;
