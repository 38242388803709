import { IProfileProps } from "@/interface/car-leads";
import { z } from "zod";

// Define validation schema using Zod
export const profileFormSchema = z.object({
  dealerName: z.string().min(1, "Dealer name is required"),
  dealershipName: z.string().min(1, "Dealership name is required"),
  email: z
    .string({
      required_error: "Enter the email address",
    })
    .min(1, "Enter a valid email address"),
  phoneNumber: z.string().min(1, "Phone number is required"),
  whatsappNumber: z.string().optional(),
  displayPhoneNumber: z.string().optional(),
  extraDisplayNumber: z.string().optional(),
  instagramHandle: z
    .string()
    .optional()
    .refine(
      (val) =>
        !val ||
        /^\s*(https:\/\/(www\.)?instagram\.com\/[a-z0-9._-]+(\/[a-z0-9._-]*)?\s*)$/i.test(
          val
        ),
      {
        message: "Enter a valid Facebook profile URL",
      }
    ),
  facebookHandle: z
    .string()
    .optional()
    .refine(
      (val) =>
        !val ||
        /^\s*(https:\/\/(www\.)?facebook\.com\/[a-z0-9._-]+(\/[a-z0-9._-]*)?\s*)$/i.test(
          val
        ),
      {
        message: "Enter a valid Facebook profile URL",
      }
    ),
  addressName: z.string().optional(),
  address: z.string().min(1, "Address is required"),
});

export const validateProfileForm = (values: Record<string, any>) => {
  let schema = profileFormSchema;
  try {
    schema.parse(values);
    return true;
  } catch (e) {
    return false;
  }
};

export const profileDefaultValues: IProfileProps = {
  dealerName: "",
  dealershipName: "",
  email: "",
  phoneNumber: "",
  whatsappNumber: "",
  displayPhoneNumber: "",
  extraDisplayNumber: "",
  instagramHandle: "",
  facebookHandle: "",
  addressName: "",
  latitude: 0,
  longitude: 0,
  address: "",
};
