import { Avatar, Skeleton } from "@mui/material";
import { Card } from "antd";

export default function LoadingCarCard() {
  return (
    <Card
      cover={
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{
            borderRadius: "8px 8px 0px 0px",
          }}
        />
      }
      bordered={true}
    >
      <div className="pb-3">
        <Skeleton variant="text" sx={{ fontSize: "24px" }} />
        <div className="flex gap-2">
          <Skeleton
            variant="rectangular"
            width={69}
            height={32}
            sx={{
              borderRadius: "40px",
            }}
          />
          <Skeleton
            variant="rectangular"
            width={80}
            height={32}
            sx={{
              borderRadius: "40px",
            }}
          />
          <Skeleton
            variant="rectangular"
            width={120}
            height={32}
            sx={{
              borderRadius: "40px",
            }}
          />
        </div>
        <p className="flex items-center space-x-1 pt-1">
          <Skeleton variant="text" width={199} sx={{ fontSize: "16px" }} />
        </p>
      </div>

      <div className="flex justify-between pt-2 border-t mb-2">
        <Skeleton variant="text" width={89} sx={{ fontSize: "16px" }} />
        <Skeleton variant="text" width={151} sx={{ fontSize: "22px" }} />
      </div>

      <div className="cta-container">
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={48}
          sx={{
            borderRadius: "40px",
          }}
        />
      </div>
    </Card>
  );
}

export const LoadingMonthPerf = () => {
  return (
    <div className="bg-white p-4 rounded-md flex flex-col gap-2 min-h-32 h-auto py-4">
      <div className="flex items-center text-gray-500 gap-2">
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
        <Skeleton height={16} width="50%" />
      </div>
      <h2 className="font-extrabold text-lg text-pages-loan-detail-heading">
        <Skeleton height={20} width="100%" />
      </h2>
      <p className="text-green-500 md:text-base text-xs ">
        <Skeleton height={18} width="100%" />
      </p>
    </div>
  );
};

export const LoadingAboutDealer = () => {
  return (
    <div className="flex flex-col gap-4 bg-white rounded-xl p-6 w-full h-auto">
      <div className="flex items-center gap-2">
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
        <Skeleton height={20} width="100%" />
      </div>
      <div className="">
        <Skeleton height={20} width="100%" />
        <Skeleton height={20} width="100%" />
        <Skeleton height={20} width="100%" />
        <Skeleton height={20} width="80%" />
      </div>
      <div>
        <Skeleton height={20} width="100%" />
        <Skeleton height={20} width="100%" />
        <Skeleton height={20} width="80%" />
      </div>
    </div>
  );
};

export const LoadingInsuranceCard = () => {
  return (
    <div className="border border-solid border-[#E5E7EB] bg-white rounded-lg px-3 py-3.5 divide-y divide-[#E5E7EB] md:cursor-pointer">
      <div className="pb-3.5 md:pb-3 flex flex-col gap-3.5">
        <div className="flex items-center justify-between">
          <Skeleton
            variant="rectangular"
            width={86}
            height={24}
            sx={{
              borderRadius: "4px",
            }}
          />
          <Skeleton
            variant="rectangular"
            width={120}
            height={24}
            sx={{
              borderRadius: "4px",
            }}
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <Skeleton variant="text" width={"50%"} sx={{ fontSize: "16px" }} />
          <div className="grid grid-cols-1 gap-y-1.5 md:gap-y-2 md:gap-x-16">
            <div>
              <Skeleton variant="text" width="100%" sx={{ fontSize: "12px" }} />
              <Skeleton variant="text" width="100%" sx={{ fontSize: "12px" }} />
            </div>
            <div className="">
              <Skeleton variant="text" width="100%" sx={{ fontSize: "12px" }} />
              <Skeleton variant="text" width="100%" sx={{ fontSize: "12px" }} />
            </div>
            <div className="text-right">
              <Skeleton variant="text" width="100%" sx={{ fontSize: "12px" }} />
              <Skeleton variant="text" width="100%" sx={{ fontSize: "12px" }} />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4 md:pt-3.5 flex flex-col gap-2.5">
        <Skeleton
          variant="rectangular"
          width={98}
          height={24}
          sx={{
            borderRadius: "4px",
          }}
        />
        <div className="flex items-center gap-2">
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
          <div className="w-full">
            <Skeleton height={20} width="100%" />
            <Skeleton height={20} width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export const LoadingCarLeadCard = () => {
  return (
    <div className="border border-solid border-[#E5E7EB] bg-white rounded-lg px-3 py-3.5 divide-y divide-[#E5E7EB] md:cursor-pointer">
      <div className="flex items-start gap-2 pb-2">
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
        <div className="flex-1">
          <Skeleton variant="text" width="100%" sx={{ fontSize: "12px" }} />
          <Skeleton variant="text" width="100%" sx={{ fontSize: "12px" }} />
        </div>
      </div>

      <div className="flex flex-col gap-3 pt-3 pb-4">
        <div className="flex items-center gap-6">
          <div className="flex-1">
            <div className="flex items-center gap-2">
              <Skeleton
                variant="rectangular"
                width={100}
                height={64}
                sx={{
                  borderRadius: "4px",
                }}
              />
              <Skeleton variant="text" width="100%" sx={{ fontSize: "12px" }} />
            </div>
          </div>
          <Skeleton variant="text" width="15%" sx={{ fontSize: "12px" }} />
        </div>
        <div className="flex items-center gap-2 md:gap-2.5">
          <Skeleton
            variant="rectangular"
            width={126}
            height={24}
            sx={{
              borderRadius: "20px",
            }}
          />
          <Skeleton
            variant="rectangular"
            width={126}
            height={24}
            sx={{
              borderRadius: "20px",
            }}
          />
        </div>
      </div>

      <div className="pt-4">
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={48}
          sx={{
            borderRadius: "40px",
          }}
        />
      </div>
    </div>
  );
};

export const LoadingDealerCard = () => {
  return (
    <div className="border border-solid border-[#E5E7EB] bg-white rounded-lg px-3 py-3.5 divide-y divide-[#E5E7EB] md:cursor-pointer">
      <div className="flex items-start gap-2 pb-2">
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
        <div className="flex-1">
          <Skeleton variant="text" width="100%" sx={{ fontSize: "16px" }} />
          <Skeleton variant="text" width="100%" sx={{ fontSize: "16px" }} />
          <Skeleton variant="text" width="100%" sx={{ fontSize: "16px" }} />
        </div>
      </div>

      <div className="pt-4">
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={48}
          sx={{
            borderRadius: "40px",
          }}
        />
      </div>
    </div>
  );
};
