import React from "react";
import { Modal } from "antd";
import { titleStyle } from "@/layouts/application-layouts/styles";

type ModalVariantProps = {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  title?: string | React.ReactNode;
  content: React.ReactNode;
  maskClosable?: boolean;
};

const ModalVariant: React.FC<ModalVariantProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  title,
  content,
  maskClosable,
}) => {
  return (
    <Modal
      title={<div style={titleStyle}>{title}</div>}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      zIndex={1007}
      footer={null}
      closeIcon={
        <img
          src="https://media.autochek.africa/file/publicAssets/x-close-u.svg"
          alt="close-icon"
          style={{ marginTop: 8 }}
        />
      }
      maskClosable={maskClosable ?? true}
    >
      {content}
    </Modal>
  );
};

export default ModalVariant;
