import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { checkVehicleErrors } from "@/utils/process-form-data";
import { useNavigate } from "react-router-dom";
import { IHandleActionOptions } from "@/types";

interface IFormButton {
  loading: boolean;
  data: any;
  fields: any;
  index: number;
  setAlert: (error: string) => void;
  setCompleted: React.Dispatch<React.SetStateAction<any>>;
  setExpanded: (index: number) => void;
  loanId?: string;
  label: string;
  productData: any;
  submit: (e: any, isDraft: boolean) => void;
}

export default function VehicleActionButton({
  loading,
  data,
  fields,
  productData,
  index,
  setAlert,
  setCompleted,
  setExpanded,
  label,
  loanId,
  submit,
}: Readonly<IFormButton>) {
  const navigate = useNavigate();

  const handlePreferenceNextAction = async (
    e: any,
    {
      data,
      fields,
      index,
      productData,
      setAlert,
      setCompleted,
      setExpanded,
    }: IHandleActionOptions
  ) => {
    const error = checkVehicleErrors(data, fields);
    if (error) {
      setAlert(error);
      setCompleted((current: any) => ({
        ...current,
        [index]: false,
      }));
      return;
    }
    if (!loanId) {
      setExpanded(
        productData?.config?.sections?.length +
          productData?.config?.preference?.length +
          productData?.config?.collateral?.length +
          index +
          1
      );
      setCompleted((current: any) => ({
        ...current,
        [productData?.config?.sections?.length +
        productData?.config?.preference?.length +
        productData?.config?.collateral?.length +
        index]: true,
      }));
      submit(e, true);
    } else {
      await submit(e, true);
      navigate(`/dealer/applications/loans/${loanId}`);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      type="button"
      fullWidth
      color="custom"
      style={{ fontWeight: 600 }}
      loading={loading}
      onClick={(e) =>
        handlePreferenceNextAction(e, {
          data,
          fields,
          index,
          productData,
          setAlert,
          setCompleted,
          setExpanded,
        })
      }
    >
      {label}
    </LoadingButton>
  );
}
