import React, { useState } from "react";
import ProfileForm from "@/components/ProfileForm";
import AccountManagerForm from "@/components/AccountManagerForm";
import tw from "tailwind-styled-components";
import storage from "@/lib/storage";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import PageLoader from "@/layouts/application-layouts/PageLoader";
import { TFranchiseDataType } from "@/interface/car-leads";
import { useLocation, useNavigate } from "react-router-dom";

interface IAllForms {
  [key: string]: React.ReactNode;
}

const NavButton = tw.button`h-[44px] rounded-md text-[#30345e] text-sm md:text-base font-semibold flex flex-row items-center justify-center md:justify-start md:px-4 md:py-2 w-full max-w-full`;

export default function SettingsPage() {
  const user = storage.getUser();
  const location = useLocation();
  const isFranchise = storage.utilities.isFranchise();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const getDealerId = queryParams.get("dealerId");
  const dealerId = getDealerId ?? user?.company?.id;

  const [selectedValue, setSelectedValue] = useState<string>("profile");
  const [franchiseData, setFranchiseData] = useState<TFranchiseDataType>();

  const handleSelectedNav = (value: string) => {
    setSelectedValue(value);
  };

  const { refetch, isFetching } = useQuery({
    queryKey: ["get_franchise", dealerId],
    queryFn: () => {
      return client.get(`/v1/franchise/${dealerId}`);
    },
    onSuccess: (res: any) => {
      const parsedFranchise = JSON.parse(res?.data);
      setFranchiseData(parsedFranchise);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const allForms: IAllForms = {
    profile: (
      <ProfileForm
        franchiseData={franchiseData!}
        refetch={refetch}
        dealerId={dealerId}
      />
    ),
    accountManager: <AccountManagerForm franchiseData={franchiseData!} />,
  };

  const formNav = [
    {
      value: "profile",
      label: "My Profile",
    },
    {
      value: "accountManager",
      label: "My Account Manager",
    },
  ];

  if (isFetching) {
    return <PageLoader />;
  }

  return (
    <main className="flex flex-col gap-6 w-full max-w-full xl:max-w-[70%] my-0 mx-auto">
      {isFranchise ? (
        <div>
          <h2 className="text-2xl text-[#30345E] font-semibold">Settings</h2>
          <p className="text-sm text-[#64748B] font-normal">
            Manage your account settings.
          </p>
        </div>
      ) : (
        <div className="flex items-center gap-2">
          <button onClick={() => navigate(-1)}>
            <img
              src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
              alt="left-arrow"
            />
          </button>
          <div>
            <h2 className="text-2xl text-[#30345E] font-semibold">
              Update your dealer profile
            </h2>
            <p className="text-sm text-[#64748B] font-normal">
              You can update your dealers profile using this page
            </p>
          </div>
        </div>
      )}
      <div className="flex flex-col md:flex-row gap-6 xl:gap-7 w-full max-w-full bg-white border border-solid border-[#E2E8F0] rounded-lg p-4 md:p-8">
        <div className="flex flex-row md:flex-col md:gap-3 w-full max-w-full md:max-w-[25%]">
          {formNav.map((item) => (
            <NavButton
              key={item.value}
              onClick={() => handleSelectedNav(item.value)}
              className={
                item.value === selectedValue ? "bg-[#eeefff]" : "bg-white"
              }
            >
              {item.label}
            </NavButton>
          ))}
        </div>

        <div className="w-full max-w-full flex-1">
          {allForms[selectedValue]}
        </div>
      </div>
    </main>
  );
}
