import React, { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchFranchise } from "@/hooks/use-api";
import storage from "@/lib/storage";
import { CustomList } from "@/layouts/application-layouts/styles";
import { LocationPin, MailIconVariant, PhoneVariantIcon } from "@/assets/svgs";
import { useLocation, useNavigate } from "react-router-dom";
import { IActivePage, IDealer, IDealerListResponse } from "@/interface/dealer";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import queryString from "query-string";
import { itemRender } from "@/utils/listItemRender";
import { useTranslation } from "react-i18next";
import getInitial from "@/utils/get-initial";
import { Pagination, PaginationProps, List, message } from "antd";
import { LoadingDealerCard } from "../LoadingCarCard";
import GridEmptyCard from "../GridEmptyCard";

const DealerListModule = () => {
  const country = storage.getCountry();
  const isAccountManager = storage.utilities.isAccountManager();
  const user = storage.getUser();
  const navigate = useNavigate();
  const { search, pathname, hash } = useLocation();
  const { t } = useTranslation("dashboard");

  const [fetchDealers, setFetchDealers] = useState({} as IDealerListResponse);
  const [activePage, setActivePage] = useState<IActivePage>({
    currentPage: 1,
    pageSize: 10,
  });
  const [current, setCurrent] = useState(1);

  const query = useMemo(() => queryString.parse(search), [search]);

  const { isFetching: loadDealers } = useQuery({
    queryKey: ["fetch_dealer_list", user?.id],
    queryFn: () => {
      const params = new URLSearchParams({
        country_code: country,
        page_size: "500",
        account_manager_id: user?.id,
        ...query,
      });

      return fetchFranchise(params);
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      setFetchDealers(parsedResponse);
    },
    onError: (err: any) => {
      const parsedErr = JSON.parse(err?.response?.data);
      message.error(parsedErr?.message ? parsedErr?.message : parsedErr?.error);
    },
    enabled: isAccountManager,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handlePageChange: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrent(page);
    navigate(
      `${pathname}${paramsObjectToQueryString({
        ...query,
        page: page,
        pageSize: pageSize,
      })}${hash || ""}`
    );
  };

  const renderContent = (item: IDealer) => {
    return (
      <section className="p-2.5 mb-5 md:mb-0 md:py-6 md:px-4 bg-white border border-solid border-[#E5E7EB] rounded-lg divide-y divide-[#E5E7EB]">
        <div className="flex gap-2 pb-3">
          <div>
            <p className="text-sm md:text-lg font-semibold font-outfit uppercase text-[#30345E] !w-10 !h-10 rounded-full bg-[#CCDDFF] flex items-center justify-center">
              {getInitial(item?.name)}
            </p>
          </div>

          <div>
            <p className="text-base md:text-lg text-[#202020] font-semibold leading-relaxed capitalize mb-2">
              {item?.name ?? "---"}
            </p>
            <div className="flex flex-col gap-1.5">
              <div className="flex items-center gap-1.5">
                <div>
                  <LocationPin color="#202020" />
                </div>
                <p className="text-sm md:text-base text-[#202020] font-normal">
                  {item?.address ?? "---"}
                </p>
              </div>
              <p className="text-sm md:text-base text-[#202020] font-normal flex items-center gap-1.5">
                <MailIconVariant color="#202020" /> {item?.email ?? "---"}
              </p>
              <p className="text-sm md:text-base text-[#202020] font-normal flex items-center gap-1">
                <PhoneVariantIcon color="#202020" />{" "}
                {item?.phonenumber ?? "---"}
              </p>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <button
            onClick={() => navigate(`/settings?dealerId=${item?.id}`)}
            className="text-base text-[#30345E] font-semibold w-full h-11 md:h-12 bg-[#FFB619] rounded-full"
          >
            Update profile
          </button>
        </div>
      </section>
    );
  };

  return (
    <main className="flex flex-col gap-6">
      <div className="flex items-center gap-2">
        <button onClick={() => navigate(-1)}>
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
            alt="left-arrow"
          />
        </button>
        <div>
          <h1 className="text-lg md:text-xl text-[#30345E] font-semibold">
            Update your dealer profile
          </h1>
          <p className="text-sm text-[#64748B] font-medium">
            List of your dealers pending profile update
          </p>
        </div>
      </div>

      <div className="hidden md:block">
        {loadDealers ? (
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
            {Array.from({ length: 12 }, (_, index) => (
              <div key={index}>
                <LoadingDealerCard />
              </div>
            ))}
          </div>
        ) : (
          <React.Fragment>
            {fetchDealers?.result?.length > 0 ? (
              <section className="flex flex-col gap-6">
                <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-4">
                  {fetchDealers?.result?.map((item) => (
                    <div key={item?.id}>{renderContent(item)}</div>
                  ))}
                </div>
                <div className="flex justify-center w-full">
                  <Pagination
                    current={current}
                    onChange={handlePageChange}
                    defaultPageSize={12}
                    total={fetchDealers?.pagination?.total}
                    showSizeChanger={false}
                  />
                </div>
              </section>
            ) : (
              <GridEmptyCard text="No dealers available" />
            )}
          </React.Fragment>
        )}
      </div>

      <CustomList className="block md:hidden">
        <List
          bordered={false}
          loading={loadDealers}
          dataSource={fetchDealers?.result ?? []}
          pagination={{
            current: activePage?.currentPage,
            pageSize: activePage?.pageSize,
            total: fetchDealers?.pagination?.total,
            simple: true,
            onChange(page, pageSize) {
              setActivePage({
                ...activePage,
                currentPage: page,
              });
              navigate(
                `${pathname}${paramsObjectToQueryString({
                  ...query,
                  current_page: page,
                  page_size: pageSize,
                })}`
              );
            },
            itemRender: itemRender(t),
          }}
          renderItem={(item) => renderContent(item)}
        />
      </CustomList>
    </main>
  );
};

export default DealerListModule;
