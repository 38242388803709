import React, { useState } from "react";
import DealerLeadCard from "./DealerLeadCard";
import { Pagination, PaginationProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import ModalVariant from "@/components/blocks/modals/ModalVariant";
import useLeadModalStore from "@/states/leadsModal";
import BookLeadsContent from "@/components/dealerLeads/BookLeadsContent";
import SalesCompletedContent from "@/components/dealerLeads/SalesCompletedContent";
import useLeadDrawerStore from "@/states/leadsDrawer";
import DrawerVariant from "@/components/blocks/modals/DrawerVariant";
import { ICarLeadProps, ICarLeadsResp } from "@/interface/car-leads";
import { LoadingCarLeadCard } from "@/components/LoadingCarCard";
import GridEmptyCard from "@/components/GridEmptyCard";

interface DealerLeadProps {
  query: any;
  tabValue: string;
  data: ICarLeadsResp;
  loading: boolean;
}

const DealerLeadGrid: React.FC<DealerLeadProps> = ({
  query,
  tabValue,
  data,
  loading,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const [leadData, setLeadData] = useState({} as ICarLeadProps);

  const {
    isBookLeadOpen,
    openBookLeadModal,
    closeBookLeadModal,
    isSalesOpen,
    openSalesModal,
    closeSalesModal,
  } = useLeadModalStore();

  const {
    isBookLeadDrawerOpen,
    closeBookLeadDrawer,
    isSalesDrawerOpen,
    closeSalesDrawer,
  } = useLeadDrawerStore();

  const handlePageChange: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrent(page);
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        page: page,
        pageSize: pageSize,
      })}${location.hash || ""}`
    );
  };

  return (
    <div>
      {data?.carLeads?.length > 0 ? (
        <React.Fragment>
          {loading ? (
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
              {Array.from({ length: 12 }, (_, index) => (
                <div key={index}>
                  <LoadingCarLeadCard />
                </div>
              ))}
            </div>
          ) : (
            <section className="flex flex-col gap-6">
              <div className="py-4 md:py-5 flex flex-col gap-4">
                <h2 className="text-base md:text-xl text-[#30345E] font-semibold">
                  {data?.pagination?.total}{" "}
                  {tabValue === "closedLeads" ? "closed" : tabValue} leads found
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-3 xl:gap-5">
                  {data?.carLeads?.map((item) => (
                    <DealerLeadCard
                      key={item.id}
                      tabValue={tabValue}
                      item={item}
                      setLeadData={setLeadData}
                    />
                  ))}
                </div>
              </div>

              <div className="flex justify-center w-full">
                <Pagination
                  current={current}
                  onChange={handlePageChange}
                  defaultPageSize={12}
                  total={data.pagination.total}
                  showSizeChanger={false}
                />
              </div>
            </section>
          )}
        </React.Fragment>
      ) : (
        <GridEmptyCard text="No leads available" />
      )}

      <ModalVariant
        isModalOpen={isBookLeadOpen}
        handleCancel={closeBookLeadModal}
        handleOk={openBookLeadModal}
        title="Book Lead"
        content={
          <BookLeadsContent
            closeBookLeadModal={closeBookLeadModal}
            data={leadData}
          />
        }
      />
      <ModalVariant
        isModalOpen={isSalesOpen}
        handleCancel={closeSalesModal}
        handleOk={openSalesModal}
        title="Sale Completed"
        content={
          <SalesCompletedContent
            closeSalesModal={closeSalesModal}
            data={leadData}
          />
        }
      />
      <DrawerVariant
        open={isBookLeadDrawerOpen}
        onClose={closeBookLeadDrawer}
        placement="bottom"
        title="Book Lead"
        content={
          <BookLeadsContent
            closeBookLeadModal={closeBookLeadDrawer}
            data={leadData}
          />
        }
      />
      <DrawerVariant
        open={isSalesDrawerOpen}
        onClose={closeSalesDrawer}
        placement="bottom"
        title="Sale Completed"
        content={
          <SalesCompletedContent
            closeSalesModal={closeSalesDrawer}
            data={leadData}
          />
        }
      />
    </div>
  );
};

export default DealerLeadGrid;
