import React, { useState } from "react";
import { IInsuranceFormProps } from "./types";
import { Controller } from "react-hook-form";
import { Button, FormLabel, InputAdornment, TextField } from "@mui/material";
import {
  FormStyledBtn,
  InputFieldStyle,
  InputLabelStyle,
} from "@/layouts/application-layouts/styles";
import { validateQuoteForm } from "@/utils/validate-quote-form";
import useQuoteStore from "@/states/create-quote";
import { getCountryTelCodeLength } from "@/utils/getCountryTelCodeLength";
import storage from "@/lib/storage";
import { getCountryTelCode } from "@/utils/getCountryTelCode";
import { QuoteFormValues } from "@/validations/insurance-quote.schema";
import { Loader } from "@/pages/auth/styles/loader";
import { useNavigate } from "react-router-dom";
import { createQuotation, updateQuotation } from "@/hooks/nova-api";

const CustomerSection: React.FC<IInsuranceFormProps> = ({
  methods,
  quoteId,
}) => {
  const country = storage.getCountry();
  const navigate = useNavigate();
  const errors = { ...methods.formState.errors } as Record<string, any>;
  const formValues = methods.watch();
  const { step } = useQuoteStore();
  const isValid = validateQuoteForm(formValues, step);
  const disabled = !isValid;
  const isAccountManager = storage.utilities.isAccountManager();
  const user = storage.getUser();
  const isFranchise = storage.utilities.isFranchise();
  const isDealerDSA = storage.utilities.isDealerDsa();
  const isDsaAgent = storage.utilities.isDsaAgent();

  const [loading, setLoading] = useState(false);
  const { setAlert } = useQuoteStore();

  const getSource = () => {
    if (isFranchise) return "DEALER";
    if (isAccountManager) return "ACCOUNT_MANAGER";
    if (isDealerDSA) return "DEALER_DSA";
    if (isDsaAgent) return "INDEPENDENT_DSA";
  };

  const getSourceData = () => {
    if (isFranchise) {
      return {
        name: user?.company?.name,
        sourceId: user?.company?.id,
        email: user.company.email,
      };
    }
    if (isAccountManager) {
      return {
        name: `${user.firstname} ${user.lastname}`,
        sourceId: user?.id,
        email: user.email,
      };
    }
    if (isDealerDSA) {
      return {
        name: `${user.firstname} ${user.lastname}`,
        sourceId: user?.id,
        email: user.email,
      };
    }
    if (isDsaAgent) {
      return {
        name: `${user.firstname} ${user.lastname}`,
        sourceId: user?.id,
        email: user.email,
      };
    }
  };

  const preparePayload = (values: QuoteFormValues) => {
    const vehicleDetail = {
      make: values.make,
      model: values.model,
      year: values.year,
      exteriorColor: values.exteriorColor,
      bodyType: values.vehicleType,
      licensePlateNumber: values.licensePlate,
      usageType: values.usageType,
      estimatedCarPrice: parseInt(values.price.replaceAll(",", "")),
      ...(quoteId && { id: values.vehicleId }),
    };

    const payload = {
      customer: {
        firstName: values.firstName,
        lastName: values.lastName,
        emailAddress: values.emailAddress,
        phoneNumber: `${getCountryTelCode(country)}${values.phoneNumber}`,
        whatsappNumber: `${getCountryTelCode(country)}${values.whatsappNumber}`,
        ...(quoteId && { id: values.customerId }),
        ...(step === 1 && { externalRef: values.carId }),
      },
      vehicle:
        step === 1
          ? {
              ...values.inventoryCars,
              ...(quoteId && { id: values.vehicleId }),
            }
          : vehicleDetail,
      productId: values.insuranceType,
      paymentPlan: values.paymentOption,
      coverDuration: values.duration,
      country: country,
      source: getSource(),
      sourceData: getSourceData(),
      ...(isAccountManager && {
        referrer: "DEALER",
        referrerData: {
          name: values.dealerName,
          referrerId: values.dealerId,
          email: values.dealerEmail,
        },
      }),
      isDraft: true,
      creatorId: isFranchise ? user?.company?.id : user?.id,
    };

    return payload;
  };

  const handleClick = async () => {
    setLoading(true);
    const data = preparePayload(formValues);
    try {
      const response = await (quoteId
        ? updateQuotation(quoteId, data)
        : createQuotation(data));
      if (response) {
        const parsedResponse = JSON.parse(response?.data);
        navigate(`/insurance/quote-completed/${parsedResponse.id}`);
      }
    } catch (error: any) {
      setAlert(JSON.parse(error.response?.data).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <section className="pt-2 pb-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <FormLabel sx={InputLabelStyle}>First name</FormLabel>
          <Controller
            name="firstName"
            control={methods.control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type="text"
                placeholder="Enter first name"
                onChange={field.onChange}
                sx={InputFieldStyle}
              />
            )}
          />
          {errors?.firstName?.message && (
            <span className="text-red-600 text-sm">
              {errors?.firstName?.message}
            </span>
          )}
        </div>
        <div className="space-y-2">
          <FormLabel sx={InputLabelStyle}>Last name</FormLabel>
          <Controller
            name="lastName"
            control={methods.control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type="text"
                placeholder="Enter last name"
                onChange={field.onChange}
                sx={InputFieldStyle}
              />
            )}
          />
          {errors?.lastName?.message && (
            <span className="text-red-600 text-sm">
              {errors?.lastName?.message}
            </span>
          )}
        </div>
        <div className="space-y-2">
          <FormLabel sx={InputLabelStyle}>Email address</FormLabel>
          <Controller
            name="emailAddress"
            control={methods.control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type="email"
                placeholder="Enter email address"
                onChange={field.onChange}
                sx={InputFieldStyle}
                inputProps={{
                  type: "email",
                }}
              />
            )}
          />
          {errors?.emailAddress?.message && (
            <span className="text-red-600 text-sm">
              {errors?.emailAddress?.message}
            </span>
          )}
        </div>
        <div className="space-y-2">
          <FormLabel sx={InputLabelStyle}>Phone number</FormLabel>
          <Controller
            name="phoneNumber"
            control={methods.control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type="text"
                placeholder="Enter phone number"
                sx={InputFieldStyle}
                inputProps={{
                  maxLength: getCountryTelCodeLength(country),
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCountryTelCode(country)}
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  const numericValue = event.target.value.replace(/\D/g, "");
                  field.onChange(numericValue);
                }}
                onBeforeInput={(event: any) => {
                  if (!/^\d*$/.test(event.data)) {
                    event.preventDefault();
                  }
                }}
              />
            )}
          />
          {errors?.phoneNumber?.message && (
            <span className="text-red-600 text-sm">
              {errors?.phoneNumber?.message}
            </span>
          )}
        </div>
        <div className="space-y-2">
          <FormLabel sx={InputLabelStyle}>Whatsapp number</FormLabel>
          <Controller
            name="whatsappNumber"
            control={methods.control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type="text"
                placeholder="Enter whatsapp number"
                inputProps={{
                  maxLength: getCountryTelCodeLength(country),
                }}
                sx={InputFieldStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCountryTelCode(country)}
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  const numericValue = event.target.value.replace(/\D/g, "");
                  field.onChange(numericValue);
                }}
                onBeforeInput={(event: any) => {
                  if (!/^\d*$/.test(event.data)) {
                    event.preventDefault();
                  }
                }}
              />
            )}
          />
          {errors?.whatsappNumber?.message && (
            <span className="text-red-600 text-sm">
              {errors?.whatsappNumber?.message}
            </span>
          )}
        </div>
      </section>

      <Button
        className="w-full max-w-[100%] my-0 mx-auto disabled:cursor-not-allowed"
        variant="contained"
        sx={FormStyledBtn}
        onClick={handleClick}
        disabled={disabled}
      >
        {loading ? <Loader variant="secondary" /> : "Generate Quote"}
      </Button>
    </main>
  );
};

export default CustomerSection;
