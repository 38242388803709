import Dashboard from "@/pages/dashboard";
import RequireAuthentication from "@c/require-authentication";
import history from "@l/history";
import storage from "@l/storage";
import AdaptiveAsideLayout from "@lo/adaptive-aside";
import ForgotPassword from "@p/auth/forgot-password";
import ResetPassword from "@p/auth/reset-password";
import SignIn from "@p/auth/sign-in";
import VerifyLink from "@p/auth/verify-link";
import NotFound from "@p/errors/not-found";
import Redirect from "@p/redirect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import shallow from "zustand/shallow";
import Applications from "./pages/applications";
import InventoryDetails from "./pages/applications/[carId]";
import LoanDetails from "./pages/applications/[id]";
import CreateCarForCashForm from "./pages/applications/car-for-cash-form";
import CreateCarForm from "./pages/applications/car-form";
import CarFound from "./pages/applications/car-found";
import CarList from "./pages/applications/car-list";
import Confirmation from "./pages/applications/confirmation";
import DealDetails from "./pages/applications/deal-id";
import EditInventoryDetails from "./pages/applications/edit/[carId]";
import Loans from "./pages/applications/loans";
import OfferSimulator from "./pages/applications/offer-simulator";
import SelectCars from "./pages/applications/select-car";
import SimulationHistory from "./pages/applications/simulation-history";
import UploadDocuments from "./pages/applications/upload-documents";
import UploadSignedDocuments from "./pages/applications/upload-signed-documents";
import TotalCommission from "./pages/dashboard/total-commission";
import TotalSales from "./pages/dashboard/total-sales";
import DealerApplication from "./pages/dealerApplication";
import DealerBenefits from "./pages/dealerBenefits";
import DealerDashboard from "./pages/dealerDashboard";
import DealerFinancing from "./pages/dealerFinancing";
import DealerHelp from "./pages/dealerHelp";
import DealerHistory from "./pages/dealerHistory";
import Inventory from "./pages/inventory";
import LoanCalculatorPage from "./pages/loan-calculator";
import PreQualification from "./pages/prequalification/prequalification";
import AllRepoDeals from "./pages/repo/all";
import RepoFinancing from "./pages/repo/repoFinancing";
import asideState from "./states/aside";
import authState from "./states/auth";
import clarity from "@microsoft/clarity";

import { isMobile } from "react-device-detect";
import LoanLimitForm from "./pages/applications/loan-limit-form";
import FinanceApplicationForm from "./pages/applications/import-financing";
import DealerLimitConfirmation from "./pages/applications/dealer-limit-confirmation";
import DealerLoanDetails from "./pages/applications/loan-id";
import CreditLimitActivation from "./pages/applications/credit-limit-activation";
import UploadMasterAgreement from "./pages/applications/upload-master-agreement";
import RepoFinancingForm from "./pages/applications/repo-financing";
import RepoInventory from "./pages/repo-inventory";
import DealerInventory from "./pages/dealer-inventory";
import PreRepoFinancingForm from "./components/refix/PreRepoFi";
import UploadDealerDocuments from "./pages/applications/upload-dealer-documents";
import UploadDealerSignedDocuments from "./pages/applications/upload-dealer-signed-docs";
import RequestInspection from "./pages/applications/request-inspection";
import UploadEquity from "./pages/applications/upload-equity";
import UploadCustomDocument from "./pages/applications/custom-document";
import ValuationReport from "./pages/applications/valuation-report";
import UploadPpiDocument from "./pages/applications/upload-ppi";
import ManageUsersModule from "./pages/manage-users";
import LoanTransferForm from "./pages/applications/loan-transfer-form";
import CarTrackerDocument from "./pages/applications/car-tracker";
import Financing from "./pages/financing";
import SelectDealer from "./pages/select-dealer";
import SettingsPage from "./pages/settings";
import { CarIsurance } from "./pages/insurance/car-insurance";
import SellInsurance from "./pages/insurance/sell-insurance";
import QuoteForm from "./pages/insurance/quote-form";
import InsurancePolicies from "./pages/insurance/insurance-policies";
import InsuranceDetail from "./layouts/insurance-layouts/InsuranceDetail";
import QuoteGenerated from "./pages/insurance/quote-generated";
import QuoteFormDetails from "./pages/insurance/quote-form-details";
import Auctions from "./pages/auctions";
import ActivateDirectDebit from "./pages/applications/direct-debit";
import InsuranceDirectDebit from "./pages/insurance/insurance-direct-debit";
import InsurancePaymentCompleted from "./layouts/insurance-layouts/InsurancePaymentCompleted";
import InsurancePayment from "./pages/insurance/insurance-payment-confirmed";
import DealerLeads from "./pages/leads";
import DealerLeadDetails from "./pages/leads/[id]";
import RouteNavigationOverlay from "./components/home/RouteNavOverlay";
import DealerListModule from "./components/dealerLeads/DealerList";

const App = () => {
  const { i18n } = useTranslation();
  const isFranchise = storage.utilities.isFranchise();
  const isAdmin = storage.utilities.isAdmin();
  const [setVisibility, setCollapsibility] = asideState(
    (state) => [state.setVisibility, state.setCollapsibility],
    shallow
  );
  const [myHistory, setMyHistory] = useState<boolean>(false);

  const toViewHistory = () => {
    isMobile ? history.push(`/dealer/history`) : setMyHistory(true);
  };

  document
    .getElementsByTagName("html")[0]
    .setAttribute("dir", `${i18n.language === "ar" ? "rtl" : "ltr"}`);

  const isLoggedIn =
    authState((state: IAuthState) => state.isLoggedIn) ||
    storage?.getUser() !== null;

  useEffect(() => {
    if (isLoggedIn && isLoggedIn !== null) {
      setVisibility(true);
      setCollapsibility(true);
    } else {
      setVisibility(false);
      setCollapsibility(false);
    }
  }, [isLoggedIn, setCollapsibility, setVisibility]);

  useEffect(() => {
    clarity.init(process.env.REACT_APP_CLARITY_API_KEY!);
  }, []);

  const roleBasedPath = () => {
    switch (true) {
      case isFranchise:
        return "/dealer/dashboard";
      case isAdmin:
        return "/manage/users";
      default:
        return "/dashboard";
    }
  };

  return (
    <>
      <HistoryRouter history={history}>
        <Routes>
          <Route path="auth">
            <Route path="sign-in" element={<SignIn />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="verify-link" element={<VerifyLink />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>
          <Route element={<AdaptiveAsideLayout />}>
            <Route path="/" element={<Redirect path={roleBasedPath()} />} />
            <Route
              path="dashboard"
              element={
                <RequireAuthentication>
                  <Dashboard />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/dashboard"
              element={
                <RequireAuthentication>
                  <DealerDashboard
                    myHistory={myHistory}
                    setMyHistory={setMyHistory}
                    toViewHistory={toViewHistory}
                  />
                </RequireAuthentication>
              }
            />
            <Route
              path="manage/users"
              element={
                <RequireAuthentication>
                  <ManageUsersModule />
                </RequireAuthentication>
              }
            />
            <Route
              path="manage/routes"
              element={
                <RequireAuthentication>
                  <RouteNavigationOverlay />
                </RequireAuthentication>
              }
            />
            <Route
              path="manage/dealers"
              element={
                <RequireAuthentication>
                  <DealerListModule />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/applications"
              element={
                <RequireAuthentication>
                  <DealerApplication />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/history"
              element={
                <RequireAuthentication>
                  <DealerHistory />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/financing"
              element={
                <RequireAuthentication>
                  <DealerFinancing />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/loan-limit"
              element={
                <RequireAuthentication>
                  <LoanLimitForm />
                </RequireAuthentication>
              }
            />

            <Route
              path="dealer/financing/loan"
              element={
                <RequireAuthentication>
                  <FinanceApplicationForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/financing/repo-financing"
              element={
                <RequireAuthentication>
                  <RepoFinancingForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/loan-limit/confirmation"
              element={
                <RequireAuthentication>
                  <DealerLimitConfirmation />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/credit-limit"
              element={
                <RequireAuthentication>
                  <CreditLimitActivation />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/upload-master-agreement"
              element={
                <RequireAuthentication>
                  <UploadMasterAgreement />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/benefits"
              element={
                <RequireAuthentication>
                  <DealerBenefits />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/help"
              element={
                <RequireAuthentication>
                  <DealerHelp />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/best-deals"
              element={
                <RequireAuthentication>
                  <RepoFinancing />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/financing/guide"
              element={
                <RequireAuthentication>
                  <PreRepoFinancingForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/inventory"
              element={
                <RequireAuthentication>
                  <DealerInventory />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/inventory/:carId"
              element={
                <RequireAuthentication>
                  <RepoInventory />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/all-deals"
              element={
                <RequireAuthentication>
                  <AllRepoDeals />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/leads"
              element={
                <RequireAuthentication>
                  <DealerLeads />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/leads/:id"
              element={
                <RequireAuthentication>
                  <DealerLeadDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications"
              element={
                <RequireAuthentication>
                  <Applications />
                </RequireAuthentication>
              }
            />
            <Route
              path="financing"
              element={
                <RequireAuthentication>
                  <Financing />
                </RequireAuthentication>
              }
            />
            <Route
              path="financing/select-dealer"
              element={
                <RequireAuthentication>
                  <SelectDealer />
                </RequireAuthentication>
              }
            />
            <Route
              path="loan-calculator"
              element={
                <RequireAuthentication>
                  <LoanCalculatorPage />
                </RequireAuthentication>
              }
            />
            <Route
              path="prequalification"
              element={
                <RequireAuthentication>
                  <PreQualification />
                </RequireAuthentication>
              }
            />
            <Route
              path="inventory"
              element={
                <RequireAuthentication>
                  <Inventory />
                </RequireAuthentication>
              }
            />
            <Route
              path="inventory/:carId"
              element={
                <RequireAuthentication>
                  <InventoryDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="inventory/create-car"
              element={
                <RequireAuthentication>
                  <CreateCarForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="inventory/:carId/edit"
              element={
                <RequireAuthentication>
                  <EditInventoryDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="dashboard/total-commission"
              element={
                <RequireAuthentication>
                  <TotalCommission />
                </RequireAuthentication>
              }
            />
            <Route
              path="dashboard/total-sales"
              element={
                <RequireAuthentication>
                  <TotalSales />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/loan-transfer"
              element={
                <RequireAuthentication>
                  <LoanTransferForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/loans"
              element={
                <RequireAuthentication>
                  <Loans />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/loans/:id"
              element={
                <RequireAuthentication>
                  <LoanDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/dealer/applications/loans/:id"
              element={
                <RequireAuthentication>
                  <DealDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/dealer/applications/deal/:id"
              element={
                <RequireAuthentication>
                  <DealerLoanDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/select-cars"
              element={
                <RequireAuthentication>
                  <SelectCars />
                </RequireAuthentication>
              }
            />
            <Route
              path="insurance"
              element={
                <RequireAuthentication>
                  <InsurancePolicies />
                </RequireAuthentication>
              }
            />
            <Route
              path="insurance/:id"
              element={
                <RequireAuthentication>
                  <InsuranceDetail />
                </RequireAuthentication>
              }
            />
            <Route
              path="insurance/car"
              element={
                <RequireAuthentication>
                  <CarIsurance />
                </RequireAuthentication>
              }
            />
            <Route
              path="insurance/sell"
              element={
                <RequireAuthentication>
                  <SellInsurance />
                </RequireAuthentication>
              }
            />
            <Route
              path="insurance/quote-form"
              element={
                <RequireAuthentication>
                  <QuoteForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="insurance/quote-completed/:id"
              element={
                <RequireAuthentication>
                  <QuoteGenerated />
                </RequireAuthentication>
              }
            />

            <Route
              path="insurance/quote-form-details"
              element={
                <RequireAuthentication>
                  <QuoteFormDetails />
                </RequireAuthentication>
              }
            />

            <Route
              path="insurance/payment"
              element={
                <RequireAuthentication>
                  <InsurancePayment />
                </RequireAuthentication>
              }
            />

            <Route
              path="insurance/payment-completed"
              element={
                <RequireAuthentication>
                  <InsurancePaymentCompleted />
                </RequireAuthentication>
              }
            />

            <Route
              path="insurance/direct-debit"
              element={
                <RequireAuthentication>
                  <InsuranceDirectDebit />
                </RequireAuthentication>
              }
            />

            <Route
              path="applications/create-car-for-cash"
              element={
                <RequireAuthentication>
                  <CreateCarForCashForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/car-found"
              element={
                <RequireAuthentication>
                  <CarFound />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/loan/confirmation"
              element={
                <RequireAuthentication>
                  <Confirmation />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/upload-documents"
              element={
                <RequireAuthentication>
                  <UploadDocuments />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/upload-signed-documents"
              element={
                <RequireAuthentication>
                  <UploadSignedDocuments />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/applications/upload-documents"
              element={
                <RequireAuthentication>
                  <UploadDealerDocuments />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/applications/upload-signed-documents"
              element={
                <RequireAuthentication>
                  <UploadDealerSignedDocuments />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/car-list"
              element={
                <RequireAuthentication>
                  <CarList />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/offer-simulator"
              element={
                <RequireAuthentication>
                  <OfferSimulator />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/offer-simulation-history"
              element={
                <RequireAuthentication>
                  <SimulationHistory />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/request-inspection"
              element={
                <RequireAuthentication>
                  <RequestInspection />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/car-tracker"
              element={
                <RequireAuthentication>
                  <CarTrackerDocument />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/equity-payment"
              element={
                <RequireAuthentication>
                  <UploadEquity />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/direct-debit"
              element={
                <RequireAuthentication>
                  <ActivateDirectDebit />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/custom-document"
              element={
                <RequireAuthentication>
                  <UploadCustomDocument />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/valuation-report"
              element={
                <RequireAuthentication>
                  <ValuationReport />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/ppi-request"
              element={
                <RequireAuthentication>
                  <UploadPpiDocument />
                </RequireAuthentication>
              }
            />
            <Route
              path="settings"
              element={
                <RequireAuthentication>
                  <SettingsPage />
                </RequireAuthentication>
              }
            />
            <Route
              path="auctions"
              element={
                <RequireAuthentication>
                  <Auctions />
                </RequireAuthentication>
              }
            />
          </Route>
          <Route element={<AdaptiveAsideLayout />}>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </HistoryRouter>

      <ToastContainer />
    </>
  );
};

export default App;
