import React, { useState } from "react";
import type { FormProps } from "antd";
import { Button, Form, InputNumber, message } from "antd";
import { FormInputButtonContainer } from "./styles";
import tw from "tailwind-styled-components";
import formatCurrency from "@/utils/formatCurrency";
import { CInputNumber } from "@/layouts/application-layouts/styles";
import { soldToLead } from "@/hooks/use-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Alert, Snackbar } from "@mui/material";
import { Loader } from "@/pages/auth/styles/loader";

type FieldType = {
  price?: string;
};

interface ISalesCompContentProps {
  closeSalesModal: () => void;
  data?: any;
}

const Label = tw.span`text-sm text-[#202020] font-semibold`;

const SalesCompletedContent: React.FC<ISalesCompContentProps> = ({
  closeSalesModal,
  data,
}) => {
  const [form] = Form.useForm();
  const query = useQueryClient();
  const [alert, setAlert] = useState("");

  const useMarkAsSold = () => {
    return useMutation({
      mutationFn: (payload) => soldToLead(data?.id, payload),
    });
  };

  const { mutate: markAsSold, isLoading: isMarkingAsSold } = useMarkAsSold();

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    const payload = {
      carId: data?.metadata?.carId,
      price: values.price,
    };
    markAsSold(payload as any, {
      onSuccess: (response) => {
        if (response) {
          closeSalesModal();
          query.invalidateQueries(["fetch_single_car_lead"]);
          message.success("Sale completed successfully");
        }
      },
      onError: (error: any) => {
        const errMessage = JSON.parse(error.response?.data || "{}").message;
        setAlert(errMessage);
      },
    });
  };

  return (
    <main>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}
      >
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>

      <Form
        name="Book Leads"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
        form={form}
      >
        <CInputNumber className="mt-5">
          <Form.Item<FieldType>
            label={<Label>Enter price Sold</Label>}
            name="price"
            rules={[{ required: true, message: "Please enter date" }]}
            extra={
              <div className="mt-2 bg-[#FFBF421C] p-2 text-sm font-light text-[#000]">
                Please note that once this has been updated, this vehicle will
                be delisted on the platform
              </div>
            }
          >
            <InputNumber
              formatter={(value: any) => {
                return formatCurrency(value);
              }}
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </CInputNumber>

        <div className="flex gap-3 mt-10 mb-4">
          <button
            onClick={closeSalesModal}
            type="button"
            className="w-full max-w-full h-12 bg-white text-base font-semibold text-[#30345E] border border-solid border-[#30345E] rounded-full"
          >
            Cancel
          </button>
          <FormInputButtonContainer className="w-full">
            <Form.Item label={null} className="mb-0">
              <Button htmlType="submit">
                {isMarkingAsSold ? <Loader variant="secondary" /> : "Submit"}{" "}
              </Button>
            </Form.Item>
          </FormInputButtonContainer>
        </div>
      </Form>
    </main>
  );
};

export default SalesCompletedContent;
