import create from "zustand";
import axios from "axios";
import { IGeneratedQuote } from "@/layouts/insurance-layouts/quote-form/types";
import { message } from "antd";

interface QuoteByIdStore {
  quoteLoading: boolean;
  quotesById: IGeneratedQuote | undefined;
  fetchQuotesById: (quoteId: string) => Promise<void>;
}

const useQuoteByIdStore = create<QuoteByIdStore>((set) => ({
  quoteLoading: false,
  quotesById: undefined,

  fetchQuotesById: async (quoteId: string) => {
    const url = `${process.env.REACT_APP_NOVA_BASE_URL}/quotations/${quoteId}`;
    const headers = {
      "Content-Type": "application/json",
      ...(process.env.REACT_APP_NOVA_API_KEY && {
        "x-api-key": process.env.REACT_APP_NOVA_API_KEY,
      }),
    };

    try {
      set({ quoteLoading: true });
      const response = await axios.get(url, { headers });
      set({
        quotesById: response.data,
        quoteLoading: false,
      });
    } catch (err: any) {
      set({ quoteLoading: false });
      message.error(err?.response?.data?.message || "Error fetching quotes");
    }
  },
}));

export default useQuoteByIdStore;
