import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import formatInt from "@/utils/format-int";
import { Loader } from "../auth/styles/loader";
import useQuoteByIdStore from "@/states/quotes";
import { PAYMENT_PLAN } from "@/interface/policies";

const Loading = () => {
  return (
    <div className="w-full max-w-full min-h-[400px] flex items-center justify-center">
      <Loader variant="secondary" />
    </div>
  );
};

const QuoteGenerated = () => {
  const navigate = useNavigate();
  const param = useParams();
  const quoteId = param.id;

  const { quoteLoading, quotesById, fetchQuotesById } = useQuoteByIdStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (quoteId) fetchQuotesById(quoteId);
  }, [fetchQuotesById, quoteId]);

  const handleProceed = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate(`/insurance/quote-form-details?quote_id=${quoteId}`);
    }, 1500);
  };

  const QuoteDetails = [
    {
      label: "Vehicle Name",
      description: quotesById?.vehicle.name ?? "---",
      id: 1,
      visible: true,
    },
    {
      label: "Policy Type",
      description: quotesById?.product.coverageType ?? "---",
      id: 2,
      visible: true,
    },
    {
      label: "Usage Type",
      description: quotesById?.vehicle?.usageType ?? "---",
      id: 3,
      visible: true,
    },
    {
      label: "Policy Value",
      description: formatInt(
        Number(quotesById?.vehicle.estimatedCarPrice),
        true
      ),
      id: 4,
      visible: true,
    },
    {
      label: "Policy Duration",
      description: `${quotesById?.coverDuration} months`,
      id: 5,
      visible: true,
    },
    {
      label: "Commission Amount",
      description: formatInt(Number(quotesById?.commissionAmount), true),
      id: 6,
      visible: true,
    },
    {
      label: "Amount Due Today",
      description: formatInt(Number(quotesById?.amountDue), true),
      id: 7,
      visible: true,
    },
    {
      label: "Subsequent Monthly Payments",
      description: formatInt(Number(quotesById?.subsequentMonthlyAmount), true),
      id: 8,
      visible: quotesById?.paymentPlan === PAYMENT_PLAN.MONTHLY,
    },
    {
      label: "Number of Instalments",
      description: quotesById?.instalmentCount,
      id: 9,
      visible: quotesById?.paymentPlan === PAYMENT_PLAN.MONTHLY,
    },
  ];

  return (
    <main className="w-full max-w-full md:max-w-[75%] xl:max-w-[45%] bg-white flex flex-col gap-5 rounded-md my-0 mx-auto px-4 py-4 md:px-8 md:py-10">
      {quoteLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex justify-center">
            <img
              src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/Frame%2034367.webp?updatedAt=1730881269416"
              alt="Girl jubilating"
              className="w-[215px] h-[248px]"
            />
          </div>
          <h1 className="text-lg md:text-2xl text-[#31355B] font-outfitbold text-center">
            Your quote has been generated
          </h1>
          <div className="w-full px-2 py-4 md:px-3 bg-[#EEEFFF] flex flex-col gap-2 border border-solid border-[#E5E7EB] rounded-lg">
            <p className="text-lg md:text-xl font-outfitbold text-[#202020] capitalize">
              {quotesById?.paymentPlan?.replaceAll("_", "-")} PAYMENT
            </p>
            <div className="flex justify-between items-start">
              <p className="text-sm md:text-base text-[#6B7280] font-medium mt-0.5">
                Amount due today
              </p>
              <div>
                <p className="text-lg md:text-2xl font-outfitbold text-[#202020] text-right">
                  {formatInt(Number(quotesById?.amountDue), true)}
                </p>
                {quotesById?.paymentPlan === PAYMENT_PLAN.MONTHLY && (
                  <p className="text-sm md:text-base text-[#6B7280] font-normal text-right">
                    Subsequent monthly amount{" "}
                    {formatInt(
                      Number(quotesById?.subsequentMonthlyAmount),
                      true
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>

          <ul className="divide-y divide-[#E5E7EB] border border-solid border-[#E5E7EB] rounded-lg">
            {QuoteDetails?.map(
              (quote) =>
                quote.visible && (
                  <li key={quote.id} className="flex justify-between py-3 px-3">
                    <p className="text-sm md:text-base text-[#6B7280] font-normal">
                      {quote.label}
                    </p>
                    <p className="text-sm md:text-base text-[#6B7280] font-semibold text-right capitalize">
                      {quote.description}
                    </p>
                  </li>
                )
            )}
          </ul>

          <div className="flex items-center space-x-4">
            <button
              onClick={() =>
                navigate(`/insurance/quote-form?quote_id=${quoteId}`)
              }
              className="text-sm md:text-base text-[#30345E] bg-white border border-solid border-[#30345E] font-semibold text-center w-full max-w-full md:max-w-[50%] my-0 mx-auto h-12 rounded-full"
            >
              Update Quote Details
            </button>
            <button
              onClick={handleProceed}
              className="text-sm md:text-base text-[#30345E] font-semibold w-full max-w-full md:max-w-[50%] my-0 mx-auto h-12 bg-[#FFB619] rounded-full"
            >
              {loading ? <Loader variant="secondary" /> : "Proceed"}
            </button>
          </div>
        </>
      )}
    </main>
  );
};

export default QuoteGenerated;
