import React, { useState } from "react";
import { DatePicker } from "antd";
import { CloseIcon } from "@/assets/svgs";
import { useLocation, useNavigate } from "react-router-dom";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import { Autocomplete, TextField } from "@mui/material";
import { ICarMakeObj, ICarMakes, ICarModelObj, IYearObj } from "@/types";
import { useQuery } from "@tanstack/react-query";
import storage from "@/lib/storage";
import { fetchCarMakes, fetchCarModels } from "@/hooks/use-api";
import { ICarModels } from "@/interface/dealer";
import { useTranslation } from "react-i18next";
import { generateArrayOfYears } from "@/utils/yearsArray";
import {
  DateField,
  FormInputNumberStyle,
} from "@/layouts/application-layouts/styles";
import { getCountryCurrency } from "@/utils/getCountryCurrency";
import { getLocalizedText } from "@/utils/locale-datepicker-text";
import dayjs, { Dayjs } from "dayjs";
import { RangePickerProps } from "antd/es/date-picker";
import { NumericFormat } from "react-number-format";

interface LeadDateRange {
  fromDate: string | Dayjs;
  toDate: string | Dayjs;
}

interface IFilterLeadsProps {
  query: any;
  setCloseFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

const contactTypeFilters = [
  {
    label: "Whatsapp",
    value: "whatsapp",
  },
  {
    label: "Phone Call",
    value: "phone_call",
  },
  {
    label: "Sellers Address",
    value: "address",
  },
  {
    label: "Social Media",
    value: "social_media",
  },
];

const FilterCarLeads: React.FC<IFilterLeadsProps> = ({
  query,
  setCloseFilter,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const country = storage.getCountry();
  const { t } = useTranslation("dashboard");
  const { RangePicker } = DatePicker;
  const locale = storage.getLocale();
  const localizedText = getLocalizedText(locale);

  const [filterContactType, setFilterContactType] = useState("");
  const [carMakes, setCarMakes] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [carMakeId, setCarMakeId] = useState({} as ICarMakeObj);
  const [carModelId, setCarModelId] = useState({} as ICarModelObj);
  const [year, setYear] = useState({} as IYearObj);
  const [leadDateValue, setLeadDateValue] = useState<LeadDateRange>({
    fromDate: "",
    toDate: "",
  });
  const [bookDateValue, setbookDateValue] = useState<LeadDateRange>({
    fromDate: "",
    toDate: "",
  });
  const [leadDateRange, setLeadDateRange] = useState<
    [dayjs.Dayjs, dayjs.Dayjs] | null
  >(null);
  const [bookDateRange, setBookDateRange] = useState<
    [dayjs.Dayjs, dayjs.Dayjs] | null
  >(null);

  const yearsList = generateArrayOfYears().map((year) => {
    return {
      label: year.toString(),
      value: year.toString(),
    };
  });

  const [minCarValue, setMinCarValue] = useState("");
  const [maxCarValue, setMaxCarValue] = useState("");

  const { isLoading: carMakesLoading } = useQuery({
    queryKey: ["get_cars_makes"],
    queryFn: () => {
      const params = new URLSearchParams({
        page_size: "400",
      });
      return fetchCarMakes(params);
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const carMakes = parsedResults?.makeList?.map((make: ICarMakes) => {
        return {
          label: make?.name,
          value: make?.id,
        };
      });
      setCarMakes(carMakes);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: carModelLoading } = useQuery({
    queryKey: ["get_cars_models", country, carMakeId?.value],
    queryFn: () => {
      const params = new URLSearchParams({
        country_code: country,
        page_size: "500",
        make_id: carMakeId?.value,
      });
      return fetchCarModels(params);
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const carModels = parsedResults?.modelList?.map((model: ICarModels) => {
        return {
          label: model?.name,
          value: model?.id,
        };
      });
      setCarModels(carModels);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!carMakeId?.value,
  });

  const handleLeadDateFilter: RangePickerProps["onChange"] = (
    dates,
    dateStrings
  ) => {
    if (dates) {
      setLeadDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs]);
      setLeadDateValue({
        fromDate: dateStrings[0],
        toDate: dateStrings[1],
      });
    } else {
      setLeadDateRange(null);
      setLeadDateValue({
        fromDate: "",
        toDate: "",
      });
    }
  };

  const handleBookingDateFilter: RangePickerProps["onChange"] = (
    dates,
    dateStrings
  ) => {
    if (dates) {
      setBookDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs]);
      setbookDateValue({
        fromDate: dateStrings[0],
        toDate: dateStrings[1],
      });
    } else {
      setBookDateRange(null);
      setbookDateValue({
        fromDate: "",
        toDate: "",
      });
    }
  };

  const handleContactTypeChange = (value: string) => {
    setFilterContactType(value);
  };

  const handleSubmitFilter = () => {
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        ...(filterContactType && { source: filterContactType }),
        ...(carMakeId?.label && { make: carMakeId?.label }),
        ...(carModelId?.label && { model: carModelId?.label }),
        ...(year?.value && { year: year?.value }),
        ...(String(minCarValue) && {
          low_price: String(minCarValue),
        }),
        ...(String(maxCarValue) && {
          high_price: String(maxCarValue),
        }),
        ...(leadDateValue.fromDate && {
          lead_date_start: leadDateValue.fromDate,
        }),
        ...(leadDateValue.toDate && { lead_date_end: leadDateValue.toDate }),
        ...(bookDateValue.toDate && {
          booking_date_start: bookDateValue.toDate,
        }),
        ...(bookDateValue.toDate && { booking_date_end: bookDateValue.toDate }),
      })}`
    );
    setCloseFilter(false);
  };

  const handleResetFilter = () => {
    setFilterContactType("");
    setCarMakeId({ label: "", value: "" } as ICarMakeObj);
    setCarModelId({ label: "", value: "" } as ICarModelObj);
    setYear({ label: "", value: "" } as IYearObj);
    setMinCarValue("");
    setMaxCarValue("");
    setLeadDateValue({
      fromDate: "",
      toDate: "",
    });
    setLeadDateRange(null);
    setbookDateValue({
      fromDate: "",
      toDate: "",
    });
    setBookDateRange(null);
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        source: "",
        make: "",
        model: "",
        year: "",
        low_price: "",
        high_price: "",
        lead_date_start: "",
        lead_date_end: "",
        booking_date_start: "",
        booking_date_end: "",
      })}`
    );
  };

  return (
    <section className="h-[600px] md:h-auto overflow-scroll">
      <div className="flex items-center justify-between mb-4">
        <p className="text-xl text-black font-semibold">Sort By</p>
        <button onClick={() => setCloseFilter(false)}>
          <CloseIcon />
        </button>
      </div>
      <div className="flex flex-col gap-5">
        <div>
          <p className="text-base text-black font-semibold mb-2">
            Contact Type
          </p>
          <div className="flex items-center gap-2.5 flex-wrap">
            {contactTypeFilters.map((c) => (
              <button
                key={c.value}
                onClick={() => handleContactTypeChange(c.value)}
                className={`${
                  c.value === filterContactType
                    ? "bg-[#EEEFFF] border-[#EEEFFF]"
                    : "bg-white border-[#30345e]"
                } text-[#30345E] w-fit h-10 border border-solid p-4 rounded-lg flex items-center`}
              >
                {c.label}
              </button>
            ))}
          </div>
        </div>

        <div>
          <p className="text-base text-black font-semibold mb-2">
            Vehicle Details
          </p>
          <div className="flex flex-col gap-2.5">
            <div>
              <p className="text-base text-[#000000] font-medium mb-1">Make</p>
              <Autocomplete
                fullWidth
                id="filterCarMakes"
                disablePortal
                options={carMakes ?? []}
                onChange={(_, newValue: any) => {
                  setCarMakeId(newValue);
                }}
                value={carMakeId}
                loading={carMakesLoading}
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="inventory_search_inputField"
                    placeholder={t("Select make")}
                  />
                )}
              />
            </div>
            <div>
              <p className="text-base text-[#000000] font-medium mb-1">Model</p>
              <Autocomplete
                fullWidth
                id="filterCarModels"
                disablePortal
                options={carModels ?? []}
                onChange={(e, newValue: any) => {
                  setCarModelId(newValue);
                }}
                value={carModelId}
                loading={carModelLoading}
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="inventory_search_inputField"
                    placeholder={t("Select model")}
                  />
                )}
              />
            </div>
            <div>
              <p className="text-base text-[#000000] font-medium mb-1">Year</p>
              <Autocomplete
                fullWidth
                id="filter_loans"
                disablePortal
                options={yearsList ?? []}
                onChange={(e, newValue: any) => {
                  setYear(newValue);
                }}
                value={year}
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="inventory_search_inputField"
                    placeholder={t("Select year")}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div>
          <p className="text-base text-black font-semibold mb-2">Price Range</p>
          <div className="flex flex-col md:flex-row md:items-center gap-2 w-full max-w-full">
            <div className="mb-3 md:mb-0 w-full">
              <div className="text-base text-[#000000] font-medium mb-1">
                Min. Car Value
              </div>
              <NumericFormat
                fullWidth
                placeholder={"Enter minimum price"}
                name={"minCarValue"}
                aria-label={"Minimum Car Value"}
                thousandSeparator=","
                allowNegative={false}
                prefix={getCountryCurrency(country)}
                onValueChange={(values) => {
                  setMinCarValue(values.value);
                }}
                customInput={TextField}
                sx={FormInputNumberStyle}
              />
            </div>
            <img
              src="https://media.autochek.africa/file/publicAssets/Line-1.svg"
              alt="line-icon"
              className="mt-5 hidden md:block"
            />
            <div className="w-full">
              <div className="text-base text-[#000000] font-medium mb-1">
                Max. Car Value
              </div>
              <NumericFormat
                fullWidth
                placeholder={"Enter maximum price"}
                name={"maxCarValue"}
                aria-label={"Maximum Car Value"}
                thousandSeparator=","
                allowNegative={false}
                prefix={getCountryCurrency(country)}
                onValueChange={(values) => {
                  setMaxCarValue(values.value);
                }}
                customInput={TextField}
                sx={FormInputNumberStyle}
              />
            </div>
          </div>
        </div>

        <div>
          <p className="text-base text-black font-semibold mb-2">Lead Date</p>
          <DateField className="w-full">
            <RangePicker
              locale={localizedText}
              onChange={handleLeadDateFilter}
              value={leadDateRange}
              allowClear
              format="DD-MM-YYYY"
            />
          </DateField>
        </div>

        <div>
          <p className="text-base text-black font-semibold mb-2">
            Booking Date
          </p>
          <DateField className="w-full">
            <RangePicker
              locale={localizedText}
              onChange={handleBookingDateFilter}
              value={bookDateRange}
              allowClear
              format="DD-MM-YYYY"
            />
          </DateField>
        </div>

        <div className="flex items-center gap-3 mt-8">
          <button
            onClick={handleResetFilter}
            className="w-full h-12 text-base bg-[#fff] text-[#30345e] font-semibold border border-solid border-[#30345e] rounded-full"
          >
            Reset
          </button>
          <button
            onClick={handleSubmitFilter}
            className="w-full h-12 text-base bg-[#FFB619] text-[#30345e] font-semibold border border-solid border-[#FFB619] rounded-full"
          >
            Apply
          </button>
        </div>
      </div>
    </section>
  );
};

export default FilterCarLeads;
