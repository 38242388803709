import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, message, TimePicker } from "antd";
import { FormInputButtonContainer, FormInputDateContainer } from "./styles";
import tw from "tailwind-styled-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCarLeads } from "@/hooks/use-api";
import { Loader } from "@/pages/auth/styles/loader";
import { Alert, Snackbar } from "@mui/material";
import dayjs from "dayjs";

interface IBookLeadPayload {
  booking_date: string;
  booking_time: string;
  status: string;
}

interface IBookLeadContentProps {
  closeBookLeadModal: () => void;
  data?: Record<string, any>;
}

const Label = tw.span`text-base text-[#202020] font-semibold`;

const BookLeadsContent: React.FC<IBookLeadContentProps> = ({
  closeBookLeadModal,
  data,
}) => {
  const [form] = Form.useForm();
  const query = useQueryClient();
  const [alert, setAlert] = useState("");

  useEffect(() => {
    if (data?.status === "booked") {
      form.setFieldsValue({
        date: dayjs(data?.bookingDate, "DD-MM-YYYY"),
        time: data?.bookingTime
          ? dayjs(`2000-01-01T${data?.bookingTime}`)
          : null,
      });
    }
  }, [data?.bookingDate, data?.bookingTime, data?.status, form]);

  const useBookLeads = () => {
    return useMutation({
      mutationFn: (payload) => updateCarLeads(data?.id, payload),
    });
  };

  const { mutate: bookLeads, isLoading: isBookingLeads } = useBookLeads();

  const onFinish = (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
      date: fieldsValue["date"].format("DD-MM-YYYY"),
      time: fieldsValue["time"] ? fieldsValue["time"].format("hh:mm A") : null,
      // ...(fieldsValue["time"] && {
      //   time: fieldsValue["time"].format("hh:mm A"),
      // }),
    };
    const payload: IBookLeadPayload = {
      booking_date: values.date,
      booking_time: values.time ? values.time : null,
      status: "booked",
    };
    bookLeads(payload as any, {
      onSuccess: (response) => {
        if (response) {
          closeBookLeadModal();
          query.invalidateQueries(["fetch_single_car_lead"]);
          query.invalidateQueries(["fetch_car_leads"]);
          message.success("Lead booked successfully");
        }
      },
      onError: (error: any) => {
        const errMessage = JSON.parse(error.response?.data || "{}").message;
        setAlert(errMessage);
      },
    });
  };

  return (
    <main>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}
      >
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>

      <Form
        name="Book Leads"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
        form={form}
        className="mt-2 md:mt-5"
      >
        <FormInputDateContainer>
          <Form.Item
            label={<Label>Date</Label>}
            name="date"
            rules={[{ required: true, message: "Please enter date" }]}
          >
            <DatePicker
              format="DD-MM-YYYY"
              disabledDate={(current) =>
                current && current <= dayjs().startOf("day")
              }
            />
          </Form.Item>
        </FormInputDateContainer>

        <FormInputDateContainer>
          <Form.Item label={<Label>Time (Optional)</Label>} name="time">
            <TimePicker use12Hours format="h:mm A" showNow={false} />
          </Form.Item>
        </FormInputDateContainer>

        <div className="flex gap-3 mt-10 mb-4">
          <button
            onClick={closeBookLeadModal}
            type="button"
            className="w-full max-w-full h-12 bg-white text-base font-semibold text-[#30345E] border border-solid border-[#30345E] rounded-full"
          >
            Cancel
          </button>
          <FormInputButtonContainer className="w-full">
            <Form.Item label={null} className="mb-0">
              <Button htmlType="submit">
                {isBookingLeads ? <Loader variant="secondary" /> : "Submit"}
              </Button>
            </Form.Item>
          </FormInputButtonContainer>
        </div>
      </Form>
    </main>
  );
};

export default BookLeadsContent;
