import create from "zustand";

type TLeadDrawerProps = {
  isBookLeadDrawerOpen: boolean;
  openBookLeadDrawer: () => void;
  closeBookLeadDrawer: () => void;
  isSalesDrawerOpen: boolean;
  openSalesDrawer: () => void;
  closeSalesDrawer: () => void;
  isClosedLeadDrawerOpen: boolean;
  openClosedLeadDrawer: () => void;
  closeClosedLeadDrawer: () => void;
};

const useLeadDrawerStore = create<TLeadDrawerProps>()((set) => ({
  isBookLeadDrawerOpen: false,
  openBookLeadDrawer: () => set({ isBookLeadDrawerOpen: true }),
  closeBookLeadDrawer: () => set({ isBookLeadDrawerOpen: false }),
  isSalesDrawerOpen: false,
  openSalesDrawer: () => set({ isSalesDrawerOpen: true }),
  closeSalesDrawer: () => set({ isSalesDrawerOpen: false }),
  isClosedLeadDrawerOpen: false,
  openClosedLeadDrawer: () => set({ isClosedLeadDrawerOpen: true }),
  closeClosedLeadDrawer: () => set({ isClosedLeadDrawerOpen: false }),
}));

export default useLeadDrawerStore;
