import { DEALER_FINANCE_COUNTRIES } from "@/utils/finance-status";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import { ChevronRight } from "@/assets/svgs";
import { useTranslation } from "react-i18next";
import storage from "@/lib/storage";

const allCountries = ["NG", "KE", "UG", "GH", "CI", "SN"];

const inventoryItems = [
  {
    title: "auctions",
    path: "/auctions",
    new: false,
    country: ["KE"],
  },
  {
    title: "my-financing",
    path: "/dealer/financing",
    new: false,
    country: DEALER_FINANCE_COUNTRIES,
  },
  {
    title: "inventory-management",
    path: "/dealer/inventory",
    new: false,
    country: allCountries,
  },
];

const sellItems = [
  {
    title: "insurance",
    path: "/insurance",
    new: false,
    country: ["NG"],
  },
  {
    title: "customer-financing",
    path: "/dealer/applications",
    new: false,
    country: allCountries,
  },
];

const marketingItems = [
  {
    title: "my-leads",
    path: "/dealer/leads",
    new: false,
    country: ["NG", "GH", "KE"],
  },
];

const supportItems = [
  {
    title: "loan-calculator",
    path: "/loan-calculator",
    new: false,
    country: allCountries,
  },
  {
    title: "prequalification",
    path: "/prequalification",
    new: false,
    country: allCountries,
  },
  {
    title: "settings",
    path: "/settings",
    new: false,
    country: allCountries,
  },
];

const RouteNavigationOverlay = () => {
  const country = storage.getCountry();
  const { t } = useTranslation("navigation");
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const variant = queryParam.get("variant");

  const determinePath = () => {
    switch (variant) {
      case "inventory":
        return inventoryItems;
      case "sell":
        return sellItems;
      case "marketing":
        return marketingItems;
      case "support":
        return supportItems;
      default:
        return [];
    }
  };

  const determinePathTitle = () => {
    switch (variant) {
      case "inventory":
        return "Inventory";
      case "sell":
        return "Sell";
      case "marketing":
        return "Marketing";
      case "support":
        return "Support";
      default:
        return "";
    }
  };

  return (
    <main className="bg-white rounded-lg">
      <h1 className="p-4 font-semibold text-2xl text-[#30345E] border-b border-solid border-[#E5E7EB]">
        {determinePathTitle()}
      </h1>
      <section className="divide-y px-4">
        {determinePath().map(
          (item) =>
            item.country.includes(country) && (
              <Link
                key={item.title}
                to={item.path}
                className="flex items-center justify-between py-6 capitalize text-[#30345E] font-semibold text-base"
              >
                {t(item.title)} <ChevronRight />
              </Link>
            )
        )}
      </section>
    </main>
  );
};

export default RouteNavigationOverlay;
