import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import storage from "@l/storage";
import { device } from "@/utils/device";
import { Modal, message } from "antd";
import NewLoansBtn from "@/layouts/application-layouts/NewLoansBtn";
import DealerLevelModal from "@/components/refix/DealerLevelModal";
import DealerHistoryView from "@/components/refix/DealerHistoryView";
import DealerLevelRelapseModal from "@/components/refix/DealerLevelRelapseModal";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import { IDealerSummary } from "@/interface/dealer";
import { useDealerSummary } from "@/states/dealer";
import { NewDealerHomepage } from "@/components/home/NewDealerHomepage";
import { dealerProfileCompleted } from "@/hooks/use-api";
import { useMediaQuery } from "@mui/material";
import useGTMTagging from "@/utils/gtmTagging";
import ModalVariant from "@/components/blocks/modals/ModalVariant";
import ProfileUpdateContent from "@/components/dealerLeads/ProfileUpdateContent";

const DealerLayout = styled.div`
  background: transparent;
  border: none;
  border-radius: 4px;
  min-height: 100vh;
  height: auto;
  .custom-scroll-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
    @media ${device.mobileL} {
      display: flex;
      flex-wrap: nowrap;
      gap: 16px;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    @media ${device.tablet} {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 16px;
    }
    .custom-card {
      flex: 0 0 auto;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default function DealerDashboard({
  myHistory,
  setMyHistory,
  toViewHistory,
}: any) {
  const role = storage.getRole();
  const user = storage.getUser();
  const isFranchise = storage.utilities.isFranchise();
  const isMobile = useMediaQuery("(max-width:600px)");
  const country = storage.getCountry();
  const [relapseOpen, setRelapseOpen] = useState<boolean>();
  const [dealerLevelOpen, setDealerLevelOpen] = useState<boolean>();
  const [dealerSummary, setDealerSummary] = useState<IDealerSummary | null>();
  const [openProfileModal, setOpenProfileModal] = useState<boolean>(false);

  const { trackPageView } = useGTMTagging();

  const trackLoginData = useCallback(() => {
    const data = {
      user_country: country,
      device_type: isMobile ? "mobile" : "desktop",
      env_device: isMobile ? "mobile" : "desktop",
      session_start: localStorage.getItem("sessionStart"),
    };
    return data;
  }, [country, isMobile]);

  useEffect(() => {
    const dataFa = trackLoginData();
    trackPageView(dataFa);
  }, [trackLoginData, trackPageView]);

  useEffect(() => {
    document.title = `Dealer Plus | Dealer Dashboard [${role}]`;
    setRelapseOpen(false);
    setDealerLevelOpen(false);
  }, [role]);

  const dealerSummaryStore = useDealerSummary.getState();

  const { isLoading: summaryLoading } = useQuery({
    queryKey: ["fetch_dealer_summary"],
    queryFn: () => {
      const franchiseId = user?.company?.id;

      return client.get(`/v1/franchise/${franchiseId}/dealer-tiers/summary`);
    },
    onSuccess: (res: any) => {
      const parsedResponseData: IDealerSummary = JSON.parse(res?.data);
      setDealerSummary(parsedResponseData);
      if (parsedResponseData?.isNewLevel) {
        setDealerLevelOpen(true);
        setRelapseOpen(true);
      }
      dealerSummaryStore.setSummary(parsedResponseData);
    },
    onError: (err: any) => {
      const parsedErr = JSON.parse(err?.response?.data);
      message.error(parsedErr?.message ? parsedErr?.message : parsedErr?.error);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["fetch_profile_completed", user?.company?.id],
    queryFn: () => {
      const params = new URLSearchParams({
        id: user?.company?.id,
      });
      return dealerProfileCompleted(user?.company?.id, params);
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      setOpenProfileModal(!parsedResponse?.completed);
    },
    enabled: !!user?.company?.id && isFranchise,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  return (
    <div>
      <Modal
        open={myHistory}
        onCancel={() => setMyHistory(false)}
        footer={null}
        width={900}
      >
        <DealerHistoryView
          dealerType={dealerSummary?.level!}
          dealerName={dealerSummary?.dealership!}
          closeModal={() => setMyHistory(false)}
        />
      </Modal>

      {dealerSummary && (
        <>
          {relapseOpen && dealerSummary?.levelUpgradeType === "downgrade" && (
            <DealerLevelRelapseModal
              close={() => setRelapseOpen(false)}
              prevLevel={dealerSummary.levelUpgrade}
              currentLevel={dealerSummary.level}
            />
          )}

          {dealerLevelOpen && dealerSummary?.levelUpgradeType === "upgrade" && (
            <DealerLevelModal
              level={dealerSummary.level.toLowerCase()}
              closeModal={() => setDealerLevelOpen(false)}
            />
          )}
        </>
      )}

      <DealerLayout className="flex flex-col gap-4 sm:px-0">
        <NewDealerHomepage
          dealerType={dealerSummary?.level!}
          loading={summaryLoading}
          dealerSummary={dealerSummary}
          toViewHistory={toViewHistory}
        />
      </DealerLayout>

      <NewLoansBtn />

      <ModalVariant
        isModalOpen={openProfileModal}
        handleCancel={() => setOpenProfileModal(false)}
        handleOk={() => setOpenProfileModal(false)}
        title="Update Your Profile to Start Connecting with Buyers!"
        content={<ProfileUpdateContent />}
        maskClosable={true}
      />
    </div>
  );
}
