import { useState } from "react";
import { CopyIcon } from "@/assets/svgs";
import { CheckOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

interface Props {
  redirectUrl: string;
}

const GeneratedLinkCard: React.FC<Props> = ({ redirectUrl }) => {
  const [copiedLink, setCopiedLink] = useState<boolean>(false);
  const location = useLocation();
  const isInsurancePath = ["/insurance/payment"].includes(location.pathname)
    ? "bg-white"
    : "bg-[#F8FAFC]";

  return (
    <div className="flex flex-col gap-1.5">
      <div
        className={`border border-solid border-[#E5E7EB] ${isInsurancePath} flex items-center justify-between px-3 py-3 rounded-md`}
      >
        <p className="flex-1 text-base text-[#202020] font-semibold text-ellipsis whitespace-nowrap w-full max-w-[95%] overflow-hidden">
          {redirectUrl}
        </p>
        <div className="flex space-x-3">
          <button
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(redirectUrl);
              setCopiedLink(true);
              setTimeout(() => {
                setCopiedLink(false);
              }, 1000);
            }}
          >
            {copiedLink ? <CheckOutlined className="text-xl" /> : <CopyIcon />}
          </button>
          <button
            onClick={() => window.open(redirectUrl, "_blank", "noopener")}
            className="px-4 py-2 bg-[#30345e] rounded-md text-white text-sm md:text-base font-medium"
          >
            Go to Payment
          </button>
        </div>
      </div>
      <p className="font-medium text-[#30345e] text-sm md:text-[15px] bg-[#fff4dd] px-3 py-1 rounded-md">
        Note that an automatic email with the link generated has been sent to
        this customer.
      </p>
    </div>
  );
};

export default GeneratedLinkCard;
