import create from "zustand";

interface IQuoteFormState {
  step: number;
  alert: string;
  setStep: (step: number) => void;
  setAlert: (step: string) => void;
}

const useQuoteStore = create<IQuoteFormState>()((set) => ({
  step: 1,
  alert: "",
  setStep: (step) => set({ step: step }),
  setAlert: (alert) => set({ alert: alert }),
}));

export default useQuoteStore;
