import { useNavigate } from "react-router-dom";
import storage from "@/lib/storage";
import { StorageKeys } from "@/constants/storage-keys";

export const CarIsurance = () => {
  const navigate = useNavigate();
  const user = storage.getUser();
  const hasViewedQuote = localStorage.getItem(StorageKeys.HAS_VIEWED_QUOTE);
  const isFranchise = storage.utilities.isFranchise();

  const handleNextAction = () => {
    localStorage.setItem(StorageKeys.HAS_VIEWED_QUOTE, "true");
    navigate(hasViewedQuote ? `/insurance/quote-form` : `/insurance/sell`);
  };

  return (
    <main>
      <div className="flex flex-col gap-5 text-button-variants-primary-scene mx-auto w-full max-w-full md:max-w-[75%] xl:max-w-[45%] md:bg-white py-3 md:p-6">
        <h2 className="!font-outfitBold font-semibold text-base md:text-2xl text-left md:text-center text-inputs-regular-input mb-2">
          Sell Valuable Services to Your customers.
        </h2>
        <div className="mb-1">
          <h2 className="!font-outfitBold font-semibold text-base md:text-xl mb-1">
            Dear{" "}
            {isFranchise ? (
              user?.company?.name ?? "Partner"
            ) : (
              <>
                {user?.firstname
                  ? `${user?.firstname} ${user?.lastname}`
                  : "Partner"}
              </>
            )}
            ,
          </h2>
          <p className="text-sm md:text-base font-medium leading-tight">
            Increase your revenue and customer satisfaction by selling through
            Dealer+
          </p>
        </div>
        <div className="rounded-lg flex !shadow-[0px_4px_50px_0px_#00000014] w-full max-w-full">
          <div className="relative w-full max-w-[45%] md:max-w-[50%]">
            <img
              src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/insurance-img1.webp"
              alt="Man Thumbs Up on Car"
              className="h-auto absolute bottom-0 left-0 w-[180px] md:w-[204px]"
            />
          </div>
          <div className="flex-1">
            <div className="text-right py-4 grid grid-flow-row gap-2 p-4">
              <h2 className="!font-outfitBold text-base md:text-2xl font-semibold">
                Sell Insurance
              </h2>
              <p className="text-xs md:text-base text-[#30345E] font-medium mb-2 !leading-tight">
                You can instantly generate <br /> a quote for a car
              </p>
              <button
                onClick={handleNextAction}
                className="text-sm md:text-base bg-button-variants-primary-scene text-[#EEEFFF] font-semibold rounded px-6 py-2 w-fit ml-auto"
              >
                Generate Quote
              </button>
            </div>
          </div>
        </div>
        <div className="rounded-lg flex justify-between !shadow-[0px_4px_50px_0px_#00000014] w-full max-w-full">
          <div className="relative w-full max-w-[45%] md:max-w-[50%]">
            <img
              src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/insurance-img2.webp"
              alt="Car Parts"
              className="h-auto absolute bottom-0 left-0 w-[180px] md:w-[204px]"
            />
          </div>

          <div className="flex-1">
            <div className="text-right py-4 grid grid-flow-row gap-2 p-4">
              <h2 className=" !font-outfitBold text-base md:text-2xl font-semibold leading-tight">
                Other Products
              </h2>
              <p className="text-xs md:text-base text-[#30345E] font-light mb-1">
                Sell Tyres, Batteries, AMC,..etc
              </p>
              <small className="text-button-variants-primary-scene bg-[#EEEFFF] rounded w-fit ml-auto font-thin p-0.5 text-xs md:text-base px-1">
                Coming soon
              </small>
              <button
                disabled
                className="bg-button-variants-primary-scene text-white font-semibold text-sm md:text-base rounded px-6 py-2 w-fit ml-auto disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
              >
                I'm interested
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
