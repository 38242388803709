import { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { InsuranceDetailTab } from "../application-layouts/styles";
import { message, Tabs } from "antd";
import InsurancePolicy from "./InsurancePolicy";
import { AxiosError } from "axios";
import { IPolicyData } from "@/interface/policies";
import getInitial from "@/utils/get-initial";
import storage from "@/lib/storage";
import { CallIcon, MailIcon2, WhatsappIcon } from "@/assets/svgs";
import tw from "tailwind-styled-components";
import { fetchPolicies } from "@/hooks/nova-api";
import { renderLoadingCard } from "@/components/insurance/LoadingCard";
import usePolicyByIdStore from "@/states/policies-by-id";

const Title = tw.p`text-xs md:text-base font-normal text-[#6B7280] flex items-center gap-1`;
const PhoneNumber = tw.p`text-xs md:text-base text-[#2563EB] font-bold`;

const InsuranceDetail = () => {
  const { id } = useParams() as { id: string };
  const country = storage.getCountry();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState("insurance_polices");
  const [policyLoading, setPolicyLoading] = useState(false);
  const [policies, setPolicies] = useState<IPolicyData>({} as IPolicyData);

  const { policyByIdLoading, policyById, fetchPolicyById } =
    usePolicyByIdStore();

  useEffect(() => {
    if (id) fetchPolicyById(id);
  }, [fetchPolicyById, id]);

  const handleTabChange = (key: string) => {
    setTabValue(key);
  };

  const handleFetchPolicies = useCallback(async () => {
    setPolicyLoading(true);
    const params = new URLSearchParams({
      country: country,
      customerId: policyById?.customer?.id as string,
    });
    try {
      const response = await fetchPolicies(params);
      setPolicies(JSON.parse(response?.data));
    } catch (error) {
      if (error instanceof AxiosError) {
        message.error(JSON.parse(error?.response?.data)?.message);
      }
    } finally {
      setPolicyLoading(false);
    }
  }, [country, policyById?.customer?.id]);

  useEffect(() => {
    if (policyById?.customer?.id) handleFetchPolicies();
  }, [handleFetchPolicies, policyById?.customer?.id]);

  if (policyLoading || policyByIdLoading) {
    return renderLoadingCard();
  }

  return (
    <main className="flex flex-col gap-2">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center gap-1 font-semibold text-[#30345E]"
      >
        <LeftOutlined /> Back
      </button>

      <div className="flex flex-col gap-5 text-button-variants-primary-scene mx-auto w-full max-w-full md:max-w-[85%] xl:max-w-[45%] md:bg-white p-0 md:p-6">
        <h1 className="text-lg md:text-2xl text-[#30345E] font-semibold text-center">
          Customer details
        </h1>

        <div className="divide-y divide-[#0505050f]">
          <div className="flex flex-row items-start gap-2 mb-5">
            <div className="text-xs md:text-xl text-[#30345E] font-semibold w-12 h-12 bg-[#CCDDFF] rounded-full flex items-center justify-center">
              {policyById?.customer?.firstName
                ? getInitial(
                    `${policyById?.customer?.firstName} ${policyById?.customer?.lastName}`
                  )
                : "---"}
            </div>
            <div className="w-full max-w-full">
              <p className="text-base md:text-[22px] mb-2 text-[#30345E] font-semibold">
                {policyById?.customer?.firstName
                  ? `${policyById?.customer?.firstName} ${policyById?.customer?.lastName}`
                  : "---"}
              </p>
              <ul className="flex flex-col gap-2 md:gap-0">
                <li className="flex flex-col md:flex-row md:items-center md:justify-between">
                  <Title>
                    <CallIcon /> Customer Phone Number
                  </Title>
                  <PhoneNumber>
                    +{policyById?.customer?.phoneNumber ?? "---"}
                  </PhoneNumber>
                </li>
                <li className="flex flex-col md:flex-row md:items-center md:justify-between">
                  <Title>
                    <WhatsappIcon /> Customer Whatsapp Number
                  </Title>
                  <PhoneNumber>
                    +{policyById?.customer?.whatsappNumber ?? "---"}
                  </PhoneNumber>
                </li>
                <li className="flex flex-col md:flex-row md:items-center md:justify-between">
                  <Title>
                    <MailIcon2 /> Customer Email Address
                  </Title>
                  <PhoneNumber>
                    {policyById?.customer?.emailAddress ?? "---"}
                  </PhoneNumber>
                </li>
              </ul>
            </div>
          </div>

          <InsuranceDetailTab style={{ paddingTop: "0rem" }}>
            <Tabs
              activeKey={tabValue}
              items={[
                {
                  key: "insurance_polices",
                  label: `Insurance Polices ( ${
                    policies?.data?.length ?? "---"
                  } )`,
                  children: <InsurancePolicy policies={policies} />,
                },
              ]}
              onChange={handleTabChange}
            />
          </InsuranceDetailTab>
        </div>
      </div>
    </main>
  );
};

export default InsuranceDetail;
