import { GoBackIcon, MailIcon, PhoneVariantIcon } from "@/assets/svgs";
import ModalVariant from "@/components/blocks/modals/ModalVariant";
import BookLeadButton from "@/components/dealerLeads/BookLeadButton";
import LoanApplyButton from "@/components/dealerLeads/LoanApplyButton";
import SalesCompletedButton from "@/components/dealerLeads/SalesCompletedButton";
import { CarTagsContainer } from "@/layouts/application-layouts/styles";
import { Space, Tag, Image } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import BookLeadsContent from "@/components/dealerLeads/BookLeadsContent";
import SalesCompletedContent from "@/components/dealerLeads/SalesCompletedContent";
import ClosedLeadsContent from "@/components/dealerLeads/CloseLeadsContent";
import useLeadModalStore from "@/states/leadsModal";
import useLeadDrawerStore from "@/states/leadsDrawer";
import DrawerVariant from "@/components/blocks/modals/DrawerVariant";
import { useMediaQuery } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchCarLeadsById, fetchCarMedia } from "@/hooks/use-api";
import { useState } from "react";
import { ICarByIdLeadProps } from "@/interface/car-leads";
import { Loader } from "../auth/styles/loader";
import moment from "moment";
import { numberWithCommas } from "@/utils/commonFunctions";
import { ImageLoader } from "@/utils/ImageLoader";
import { ICarMedias } from "@/interface/inventory-alerts";
import getInitial from "@/utils/get-initial";
import dayjs from "dayjs";
import formatInt from "@/utils/format-int";

const Text = tw.p`text-sm lg:text-sm text-[#30345E] bg-[#EEEFFF] font-semibold w-fit h-7 px-1 md:px-2 lg:px-3 rounded-full flex items-center justify-center`;

const DealerLeadDetails = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const totalImgLength: number = isMobile ? 3 : 4;

  const [carLeadById, setCarLeadById] = useState({} as ICarByIdLeadProps);
  const [carMedia, setCarMedia] = useState<ICarMedias[]>([]);
  const [visible, setVisible] = useState(false);
  const [scaleStep] = useState(0.5);
  const [leadData, setLeadData] = useState({} as ICarByIdLeadProps);

  const {
    isBookLeadOpen,
    openBookLeadModal,
    closeBookLeadModal,
    isSalesOpen,
    openSalesModal,
    closeSalesModal,
    isClosedLeadOpen,
    openClosedLeadModal,
    closeClosedLeadModal,
  } = useLeadModalStore();

  const {
    isBookLeadDrawerOpen,
    openBookLeadDrawer,
    closeBookLeadDrawer,
    isSalesDrawerOpen,
    openSalesDrawer,
    closeSalesDrawer,
    isClosedLeadDrawerOpen,
    openClosedLeadDrawer,
    closeClosedLeadDrawer,
  } = useLeadDrawerStore();

  const { isFetching: carLeadsIdLoading } = useQuery({
    queryKey: ["fetch_single_car_lead", id],
    queryFn: () => {
      return fetchCarLeadsById(id);
    },
    onSuccess: (res: any) => {
      const parsedLeads = JSON.parse(res?.data)?.carLeads;
      const updatedLeads = {
        ...parsedLeads,
        metadata: parsedLeads.metadata
          ? JSON.parse(parsedLeads.metadata)
          : null,
      };
      setCarLeadById(updatedLeads);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: carMediaLoading } = useQuery({
    queryKey: ["car_media_by_id", carLeadById?.carId],
    queryFn: () => {
      const params = new URLSearchParams({
        car_id: carLeadById?.carId,
        page_number: "1",
        page_size: "20",
      });
      return fetchCarMedia(params);
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const filteredMedia = parsedResults?.carMediaList?.filter(
        (item: ICarMedias) => {
          return item?.name !== "Thumbnail";
        }
      );
      setCarMedia(filteredMedia);
    },
    enabled: !!carLeadById?.carId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleBookLead = (data: ICarByIdLeadProps) => {
    setLeadData(data);
    if (isMobile) {
      openBookLeadDrawer();
    } else {
      openBookLeadModal();
    }
  };

  const handleCloseLeads = (data: ICarByIdLeadProps) => {
    setLeadData(data);
    if (isMobile) {
      openClosedLeadDrawer();
    } else {
      openClosedLeadModal();
    }
  };

  const handleSalesCompleted = (data: ICarByIdLeadProps) => {
    setLeadData(data);
    if (isMobile) {
      openSalesDrawer();
    } else {
      openSalesModal();
    }
  };

  const calculateFallbackGrid = (length: number) => {
    if (length < totalImgLength) {
      return totalImgLength - length;
    }
    return 0;
  };

  if (carLeadsIdLoading || carMediaLoading) {
    return (
      <div className="flex flex-col justify-center items-center w-full min-h-screen">
        <Loader variant={"secondary"} />
      </div>
    );
  }

  return (
    <main className="flex flex-col gap-6 mb-12">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center gap-2 text-xl text-[#30345E] font-semibold font-outfitbold"
      >
        <GoBackIcon />
        Lead details
      </button>

      <section className="flex flex-col md:flex-row gap-4">
        <div className="w-full max-w-full md:max-w-[40%] flex flex-col gap-5">
          <div className="rounded-lg overflow-hidden">
            <p className="text-sm text-[#000] bg-[#FFBF421C] flex items-center justify-center font-light w-full h-6 border-x border-t border-solid border-[#f0f0f0]">
              Customer is interested in Loans/Trade in
            </p>
            <div className="bg-white p-3 divide-y divide-[#E5E7EB] border-x border-b border-solid border-[#f0f0f0]">
              <div className="flex items-start gap-2 pb-2">
                <p className="text-sm md:text-lg font-semibold font-outfit uppercase text-[#30345E] !w-10 !h-10 rounded-full bg-[#CCDDFF] flex items-center justify-center">
                  {carLeadById?.metadata?.firstName &&
                    getInitial(
                      `${carLeadById?.metadata?.firstName} ${carLeadById?.metadata?.lastName}`
                    )}
                </p>
                <div className="flex-1">
                  <p className="text-base md:text-lg text-[#202020] font-semibold leading-relaxed capitalize">
                    {carLeadById?.metadata?.firstName
                      ? `${carLeadById?.metadata?.firstName} ${carLeadById?.metadata?.lastName}`
                      : "---"}
                  </p>
                  <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                    <p className="text-sm text-[#6B7280] mb-1 font-normal flex items-center gap-1">
                      <MailIcon /> {carLeadById?.metadata?.email ?? "---"}
                    </p>
                    <p className="text-sm text-[#2563EB] font-normal flex items-center gap-1">
                      <PhoneVariantIcon color="#2563EB" />{" "}
                      {carLeadById?.metadata?.primaryPhone ?? "---"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 md:gap-2.5 pt-3">
                <div className="flex items-center gap-1.5">
                  <p className="text-sm font-semibold">Contact type:</p>
                  {carLeadById?.source ? (
                    <div className="flex items-center gap-1.5">
                      {carLeadById?.source?.split(",")?.map((el) => (
                        <Text key={el}>{el?.replaceAll("_", " ")}</Text>
                      ))}
                    </div>
                  ) : (
                    "---"
                  )}
                </div>

                <div className="flex items-center gap-1.5">
                  <p className="text-sm font-semibold">Lead date:</p>
                  <Text>
                    {carLeadById?.createdAt
                      ? moment(carLeadById?.createdAt).format("MMM Do YYYY")
                      : "---"}
                  </Text>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="space-y-2 bg-white border-b border-solid border-[#f0f0f0] rounded-t-lg p-4">
              <div className="flex flex-wrap items-center gap-1">
                <p className="font-outfitbold text-[#30345E] text-2xl flex-1">
                  {carLeadById?.metadata?.carMake
                    ? `${carLeadById?.metadata?.carYear} ${carLeadById?.metadata?.carMake} ${carLeadById?.metadata?.carModel}`
                    : "---"}
                </p>
                <p className="text-base text-[#6B7280] font-semibold">
                  {formatInt(
                    Number(carLeadById?.metadata?.carMarketplacePrice),
                    true
                  )}
                </p>
              </div>

              <CarTagsContainer>
                <Space size={[0, 8]} wrap>
                  <Tag className="capitalize" color="#4E538A">
                    {carLeadById?.metadata?.carSellingCondition
                      ? `${carLeadById?.metadata?.carSellingCondition} Used`
                      : `---`}
                  </Tag>
                  <Tag color="#4E538A">
                    {carLeadById?.metadata?.carMileage
                      ? `${numberWithCommas(
                          carLeadById?.metadata?.carMileage
                        )} ${
                          carLeadById?.metadata?.carMileageUnit?.toUpperCase() ??
                          ""
                        }`
                      : "---"}
                  </Tag>
                  <Tag color="#4E538A">
                    {carLeadById?.metadata?.carEngineType ?? "---"}
                  </Tag>
                </Space>
              </CarTagsContainer>

              <p className="flex items-start flex-1 md:items-center gap-1 text-sm text-[#6B7280] font-normal">
                <img
                  src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/map-pin.svg?updatedAt=1740322815546"
                  alt="Map Pin"
                  className="mt-0.5 md:mt-0"
                />{" "}
                {carLeadById?.metadata?.carAddress ?? "---"}
              </p>
            </div>
            <div className="space-y-3">
              <div className="w-full h-[300px] overflow-hidden bg-gray-400 rounded-md">
                <img
                  src={ImageLoader({
                    src: `${
                      carLeadById?.metadata?.carImage ??
                      "https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg"
                    }`,
                    width: 400,
                    quality: 100,
                  })}
                  alt="Car Thumbnail"
                  className={`${
                    carLeadById?.metadata?.carImage
                      ? "object-cover"
                      : "object-contain"
                  } w-full h-[300px] object-center`}
                />
              </div>
              <div className="grid grid-cols-3 md:grid-cols-4 gap-3 relative">
                {carMedia?.slice(0, totalImgLength)?.map((item) => (
                  <div
                    key={item?.id}
                    className="relative w-full bg-neutral-200 h-[100px] rounded-md overflow-hidden"
                  >
                    <img
                      src={ImageLoader({
                        src: item?.url,
                        width: 400,
                        quality: 100,
                      })}
                      alt="Grid of Cars"
                      className="w-full h-[100px] object-cover rounded-md"
                    />
                  </div>
                ))}
                {Array(
                  calculateFallbackGrid(
                    carMedia?.slice(0, totalImgLength)?.length
                  )
                ).fill(
                  <div className="w-full h-[100px] flex items-center justify-center bg-neutral-200 overflow-hidden rounded-md">
                    <img
                      src="https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg"
                      alt="fallback"
                      className="object-contain"
                    />
                  </div>
                )}
                {totalImgLength !== 0 && (
                  <button
                    onClick={() => setVisible(true)}
                    className="absolute right-0 w-[31%] md:w-[23.5%] h-[100px] bg-black/50 text-white font-semibold text-sm flex items-center justify-center rounded-md"
                  >
                    show all photos
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full max-w-full md:max-w-[60%] flex flex-col gap-5">
          {carLeadById?.bookingDate ? (
            <div className="bg-white rounded-lg p-4 pb-6 flex flex-col gap-3 border border-solid border-[#f0f0f0]">
              <p className="text-xl md:text-2xl text-[#30345E] font-semibold">
                Booking Details
              </p>
              <div>
                <p className="text-base text-[#4B5563] font-semibold leading-normal">
                  Date of visit
                </p>
                <p className="text-base md:text-lg text-[#6B7280] font-normal">
                  {carLeadById?.bookingDate ?? "---"}
                </p>
              </div>
              {carLeadById?.bookingTime && (
                <div>
                  <p className="text-base text-[#4B5563] font-semibold leading-normal">
                    Time of visit
                  </p>
                  <p className="text-base md:text-lg text-[#6B7280] font-normal">
                    {carLeadById?.bookingTime
                      ? dayjs(`2000-01-01T${carLeadById?.bookingTime}`).format(
                          "h:mm A"
                        )
                      : "---"}
                  </p>
                </div>
              )}
              {carLeadById?.status !== "sold" && (
                <button
                  onClick={() => handleBookLead(carLeadById)}
                  disabled={
                    carLeadById?.status === "closed_leads" ||
                    carLeadById?.status === "new"
                  }
                  className="text-base text-[#30345E] font-semibold border border-solid border-[#30345E] rounded-full w-fit h-10 px-6 mt-2.5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                >
                  Update booking
                </button>
              )}
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center bg-white rounded-lg w-full h-[400px] p-6 border border-solid border-[#f0f0f0]">
              <p className="text-base text-[#6B7280] font-semibold">
                No booking activity
              </p>
            </div>
          )}

          {carLeadById?.status !== "sold" && (
            <div className="bg-white rounded-lg px-4 py-6 flex items-center justify-center border border-solid border-[#f0f0f0]">
              <div className="w-full max-w-full md:max-w-[85%] flex items-center justify-center gap-1.5 md:gap-3">
                <LoanApplyButton
                  onHandleSubmit={() =>
                    navigate(
                      `/applications/loans?car_id=${carLeadById?.carId}&form_type=new`
                    )
                  }
                />
                {["booked"].includes(carLeadById?.status) && (
                  <SalesCompletedButton
                    onHandleSubmit={() => handleSalesCompleted(carLeadById)}
                  />
                )}
                {["new", "closed_leads"].includes(carLeadById?.status) && (
                  <BookLeadButton
                    onHandleSubmit={() => handleBookLead(carLeadById)}
                  />
                )}
                {carLeadById?.status !== "closed_leads" && (
                  <button
                    onClick={() => handleCloseLeads(carLeadById)}
                    className="text-sm md:text-base text-[#DC2626] border border-solid border-[#DC2626] font-semibold w-full px-4 h-11 md:h-12 bg-white rounded-full"
                  >
                    Close Lead
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>

      {/* PREVIEW CAR IMAGE GALLERY COMPONENT */}
      <div className="preview-container hidden">
        <Image.PreviewGroup
          preview={{
            visible,
            scaleStep,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        >
          {carMedia?.map((elm) => (
            <div key={elm?.id}>
              <Image
                style={{ display: "none" }}
                width={200}
                src={elm?.url}
                alt="car-img"
              />
            </div>
          ))}
        </Image.PreviewGroup>
      </div>

      <ModalVariant
        isModalOpen={isBookLeadOpen}
        handleCancel={closeBookLeadModal}
        handleOk={openBookLeadModal}
        title="Book Lead"
        content={
          <BookLeadsContent
            closeBookLeadModal={closeBookLeadModal}
            data={leadData}
          />
        }
      />
      <ModalVariant
        isModalOpen={isSalesOpen}
        handleCancel={closeSalesModal}
        handleOk={openSalesModal}
        title="Sale Completed"
        content={
          <SalesCompletedContent
            closeSalesModal={closeSalesModal}
            data={leadData}
          />
        }
      />
      <ModalVariant
        isModalOpen={isClosedLeadOpen}
        handleCancel={closeClosedLeadModal}
        handleOk={openClosedLeadModal}
        title="Close Lead"
        content={
          <ClosedLeadsContent
            closeClosedLeadModal={closeClosedLeadModal}
            data={leadData}
          />
        }
      />
      <DrawerVariant
        open={isBookLeadDrawerOpen}
        onClose={closeBookLeadDrawer}
        placement="bottom"
        title="Book Lead"
        content={
          <BookLeadsContent
            closeBookLeadModal={closeBookLeadDrawer}
            data={leadData}
          />
        }
      />
      <DrawerVariant
        open={isSalesDrawerOpen}
        onClose={closeSalesDrawer}
        placement="bottom"
        title="Sale Completed"
        content={
          <SalesCompletedContent
            closeSalesModal={closeSalesDrawer}
            data={leadData}
          />
        }
      />
      <DrawerVariant
        open={isClosedLeadDrawerOpen}
        onClose={closeClosedLeadDrawer}
        placement="bottom"
        title="Close Lead"
        content={
          <ClosedLeadsContent
            closeClosedLeadModal={closeClosedLeadDrawer}
            data={leadData}
          />
        }
      />
    </main>
  );
};

export default DealerLeadDetails;
