import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { IInsuranceFormProps } from "./types";
import { Autocomplete, FormLabel, TextField } from "@mui/material";
import {
  FormDropdownStyle,
  InputFieldStyle,
  InputLabelStyle,
  SelectTextFieldStyle,
} from "@/layouts/application-layouts/styles";
import NewCarSection from "./NewCarSection";
import tw from "tailwind-styled-components";
import formatInt from "@/utils/format-int";
import { ImageLoader } from "@/utils/ImageLoader";
import useQuoteStore from "@/states/create-quote";
import { getCountryLicensePlateLength } from "@/utils/getCountryTelCodeLength";
import storage from "@/lib/storage";

interface IOption {
  label: string;
  value: string;
}

interface ICarData {
  make: string;
  model: string;
  year: number;
  exteriorColor: string;
  bodyType: string;
  usageType: string;
  estimatedCarPrice: number;
  imageUrl: string;
  rating: number;
}

const Title = tw.p`text-xs text-[#6B7280] font-normal`;
const Text = tw.p`text-sm text-[#202020] font-semibold`;

const CarSection: React.FC<IInsuranceFormProps> = ({
  methods,
  data,
  t,
  quoteId,
}) => {
  const country = storage.getCountry();
  const errors = { ...methods.formState.errors } as Record<string, any>;
  const [carData, setCarData] = useState<ICarData>();
  const { step, setStep } = useQuoteStore();
  const getCarId = methods.getValues("carId");

  const handleStepClick = () => setStep(step === 1 ? 2 : 1);

  useEffect(() => {
    !getCarId && quoteId ? setStep(2) : setStep(1);
  }, [getCarId, quoteId, setStep]);

  useEffect(() => {
    setCarData(data?.selectedCar);
  }, [data?.selectedCar]);

  return (
    <main className="relative">
      {!quoteId && (
        <div className="flex justify-end mb-1">
          <button
            onClick={handleStepClick}
            className="text-xs md:text-sm text-[#7F83A8] font-semibold"
            type="button"
          >
            {step === 1 ? "Add a new car" : "Go back"}
          </button>
        </div>
      )}

      {step === 1 && (
        <div>
          <div className="space-y-2 pt-2 pb-4">
            <FormLabel sx={InputLabelStyle}>
              Select car from inventory
            </FormLabel>
            <Controller
              name="selectedCarId"
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  id="selectedCarId"
                  disablePortal
                  loading={data?.isSearchingCars}
                  fullWidth
                  options={data?.cars ?? []}
                  getOptionLabel={(option: IOption) => option?.label || ""}
                  isOptionEqualToValue={(option: IOption, value: IOption) =>
                    option.value === value.value
                  }
                  onChange={(_, newValue) => onChange(newValue?.value)}
                  value={
                    data?.cars?.find(
                      (option: IOption) => option.value === value
                    ) || null
                  }
                  sx={FormDropdownStyle}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search and select car by VIN"
                      sx={SelectTextFieldStyle}
                      onChange={(e) =>
                        methods.setValue("searchCar", e.target.value)
                      }
                    />
                  )}
                />
              )}
            />
            {errors?.selectedCarId?.message && (
              <span className="text-red-600 text-sm">
                {errors?.selectedCarId?.message}
              </span>
            )}
          </div>

          {data?.selectedCar && (
            <div className="flex items-center gap-2 md:gap-3 w-full border border-solid p-3 mt-1">
              <img
                src={ImageLoader({
                  src: `${
                    carData?.imageUrl ??
                    "https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg"
                  }`,
                  width: 400,
                  quality: 100,
                })}
                alt="Car"
                className="w-[100px] h-[89px] rounded-md"
              />
              <div className="w-full flex flex-col gap-2">
                <p className="text-base text-[#30345E] font-semibold">
                  {carData?.year} {carData?.make} {carData?.model}
                </p>
                <div className="flex justify-between">
                  <div>
                    <Title>Value</Title>
                    <Text>
                      {formatInt(carData?.estimatedCarPrice as number, true)}
                    </Text>
                  </div>
                  <div>
                    <Title>Rating</Title>
                    <Text>{carData?.rating?.toFixed(1) ?? 0}</Text>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="space-y-2 pt-2 pb-4">
            <FormLabel sx={InputLabelStyle}>License plate number</FormLabel>
            <Controller
              name="carLicense"
              control={methods.control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!error}
                  type={"text"}
                  placeholder={t("Enter plate number")}
                  onChange={(e) => {
                    const uppercaseValue = e.target.value.toUpperCase();
                    field.onChange(uppercaseValue);
                  }}
                  sx={InputFieldStyle}
                  inputProps={{
                    maxLength: getCountryLicensePlateLength(country),
                  }}
                />
              )}
            />
            {errors?.carLicense?.message && (
              <span className="text-red-600 text-sm">
                {errors?.carLicense?.message}
              </span>
            )}
          </div>
        </div>
      )}

      {step === 2 && <NewCarSection methods={methods} t={t} data={data} />}
    </main>
  );
};

export default CarSection;
