import create from "zustand";
import { message } from "antd";
import storage from "@/lib/storage";
import { fetchBanks } from "@/hooks/use-api";

interface ISupportedBanks {
  label: string;
  value: string;
  id: string;
}

interface SupportedBankStore {
  supportedBanksLoading: boolean;
  supportedBanks: ISupportedBanks[] | undefined;
  fetchSupportedBanks: () => Promise<void>;
}

const useSupportedBanksStore = create<SupportedBankStore>((set) => ({
  supportedBanksLoading: false,
  supportedBanks: undefined,
  fetchSupportedBanks: async () => {
    const country = storage.getCountry();
    const params = new URLSearchParams({
      country: country,
    });
    try {
      set({ supportedBanksLoading: true });
      const response = await fetchBanks(params);
      const parsedResponse = JSON.parse(response?.data);
      const filteredBanks = parsedResponse?.banks
        ?.filter((item: any) => item?.paymentEngineCode)
        ?.map((item: Record<string, string>) => {
          return {
            label: item.name,
            value: item.name,
            id: item.paymentEngineCode,
          };
        });
      set({
        supportedBanks: filteredBanks,
        supportedBanksLoading: false,
      });
    } catch (err: any) {
      set({ supportedBanksLoading: false });
      message.error(
        JSON.parse(err?.response?.data)?.message || "Error fetching banks"
      );
    }
  },
}));

export default useSupportedBanksStore;
