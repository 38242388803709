import { InputLabelStyle } from "@/layouts/application-layouts/styles";
import { FormLabel } from "@mui/material";
import React from "react";

interface LabelProps {
  required?: boolean;
  label: string;
}

const FormFieldLabel: React.FC<LabelProps> = ({ required, label }) => {
  return (
    <FormLabel sx={InputLabelStyle}>
      {label} {required && <span className="text-sm text-red-600">*</span>}
    </FormLabel>
  );
};

export default FormFieldLabel;
