import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { checkLoanCollateralErrors } from "@/utils/process-form-data";
import { useNavigate } from "react-router-dom";
import { IHandleActionOptions } from "@/types";
import { useQueryClient } from "@tanstack/react-query";

interface IFormButton {
  loading: boolean;
  data: any;
  fields: any;
  index: number;
  setAlert: (error: string) => void;
  setCompleted: React.Dispatch<React.SetStateAction<any>>;
  setExpanded: (index: number) => void;
  loanId?: string;
  label: string;
  productData: any;
  carFields: any;
  prefilledCars: any;
  prefilledFields: any;
  submit: (e: any, isDraft: boolean) => void;
}

export default function CollateralActionButton({
  loading,
  data,
  fields,
  productData,
  index,
  setAlert,
  setCompleted,
  setExpanded,
  label,
  loanId,
  carFields,
  prefilledCars,
  prefilledFields,
  submit,
}: Readonly<IFormButton>) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleCollateralNextAction = async (
    e: any,
    {
      data,
      fields,
      index,
      productData,
      setAlert,
      setCompleted,
      setExpanded,
    }: IHandleActionOptions
  ) => {
    const error = checkLoanCollateralErrors(data, fields);
    if (
      error &&
      carFields?.length === 0 &&
      prefilledCars?.length === 0 &&
      prefilledFields?.length === 0
    ) {
      setAlert(error);
      setCompleted((current: any) => ({
        ...current,
        [index]: false,
      }));
      return;
    }
    if (!loanId) {
      setExpanded(
        productData?.config?.sections?.length +
          productData?.config?.preference?.length +
          index +
          1
      );
      setCompleted((current: any) => ({
        ...current,
        [productData?.config?.sections?.length +
        productData?.config?.preference?.length +
        index]: true,
      }));
      submit(e, true);
    } else {
      if (submit) {
        await submit(e, true);
      }
      queryClient.invalidateQueries();
      navigate(`/dealer/applications/loans/${loanId}`);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      type="button"
      fullWidth
      color="custom"
      style={{ fontWeight: 600 }}
      loading={loading}
      onClick={(e) =>
        handleCollateralNextAction(e, {
          data,
          fields,
          index,
          productData,
          setAlert,
          setCompleted,
          setExpanded,
        })
      }
    >
      {label}
    </LoadingButton>
  );
}
