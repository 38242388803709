import storage from "@/lib/storage";
import React, { useRef, useState } from "react";
import styled from "styled-components";

import { useLoadScript } from "@react-google-maps/api";
import { CircularProgress, TextField } from "@mui/material";
import { InputFieldStyle } from "@/layouts/application-layouts/styles";
import { Controller } from "react-hook-form";

interface AddressProps {
  methods?: any;
  setDisplayAddress?: React.Dispatch<React.SetStateAction<boolean>>;
}

const libraries: "places"[] = ["places"];

const AutocompleteContainer = styled.div`
  .css-13cymwt-control {
    background: #f8fafc;
    border-radius: 4px;
    border: 1px solid #e5e7eb;
  }

  .css-1u9des2-indicatorSeparator {
    width: 0px;
  }
`;

const AddressAutocomplete: React.FC<AddressProps> = ({
  methods,
  setDisplayAddress,
}) => {
  const country = storage.getCountry();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries,
  });

  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);

  const onLoad = () => {
    if (inputRef.current) {
      autocompleteRef.current = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: country.toLowerCase() },
          types: ["geocode"],
        }
      );
      autocompleteRef.current.addListener("place_changed", () => {
        const place = autocompleteRef.current?.getPlace();
        if (place?.geometry?.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          const formattedAddress = place.formatted_address ?? "";
          methods?.setValue("address", formattedAddress);
          methods?.setValue("latitude", lat);
          methods?.setValue("longitude", lng);
          setDisplayAddress?.(false);
        }
      });
    }
  };

  if (!isLoaded) {
    return <>Loading...</>;
  }

  return (
    <AutocompleteContainer>
      <Controller
        name="addressName"
        control={methods.control}
        render={({ field }) => (
          <TextField
            inputRef={inputRef}
            onFocus={() => {
              setLoading(true);
              onLoad();
            }}
            onBlur={() => setLoading(false)}
            onChange={field.onChange}
            placeholder="Enter your address"
            variant="outlined"
            fullWidth
            sx={InputFieldStyle}
            InputProps={{
              endAdornment: loading ? <CircularProgress size={20} /> : null,
            }}
          />
        )}
      />
    </AutocompleteContainer>
  );
};

export default AddressAutocomplete;
