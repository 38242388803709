import React, { useState } from "react";
import type { FormProps } from "antd";
import { Button, Form, Input, message, Select } from "antd";
import {
  FormInputButtonContainer,
  FormInputSelectContainer,
  FormInputTextAreaContainer,
} from "./styles";
import tw from "tailwind-styled-components";
import { updateCarLeads } from "@/hooks/use-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Alert, Snackbar } from "@mui/material";
import { Loader } from "@/pages/auth/styles/loader";

type FieldType = {
  reason?: string;
  reason_description?: string;
};

interface IClosedLeadsProps {
  closeClosedLeadModal: () => void;
  data?: Record<string, any>;
}

const Label = tw.span`text-base text-[#202020] font-semibold`;

const ClosedLeadsContent: React.FC<IClosedLeadsProps> = ({
  closeClosedLeadModal,
  data,
}) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const query = useQueryClient();
  const [alert, setAlert] = useState("");
  const [otherReason, setOtherReason] = useState("");

  const useCloseLeads = () => {
    return useMutation({
      mutationFn: (payload) => updateCarLeads(data?.id, payload),
    });
  };

  const { mutate: closeLeads, isLoading: isClosingLeads } = useCloseLeads();

  const handleSelectReason = (value: string) => {
    setOtherReason(value);
  };

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    const payload = {
      status: "closed_leads",
      reason:
        otherReason === "other" ? values.reason_description : values.reason,
    };
    closeLeads(payload as any, {
      onSuccess: (response) => {
        if (response) {
          closeClosedLeadModal();
          query.invalidateQueries(["fetch_single_car_lead"]);
          message.success("Lead booked successfully");
        }
      },
      onError: (error: any) => {
        const errMessage = JSON.parse(error.response?.data || "{}").message;
        setAlert(errMessage);
      },
    });
  };

  return (
    <main>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}
      >
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>

      <Form
        name="Book Leads"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
        form={form}
        className="mt-5"
      >
        <FormInputSelectContainer>
          <Form.Item
            label={<Label>Select Reason</Label>}
            name="reason"
            rules={[{ required: true, message: "please-add-a-reason" }]}
          >
            <Select
              onChange={handleSelectReason}
              options={[
                {
                  value: "Customer Unreachable",
                  label: "Customer Unreachable",
                },
                {
                  value: "Inquiry",
                  label: "Inquiry",
                },
                {
                  value: "other",
                  label: "Other - Please specify",
                },
              ]}
            />
          </Form.Item>
        </FormInputSelectContainer>

        {otherReason === "other" && (
          <FormInputTextAreaContainer>
            <Form.Item label={null} name="reason_description">
              <TextArea rows={4} placeholder="Please specify reason" />
            </Form.Item>
          </FormInputTextAreaContainer>
        )}

        <div className="flex gap-3 mt-10 mb-4">
          <button
            onClick={closeClosedLeadModal}
            type="button"
            className="w-full max-w-full h-12 bg-white text-base font-semibold text-[#30345E] border border-solid border-[#30345E] rounded-full"
          >
            Cancel
          </button>
          <FormInputButtonContainer className="w-full">
            <Form.Item label={null} className="mb-0">
              <Button htmlType="submit">
                {isClosingLeads ? <Loader variant="secondary" /> : "Submit"}
              </Button>
            </Form.Item>
          </FormInputButtonContainer>
        </div>
      </Form>
    </main>
  );
};

export default ClosedLeadsContent;
