import styled from "styled-components";

export const FormInputDateContainer = styled.div`
  .ant-picker {
    width: 100%;
    height: 48px;
    background: #f8fafc;
    border: 1px solid;
    border-color: #e5e7eb;
    font-size: 16px;
  }
  input {
    font-size: 16px;
    &::placeholder {
      font-size: 16px;
    }
  }
`;

export const FormInputButtonContainer = styled.div`
  .ant-btn {
    width: 100%;
    max-width: 100%;
    height: 48px;
    background: #ffb619;
    border-radius: 60px;
    font-size: 16px;
    border: 0;
    span {
      color: #30345e;
      font-weight: 600;
    }
  }
`;

export const FormInputContainer = styled.div`
  .ant-input {
    width: 100%;
    height: 48px;
    background: #f8fafc;
    border: 1px solid;
    border-color: #e5e7eb;
    font-size: 16px;
  }
  input {
    font-size: 16px;
    &::placeholder {
      font-size: 16px;
    }
  }
`;

export const FormInputSelectContainer = styled.div`
  .ant-select {
    height: 48px;
    width: 100%;
    font-size: 16px;
    .ant-select-selector {
      height: 48px;
      border-radius: 4px;
      background: #f8fafc;
      align-items: center;
      font-size: 16px;
      .ant-select-selection-search-input {
        padding-top: 14px;
        font-size: 16px;
      }
    }
  }
`;

export const FormInputTextAreaContainer = styled.div`
  .ant-input {
    width: 100%;
    /* height: 48px; */
    background: #f8fafc;
    border: 1px solid;
    border-color: #e5e7eb;
    font-size: 16px;
  }
  input {
    font-size: 16px;
    &::placeholder {
      font-size: 16px;
    }
  }
`;

export const RadioGroupStyle = styled.div`
  .ant-radio-group {
    display: flex;
    gap: 10px;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #eeefff;
    border-color: #eeefff;
  }
  .ant-radio-group-large .ant-radio-button-wrapper:first-child {
    border-radius: 8px;
  }
  .ant-radio-group-large .ant-radio-button-wrapper:last-child {
    border-radius: 8px;
  }
  .ant-radio-group-large .ant-radio-button-wrapper {
    border-radius: 8px;
    border: 1px solid #30345e;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    position: absolute;
    inset-block-start: -1px;
    inset-inline-start: -1px;
    display: block;
    box-sizing: content-box;
    width: 0px;
    height: 100%;
    padding-block: 1px;
    padding-inline: 0;
    background-color: #d9d9d9;
    transition: background-color 0.3s;
    content: "";
  }
`;
