import { message } from "antd";
import {
  FormStyledBtn,
  InputFieldStyle,
} from "@/layouts/application-layouts/styles";
import storage from "@l/storage";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import { getCountryTelCode } from "@/utils/getCountryTelCode";
import { getCountryTelCodeLength } from "@/utils/getCountryTelCodeLength";
import { Loader } from "@/pages/auth/styles/loader";
import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import PlacesGoogleMap from "./PlacesGoogleMap";
import AddressAutocomplete from "./insurance/AddressAutocomplete";

import { TextField, Button, Grid, InputAdornment } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  profileDefaultValues,
  profileFormSchema,
  validateProfileForm,
} from "@/validations/profileFormSchema";
import { IProfileProps, TFranchiseDataType } from "@/interface/car-leads";
import { FaExternalLinkAlt } from "react-icons/fa";
import getCoordinatesFromAddress from "@/utils/getCoordinatesFromAddress";
import FormFieldLabel from "./formElements/FormFieldLabel";

export default function ProfileForm({
  franchiseData,
  refetch,
  dealerId,
}: Readonly<{
  franchiseData: TFranchiseDataType;
  refetch: () => void;
  dealerId: string;
}>) {
  const user = storage.getUser();
  const country = storage.getCountry();

  const [isCopied, setIsCopied] = useState(false);
  const [displayAddress, setDisplayAddress] = useState(false);

  const methods = useForm({
    mode: "all",
    defaultValues: profileDefaultValues,
    resolver: zodResolver(profileFormSchema),
  });

  const errors = { ...methods.formState.errors } as Record<string, any>;
  const formValues = methods.watch();
  const isValid = validateProfileForm(formValues);
  const disabled = !isValid;
  const address = methods.getValues("address");

  let websiteBaseUrl;
  if (process.env.REACT_APP_BASE_ENV === "development") {
    websiteBaseUrl = "https://marketplace.dev.myautochek.com";
  }
  if (process.env.REACT_APP_BASE_ENV === "staging") {
    websiteBaseUrl = "https://marketplace.staging.myautochek.com";
  }
  if (process.env.REACT_APP_BASE_ENV === "production") {
    websiteBaseUrl = "https://autochek.africa";
  }

  const redirectUrl = `${
    websiteBaseUrl ?? "https://autochek.africa"
  }/${country.toLowerCase()}/dealer-cars/${dealerId}`;

  const updateFranchiseData = useMutation({
    mutationFn: (payload) => client.put(`/v1/franchise/${dealerId}`, payload),
  });

  useEffect(() => {
    const decodeAddress = async () => {
      if (address) {
        try {
          const coordinates = await getCoordinatesFromAddress(address);
          if (coordinates) {
            methods.setValue("latitude", coordinates.lat);
            methods.setValue("longitude", coordinates.lng);
          }
        } catch (error) {
          console.error(
            "Failed to get coordinates:",
            error instanceof Error ? error.message : "Unknown error"
          );
        }
      }
    };

    decodeAddress();
  }, [address, methods]);

  const mappedProfileData = useCallback(
    (data: TFranchiseDataType): IProfileProps => {
      return {
        dealerName: data?.ownerInfo?.firstname
          ? `${data?.ownerInfo?.firstname} ${data?.ownerInfo?.lastname}`
          : "",
        dealershipName: data?.name ?? "",
        email: data?.email ?? "",
        phoneNumber: data?.phonenumber?.slice(3) ?? "",
        whatsappNumber: data?.whatsappNumber?.slice(3) ?? "",
        displayPhoneNumber: data?.displayPhoneNumber?.slice(3) ?? "",
        extraDisplayNumber: data?.extraDisplayPhoneNumber?.slice(3) ?? "",
        instagramHandle: data?.instagramHandle ?? "",
        facebookHandle: data?.facebookHandle ?? "",
        addressName: "",
        latitude: data?.latitude,
        longitude: data?.longitude,
        address: data?.address ?? "",
      };
    },
    []
  );

  useEffect(() => {
    if (franchiseData) {
      const mappedData = mappedProfileData(franchiseData);
      methods.reset(mappedData);
    }
  }, [franchiseData, mappedProfileData, methods]);

  const preparePayload = (values: IProfileProps) => {
    const payload = {
      phonenumber: values.phoneNumber
        ? `${getCountryTelCode(country)}${values.phoneNumber}`
        : "",
      whatsapp_number: values.whatsappNumber
        ? `${getCountryTelCode(country)}${values.whatsappNumber}`
        : "",
      display_phone_number: values.displayPhoneNumber
        ? `${getCountryTelCode(country)}${values.displayPhoneNumber}`
        : "",
      extra_display_phone_number: values.extraDisplayNumber
        ? `${getCountryTelCode(country)}${values.extraDisplayNumber}`
        : "",
      onboarding_source: user.id,
      instagram_handle: values.instagramHandle,
      facebook_handle: values.facebookHandle,
      longitude: values.longitude,
      latitude: values.latitude,
      address: values.address,
      email: values.email,
    };
    return payload;
  };

  const handleProfileForm = async () => {
    const data = preparePayload(formValues);
    updateFranchiseData
      .mutateAsync(data as any)
      .then(() => {
        message.success("Profile updated successfully");
        refetch();
      })
      .catch((error) => {
        const errorMessage = JSON.parse(error.response?.data).error;
        message.error(
          errorMessage.error ||
            errorMessage.message ||
            "Failed to update profile."
        );
      });
  };

  return (
    <div className="w-full max-w-full flex flex-col gap-8">
      <div className="rounded-xl overflow-hidden">
        <p className="text-sm md:text-base font-semibold bg-[#30345E] text-white px-3 md:px-4 py-2.5">
          Your unique landing page URL
        </p>
        <div className="px-3 py-4 md:p-4 bg-[#262A4B] flex flex-col gap-4">
          <div className="px-2 md:px-3 py-3 md:py-3.5 bg-[#F8FAFC] rounded-md flex items-center">
            <p className="flex-1 text-base text-[#202020] font-semibold text-ellipsis whitespace-nowrap w-full max-w-[95%] overflow-hidden">
              {redirectUrl}
            </p>
            <div className="flex items-center gap-2">
              <button
                className="flex items-center gap-2 text-[#30345E] text-sm md:text-base font-semibold bg-[#FFB619] w-fit py-2 px-4 rounded-full"
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(redirectUrl);
                  setIsCopied(true);
                  setTimeout(() => {
                    setIsCopied(false);
                  }, 1000);
                }}
              >
                <span className="hidden md:block">Copy URL</span>{" "}
                {isCopied ? (
                  <CheckOutlined />
                ) : (
                  <CopyOutlined className="font-semibold" />
                )}
              </button>
              <button
                onClick={() => window.open(redirectUrl, "_blank", "noopener")}
                className="px-4 py-2 bg-[#30345e] rounded-full text-white text-sm md:text-base font-medium"
              >
                <FaExternalLinkAlt />
              </button>
            </div>
          </div>
          <p className="text-xs md:text-sm bg-[#30345E] text-white/70 px-3 md:px-3 py-2.5 flex items-center font-medium rounded-full">
            Access your dedicated page to view and manage all your vehicles in
            one place.
          </p>
        </div>
      </div>

      <form>
        <Grid container spacing={2}>
          {/* Dealer Name */}
          <Grid item xs={12} className="space-y-2">
            <FormFieldLabel label="Dealer Name" required />
            <Controller
              name="dealerName"
              control={methods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Enter dealer name"
                  fullWidth
                  sx={InputFieldStyle}
                  onChange={field.onChange}
                  disabled
                />
              )}
            />
            {errors?.dealerName?.message && (
              <span className="text-red-600 text-sm">
                {errors?.dealerName?.message}
              </span>
            )}
          </Grid>

          {/* Dealership Name */}
          <Grid item xs={12} className="space-y-2">
            <FormFieldLabel label="Dealership Name" required />
            <Controller
              name="dealershipName"
              control={methods.control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Enter dealership name"
                  fullWidth
                  sx={InputFieldStyle}
                  onChange={field.onChange}
                  disabled
                />
              )}
            />
            {errors?.dealershipName?.message && (
              <span className="text-red-600 text-sm">
                {errors?.dealershipName?.message}
              </span>
            )}
          </Grid>

          {/* Email */}
          <Grid item xs={12} className="space-y-2">
            <FormFieldLabel label="Email" required />
            <Controller
              name="email"
              control={methods.control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Enter email address"
                  fullWidth
                  onChange={field.onChange}
                  sx={InputFieldStyle}
                />
              )}
            />
            {errors?.email?.message && (
              <span className="text-red-600 text-sm">
                {errors?.email?.message}
              </span>
            )}
          </Grid>

          {/* Phone Number and WhatsApp Number */}
          <Grid item xs={12} md={6} className="space-y-2">
            <FormFieldLabel label="Phone number" required />
            <Controller
              name="phoneNumber"
              control={methods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="text"
                  placeholder="Enter phone number"
                  sx={InputFieldStyle}
                  inputProps={{
                    maxLength: getCountryTelCodeLength(country),
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCountryTelCode(country)}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => {
                    const numericValue = event.target.value.replace(/\D/g, "");
                    field.onChange(numericValue);
                  }}
                  onBeforeInput={(event: any) => {
                    if (!/^\d*$/.test(event.data)) {
                      event.preventDefault();
                    }
                  }}
                />
              )}
            />
            {errors?.phoneNumber?.message && (
              <span className="text-red-600 text-sm">
                {errors?.phoneNumber?.message}
              </span>
            )}
          </Grid>

          <Grid item xs={12} md={6} className="space-y-2">
            <FormFieldLabel label="Whatsapp number" />
            <Controller
              name="whatsappNumber"
              control={methods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="text"
                  placeholder="Enter whatsapp number"
                  sx={InputFieldStyle}
                  inputProps={{
                    maxLength: getCountryTelCodeLength(country),
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCountryTelCode(country)}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => {
                    const numericValue = event.target.value.replace(/\D/g, "");
                    field.onChange(numericValue);
                  }}
                  onBeforeInput={(event: any) => {
                    if (!/^\d*$/.test(event.data)) {
                      event.preventDefault();
                    }
                  }}
                />
              )}
            />
            {errors?.whatsappNumber?.message && (
              <span className="text-red-600 text-sm">
                {errors?.whatsappNumber?.message}
              </span>
            )}
          </Grid>

          {/* Display Phone Number */}
          <Grid item xs={12} className="space-y-2">
            <FormFieldLabel label="Display Phone Number" />
            <Controller
              name="displayPhoneNumber"
              control={methods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="text"
                  placeholder="Enter phone number"
                  sx={InputFieldStyle}
                  inputProps={{
                    maxLength: getCountryTelCodeLength(country),
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCountryTelCode(country)}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => {
                    const numericValue = event.target.value.replace(/\D/g, "");
                    field.onChange(numericValue);
                  }}
                  onBeforeInput={(event: any) => {
                    if (!/^\d*$/.test(event.data)) {
                      event.preventDefault();
                    }
                  }}
                />
              )}
            />
            {errors?.displayPhoneNumber?.message && (
              <span className="text-red-600 text-sm">
                {errors?.displayPhoneNumber?.message}
              </span>
            )}
          </Grid>

          {/* Extra Display Phone Number */}
          <Grid item xs={12} className="space-y-2">
            <FormFieldLabel label="Extra display phone number" />
            <Controller
              name="extraDisplayNumber"
              control={methods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="text"
                  placeholder="Enter phone number"
                  sx={InputFieldStyle}
                  inputProps={{
                    maxLength: getCountryTelCodeLength(country),
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCountryTelCode(country)}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => {
                    const numericValue = event.target.value.replace(/\D/g, "");
                    field.onChange(numericValue);
                  }}
                  onBeforeInput={(event: any) => {
                    if (!/^\d*$/.test(event.data)) {
                      event.preventDefault();
                    }
                  }}
                />
              )}
            />
            {errors?.extraDisplayNumber?.message && (
              <span className="text-red-600 text-sm">
                {errors?.extraDisplayNumber?.message}
              </span>
            )}
          </Grid>

          {/* Instagram Handle */}
          <Grid item xs={12} className="space-y-2">
            <FormFieldLabel label="Instagram profile" />
            <Controller
              name="instagramHandle"
              control={methods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="https://www.instagram.com/example"
                  sx={InputFieldStyle}
                />
              )}
            />
            {errors?.instagramHandle?.message && (
              <span className="text-red-600 text-sm">
                {errors?.instagramHandle?.message}
              </span>
            )}
          </Grid>

          {/* Facebook Handle */}
          <Grid item xs={12} className="space-y-2">
            <FormFieldLabel label="Facebook profile" />
            <Controller
              name="facebookHandle"
              control={methods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="https://www.facebook.com/example"
                  sx={InputFieldStyle}
                />
              )}
            />
            {errors?.facebookHandle?.message && (
              <span className="text-red-600 text-sm">
                {errors?.facebookHandle?.message}
              </span>
            )}
          </Grid>

          {/* Address */}
          <Grid item xs={12} className="!flex !flex-col !gap-2">
            <FormFieldLabel label="Dealer GPS Address" required />
            {address && <PlacesGoogleMap address={address} />}
            {address && (
              <p className="mt-2 flex items-center gap-1">
                <img
                  src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/map-pin.svg?updatedAt=1740322815546"
                  alt="Location"
                />{" "}
                {address}
              </p>
            )}
            {displayAddress && (
              <div className="mt-2">
                <AddressAutocomplete
                  methods={methods}
                  setDisplayAddress={setDisplayAddress}
                />
                {errors?.addressName?.message && (
                  <span className="text-red-600 text-sm">
                    {errors?.addressName?.message}
                  </span>
                )}
              </div>
            )}
            <div>
              <button
                type="button"
                onClick={() => setDisplayAddress(!displayAddress)}
                className="flex items-center gap-1 text-sm text-[#199400] border border-solid border-[#199400] rounded-full p-2 w-fit"
              >
                {address ? "Change" : "Set"} GPS Address{" "}
                <img
                  src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/maps-square-01.svg?updatedAt=1741625685853"
                  alt="GPS"
                />{" "}
              </button>
            </div>
          </Grid>

          {/* Permissions */}
          <Grid item xs={12} className="!mt-0">
            <p className="text-base text-[#4B5563] font-normal">
              By updating your contact details, you agree that your contact
              details may be shared with the buyer.
            </p>
          </Grid>

          <Grid item xs={12}>
            <Button
              className="w-full max-w-[50%] my-0 mx-auto !font-semibold disabled:cursor-not-allowed"
              variant="contained"
              sx={FormStyledBtn}
              color="primary"
              fullWidth
              style={{ marginTop: "30px" }}
              onClick={handleProfileForm}
              disabled={disabled}
            >
              {updateFranchiseData.isLoading ? (
                <Loader variant="secondary" />
              ) : (
                "Update Profile"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
