const getCoordinatesFromAddress = (
  address: string
): Promise<{ lat: number; lng: number }> => {
  const geocoder = new google.maps.Geocoder();

  return new Promise<{ lat: number; lng: number }>((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results && results.length > 0) {
        const location = results[0].geometry.location;
        resolve({ lat: location.lat(), lng: location.lng() });
      } else {
        reject(
          new Error(
            `Geocoding failed for address: ${address}. Status: ${status}`
          )
        );
      }
    });
  });
};

export default getCoordinatesFromAddress;
